<template>
  <section>
    <div
      v-if="
        currentEntity &&
        currentEntity.templates &&
        currentEntity.templates.length == 1 &&
        (customization == null ||
          (customization &&
            customization.tab_groups &&
            customization.tab_groups.length == 0)) &&
        currentEntity.entity_type === 'GENERAL'
      "
    >
      <dialog-component
        :title="currentEntity.name"
        :visible="dialogVisible"
        @before-close="backToEntityInfoList"
        :fullscreen="true"
        :width="getIsMobile ? '100%' : '100%'"
        class="el-dialog el-dialog-fullscreen entity-view"
        :disableTopBar="false"
        :containerWidth="
          currentEntity.templates[0].templateInfo.free_style ? '100%' : '100%'
        "
        :containerHeight="'100%'"
        :prompt="isEdit ? warningmsg : ''"
        :cancelConfirmation="isEdit ? true : false"
      >
        <div>
          <entity-template-view
            v-if="showTemplate && currrentTab == 'entities_data'"
            :currentEntity="currentEntity"
            :entityData="entityData"
            :isEdit="isEdit"
            :layout="activeLayout"
            :isApplicationUserSide="isApplicationUserSide"
            :isSaveAsDraft="isSaveAsDraft"
            :entityDataExists="entityDataExists"
            :printEntityData="printEntityData"
            :profilePicture="getUserprofilepicture"
            :entitySettings="entitySettings"
            :saveTemp="saveTemp"
            :openNew="openNew"
            :allEntitiesDataFromParent="allEntitiesData"
            :allFormTemplatesFromParent="allFormTemplates"
            :allCompanyUserTypesDataFromParent="allCompanyUserTypesData"
            v-on:refresh-page="refreshThePage"
            v-on:getDataFromEntityTemplateView="getDataFromEntityTemplateView"
            @closeParent="backToEntityInfoList"
            :getSuggestionFormData="getSuggestionFormData"
          ></entity-template-view>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="buttons-layout">
            <span
              v-if="
                isEdit &&
                templateData &&
                templateData.templateInfo &&
                templateData.templateInfo.templateTryUsingAI
              "
              class="ai-icon"
            >
              <div class="ai-icon-content" @click="showTryUsingAIMethod">
                <icons
                  :iconName="'TryUsingAI'"
                  style="
                    color: var(--primary-color) !important;
                    fill: var(--primary-color) !important;
                  "
                ></icons>
                <span class="ai-text">Try Using AI</span>
              </div>
            </span>
            <el-dropdown
              class="ml-1"
              placement="top"
              v-if="
                currentEntity &&
                currentEntity.default_printing_document &&
                (checkPerimission('DOCUMENT') ||
                  checkPerimission('SEND NOTIFICATION'))
              "
            >
              <el-button size="mini" style="margin: 10px; height: 35px">
                <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                Send
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <a
                  @click="saveAndPrint(true)"
                  v-if="
                    currentEntity &&
                    currentEntity.default_printing_document &&
                    checkPerimission('DOCUMENT')
                  "
                >
                  <el-dropdown-item>
                    <i class="fa fa-print" aria-hidden="true"></i>
                    {{
                      entityDataId
                        ? isEdit
                          ? "Update & Print"
                          : "Print"
                        : "Save & Print"
                    }}
                  </el-dropdown-item>
                </a>

                <a
                  @click="saveAndPrint(false)"
                  v-if="
                    currentEntity &&
                    currentEntity.default_printing_document &&
                    checkPerimission('DOCUMENT')
                  "
                >
                  <el-dropdown-item>
                    <i class="el-icon-download"></i>
                    Download
                  </el-dropdown-item>
                </a>
                <a
                  @click="openSendNotification()"
                  v-if="
                    currentEntity &&
                    currentEntity.default_printing_document &&
                    checkPerimission('SEND NOTIFICATION')
                  "
                >
                  <el-dropdown-item>
                    <i class="el-icon-s-promotion"></i>
                    Send Notification
                  </el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
            <TryUsingAI
              v-if="showTryUsingAI"
              :form="getUpdatedForm"
              :fieldsData="allEntityFields"
              type="EntityData"
              @close="handleCloseTryUsingAI"
              @copy-content="handleCopyContent"
            ></TryUsingAI>
            <SplitButton
              v-if="checkIsDeletedData"
              :mainButton="getMainButtonData()"
              :dropdownItems="getMenuItemsForButton()"
              :isSaveAsDraft="templateData.templateInfo.isSaveAsDraft"
              :disableDropdown="shouldDisableDropdown"
              :dataStatus="$route.query.dataStatus?$route.query.dataStatus:''"
              @mainButtonClick="handleMainButtonClick"
              @dropdownSelectEmit="handleDropdownSelect"
              @saveAsDraftButtonClick="handleSaveAsDraftButtonClick"
            >
            </SplitButton>
            <!-- <el-button
              v-if="
                isEdit &&
                currentEntity &&
                currentEntity.default_printing_document
              "
              size="mini"
              type="primary"
              @click="saveAndPrint"
              class="scaling-button"
            >
              {{ entityDataId ? "Update & Print" : "Save & Print" }}
            </el-button>
            <el-button
              v-if="isEdit"
              size="mini"
              type="primary"
              @click="emitSaveTemplate1"
              class="scaling-button"
            >
              {{ entityDataId ? "Update" : "Save" }}
            </el-button>
            <el-link
              v-else
              icon="el-icon-edit"
              type="button"
              :underline="false"
              @click="emitGoToEditMode"
              class="
                el-button el-button--primary el-button--mini
                scaling-button
              "
            >
              Edit</el-link
            >
            <el-button
              v-if="
                isEdit &&
                entityDataId &&
                (!currentEntity || currentEntity.entity_type != 'INDIVIDUAL')
              "
              size="mini"
              type="primary"
              @click="emitCloneEntityData"
              class="scaling-button"
              >Duplicate
            </el-button>
            <el-button
              v-if="isEdit && !entityDataId"
              size="mini"
              type="primary"
              @click="emitSaveTemplate2"
              class="scaling-button"
              >Save & New
            </el-button>-->
          </div>
        </span>
      </dialog-component>
    </div>
    <div
      v-else-if="defaultLoading"
      v-loading="defaultLoading"
      style="width: 100% !important; height: 100vh !important"
      element-loading-text="Please wait, gathering data..."
    ></div>
    <div
      v-else-if="!defaultLoading"
      v-loading="loading"
      :element-loading-text="loadingText"
      style="width: 100% !important"
    >
      <div class="error-container" v-if="showError">{{ errorMessage }}</div>
      <div v-else class="main-data-container">
        <div class="entity-navbar">
          <div class="left d-flex">
            <el-button
              v-if="displayBackButton"
              icon="el-icon-back"
              size="mini"
              class="mr-1"
              style="height: 30px"
              @click="backToEntityInfoList"
            ></el-button>
            <h3 v-if="currentEntity && currentEntity.name">
              {{ currentEntity.name }}
            </h3>
          </div>
          <div class="right d-flex">
            <el-dropdown
              class="ml-1"
              placement="top"
              v-if="
                currentEntity &&
                currentEntity.default_printing_document &&
                (checkPerimission('DOCUMENT') ||
                  checkPerimission('SEND NOTIFICATION'))
              "
            >
              <el-button
                size="mini"
                class="float-right layout-selector send-notification-button"
              >
                <i class="el-icon-s-promotion"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <a
                  @click="saveAndPrint(true)"
                  v-if="
                    currentEntity &&
                    currentEntity.default_printing_document &&
                    checkPerimission('DOCUMENT')
                  "
                >
                  <el-dropdown-item>
                    <i class="fa fa-print" aria-hidden="true"></i>
                    {{
                      entityDataId
                        ? isEdit
                          ? "Update & Print"
                          : "Print"
                        : "Save & Print"
                    }}
                  </el-dropdown-item>
                </a>

                <a
                  @click="saveAndPrint(false)"
                  v-if="
                    currentEntity &&
                    currentEntity.default_printing_document &&
                    checkPerimission('DOCUMENT')
                  "
                >
                  <el-dropdown-item>
                    <i class="el-icon-download"></i>
                    Download
                  </el-dropdown-item>
                </a>
                <a
                  @click="openSendNotification()"
                  v-if="
                    currentEntity &&
                    currentEntity.default_printing_document &&
                    checkPerimission('SEND NOTIFICATION')
                  "
                >
                  <el-dropdown-item>
                    <i class="el-icon-s-promotion"></i>
                    Send Notification
                  </el-dropdown-item>
                </a>
                <a @click="openWASendNotification()">
                  <el-dropdown-item>
                    <i class="el-icon-s-promotion"></i>
                    Send WA Notification
                  </el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              v-if="
                checkPerimission('LAYOUT') &&
                IframePermissions('LAYOUT', 'EntityDetailedView')
              "
            >
              <el-button class="float-right layout-selector" size="mini">
                <img src="@/assets/img/icons/entityViews/layoutselect.svg" />
                Layout
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <a @click="switchEntityView('STANDARD')">
                  <el-dropdown-item>Standard</el-dropdown-item>
                </a>
                <a @click="switchEntityView('PROFILE')">
                  <el-dropdown-item>Profile</el-dropdown-item>
                </a>
                <a @click="switchEntityView('COVER')">
                  <el-dropdown-item>Cover</el-dropdown-item>
                </a>
                <a
                  @click="switchEntityView('SPLIT_VIEW')"
                  v-if="$route.query.dataId"
                >
                  <el-dropdown-item>Split View</el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
            <el-link
              v-if="
                checkPerimission('LAYOUT OPTIONS') &&
                IframePermissions('LAYOUT OPTIONS', 'EntityDetailedView')
              "
              icon="el-icon-more"
              :underline="false"
              style="transform: rotate(90deg); height: 40px"
              @click="openViewConfigPage"
            ></el-link>
            <div class="right-block float-right" v-if="shouldShowLink">
              <el-link
                icon="el-icon-link"
                style="font-size: 24px; margin-top: 1px"
                :underline="false"
                @click="ViewConfigPage"
              ></el-link>
            </div>
          </div>
        </div>
        <div class="profile-view-container" v-if="activeLayout == 'PROFILE'">
          <el-row :gutter="15">
            <el-col :xl="5" :lg="4" :md="5">
              <div class="filter-card">
                <div class="card-image">
                  <div>
                    <!-- <i class="el-icon-picture-outline"></i> -->
                    <el-tooltip
                      v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                      :content="
                        isEdit ? 'Upload profile picture' : 'Profile picture'
                      "
                    >
                      <div v-if="!upLoadEffect" @click="checkAndUpdateProfile">
                        <img
                          v-if="getFieldValue('profile')"
                          :src="getFieldValue('profile')"
                          style="border-radius: 50%"
                        />
                        <img
                          v-else
                          src="@/assets/img/defaultAvatar/Individual.svg"
                          alt="icon"
                          style="border-radius: 50%"
                        />
                      </div>
                    </el-tooltip>
                    <div v-else>
                      <img
                        v-if="getFieldValue('profile')"
                        :src="getFieldValue('profile')"
                        alt="icon"
                        style="border-radius: 50%"
                      />
                      <img
                        v-else
                        src="@/assets/img/defaultAvatar/Business.svg"
                        alt="icon"
                        height="20"
                        width="20"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <p class="card-title" v-if="getFieldValue('title')">
                    {{ getFieldValue("title") | truncate(24, "...") }}
                  </p>
                  <p
                    class="card-descripion"
                    v-if="getFieldValue('description')"
                  >
                    {{ getFieldValue("description") | truncate(140, "...") }}
                  </p>
                </div>
                <el-scrollbar wrap-style="max-height: 300px">
                  <div class="card-fields">
                    <div class="card-fields-inner">
                      <div
                        v-for="(field, i) in showFields.fields"
                        :key="field.key + '_show_fields'"
                        class="mt-1"
                      >
                        <div v-if="i < 5">
                          <span
                            class="field-label"
                            v-if="field && field.template_id && field.key"
                            >{{ field.label }}</span
                          >

                          <div v-if="field && field.input_type == 'DATE'">
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | globalDateFormat
                              }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'DATE_TIME'"
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | moment("MM-DD-YYYY hh:mm:ss A")
                              }}
                            </p>
                          </div>

                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field) | toDateTimeRange
                              }}
                              <!-- | toDateTimeRange -->
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toTimeRange }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'WEEKDAYS'"
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toWeekDays }}
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_RANGE'
                            "
                          >
                            <p class="field-value">
                              <!-- {{getFieldValue(scope.row, field)}}<br /> -->
                              {{ getFieldValue("fields", field) | toDateRange }}
                              <!-- | toDateRange -->
                            </p>
                          </div>
                          <div v-else>
                            <p
                              class="field-value"
                              v-if="getFieldValue('fields', field)"
                            >
                              {{
                                getFieldValue("fields", field)
                                  | truncate(30, "...")
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </el-col>
            <el-col :xl="18" :lg="20" :md="19" :sm="24" :xs="24">
              <div class="data-card-container">
                <div
                  v-if="
                    customization == null ||
                    (customization &&
                      customization.tab_groups &&
                      customization.tab_groups.length == 0)
                  "
                >
                  <el-tabs
                    type="card"
                    @tab-click="changeTab('tab')"
                    v-model="currrentTab"
                    class="data-card-tabs"
                  >
                    <el-tab-pane
                      label="Details"
                      name="entities_data"
                    ></el-tab-pane>
                    <template>
                      <el-tab-pane
                        v-for="(reationship, index) of relationshipsData"
                        :key="'reationships' + index"
                        :label="reationship.relationship_title"
                        :name="reationship.relationship_id"
                      ></el-tab-pane>
                      <template v-if="showNestedRelaships">
                        <el-tab-pane
                          v-for="(
                            reationship, index
                          ) of nestedRelationshipsData"
                          :key="
                            'nestedRelationshipsData' + index + reationship._id
                          "
                          :label="reationship.relationship_title"
                          :name="reationship._id"
                        ></el-tab-pane>
                      </template>
                    </template>
                    <el-tab-pane label="Files" name="files"></el-tab-pane>
                    <el-tab-pane
                      label="eSignatures"
                      name="esignature"
                      v-if="!isApplicationUserSide"
                    ></el-tab-pane>
                    <el-tab-pane
                      label="Notifications"
                      name="emails"
                    ></el-tab-pane>
                  </el-tabs>
                  <div class="template-container">
                    <el-row
                      v-if="showTemplate && currrentTab == 'entities_data'"
                      class="template-container"
                    >
                      <entity-template-view
                        v-if="currrentTab == 'entities_data'"
                        :currentEntity="currentEntity"
                        :entityData="entityData"
                        :isEdit="isEdit"
                        :layout="activeLayout"
                        :isApplicationUserSide="isApplicationUserSide"
                        :entityDataExists="entityDataExists"
                        :profilePicture="getUserprofilepicture"
                        :allEntitiesDataFromParent="allEntitiesData"
                        :allFormTemplatesFromParent="allFormTemplates"
                        :allCompanyUserTypesDataFromParent="
                          allCompanyUserTypesData
                        "
                        v-on:refresh-page="refreshThePage"
                        @closeParent="backToEntityInfoList"
                        :getSuggestionFormData="getSuggestionFormData"
                      ></entity-template-view>
                    </el-row>
                    <el-row v-else-if="currrentTab == 'esignature'">
                      <entity-documents
                        :entityId="entity_id"
                        :entityDataId="entityDataId"
                        :entityData="entityData"
                        :isDocument="false"
                        :isATemplate="true"
                      ></entity-documents>
                    </el-row>
                    <el-row v-else-if="currrentTab == 'files'">
                      <entity-data-documents
                        v-if="!refresh"
                        :entityDataId="entityDataId"
                        :entityId="entity_id"
                        :folder="folder"
                        :isApplicationUserSide="isApplicationUserSide"
                        :getEntityPermission="getEntityPermission"
                        :primaryLabel="getPrimaryLabel"
                        @moveToFolder="moveToFolder"
                      ></entity-data-documents>
                    </el-row>
                    <el-row v-else-if="currrentTab == 'emails'">
                      <EntityEmails />
                    </el-row>
                    <el-row v-else-if="isCurrentSlugIsRelationship && showData">
                      <EntityReationshipDataTable
                        :relationshipDetails="getCurrentRelationshipDetails"
                        :nextRealationshipDetails="hasNextRelationshipDetails"
                        :hideLabel="true"
                        :currentEntityData="currentEntityData"
                        :customization="customization"
                      />
                    </el-row>

                    <el-row
                      v-else-if="isCurrentSlugIsNestedRelationship && showData"
                    >
                      <EntityNestedReationshipDataTable
                        :relationshipDetails="
                          getCurrentNestedRelationshipDetails
                        "
                        :nextRealationshipDetails="null"
                        :hideLabel="true"
                        :customization="customization"
                      />
                    </el-row>
                  </div>
                </div>
                <div v-else>
                  <el-tabs
                    v-model="currrentTab"
                    type="card"
                    class="data-card-tabs"
                    @tab-click="changeTab('tab')"
                  >
                    <el-tab-pane
                      v-for="(tab, index) in customization.tab_groups"
                      :key="index"
                      :name="tab.label"
                      :label="tab.label"
                    >
                      <div v-if="tab.tabs.length == 1">
                        <el-row
                          v-if="
                            tab.tabs[0].feature_type == 'TEMPLATE' && showData
                          "
                        >
                          <fields-preview-template
                            :templateData="
                              getCurrentStep(tab.tabs[0].feature_id)
                                .templateInfo
                            "
                            :has-next="false"
                            :entityDataId="entityDataId"
                            :workflow-step-id="
                              getCurrentStep(tab.tabs[0].feature_id)._id
                            "
                            :template-data-id="
                              getCurrentStep(tab.tabs[0].feature_id)
                                .templateDataId
                            "
                            :currentStep="
                              getCurrentStep(tab.tabs[0].feature_id)
                            "
                            :is-execute="isEdit"
                            :layout="customization.active_layout"
                            :currentEntity="currentEntity"
                            :entitySettings="entitySettings"
                            :isEdit="isEdit"
                            :entityData="entityData"
                            :currentMenu="currentMenu()"
                            :entityDataExists="entityDataExists"
                            :profilePicture="getUserprofilepicture"
                            :customization="customization"
                            :saveTemp="saveTemp"
                            :openNew="openNew"
                            @goToNextTab="goToNextTab"
                            @closeParent="backToEntityInfoList"
                            :getSuggestionFormData="getSuggestionFormData"
                          ></fields-preview-template>
                        </el-row>
                        <el-row
                          v-else-if="
                            tab.tabs[0].feature_type == 'RELATIONSHIP' &&
                            showData &&
                            currrentTab == tab.label
                          "
                        >
                          <EntityReationshipDataTable
                            :relationshipDetails="
                              getCurrentRelation(tab.tabs[0].feature_id)
                            "
                            :nextRealationshipDetails="null"
                            :hideLabel="true"
                            :currentEntityData="currentEntityData"
                            :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                            :customization="customization"
                          />
                        </el-row>
                        <el-row
                          v-else-if="
                            tab.tabs[0].feature_type == 'NESTED_RELATIONSHIP' &&
                            showData &&
                            currrentTab == tab.label
                          "
                        >
                          <EntityNestedReationshipDataTable
                            :relationshipDetails="
                              getCurrentNestedRelaiton(tab.tabs[0].feature_id)
                            "
                            :nextRealationshipDetails="null"
                            :hideLabel="true"
                            :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                            :customization="customization"
                          />
                        </el-row>
                        <el-row
                          v-else-if="tab.tabs[0].feature_type == 'OTHERS'"
                        >
                          <div v-if="tab.tabs[0].feature_id == 'Files'">
                            <entity-data-documents
                              v-if="!refresh"
                              :entityDataId="entityDataId"
                              :entityId="entity_id"
                              :folder="folder"
                              :isApplicationUserSide="isApplicationUserSide"
                              :getEntityPermission="getEntityPermission"
                              :primaryLabel="getPrimaryLabel"
                              @moveToFolder="moveToFolder"
                            ></entity-data-documents>
                          </div>
                          <div
                            v-else-if="tab.tabs[0].feature_id == 'eSignatures'"
                          >
                            <entity-documents
                              :entityId="entity_id"
                              :entityDataId="entityDataId"
                              :isDocument="true"
                              :isATemplate="true"
                              :customization="customization"
                            ></entity-documents>
                          </div>
                          <div v-else-if="tab.tabs[0].feature_id == 'Contacts'">
                            <entity-contacts
                              :contactType="entity_id"
                              :accountName="entityDataId"
                              :entityDataId="currentEntity"
                              :hideLabel="true"
                            ></entity-contacts>
                          </div>
                          <div v-else-if="tab.tabs[0].feature_id == 'Emails'">
                            <EntityEmails />
                          </div>
                        </el-row>
                      </div>
                      <div v-else>
                        <el-tabs
                          v-model="currentSubTab"
                          @tab-click="changeTab('sub')"
                          :before-leave="
                            (newTab, oldTab) =>
                              handleBeforeLeave('sub', newTab, oldTab)
                          "
                        >
                          <el-tab-pane
                            v-for="(sub_tab, sub_index) in tab.tabs"
                            :key="sub_index"
                            :name="sub_tab.feature_id + '#' + sub_index"
                            :label="sub_tab.label"
                          ></el-tab-pane>
                        </el-tabs>
                        <div class="template-wrapper">
                          <el-row
                            v-if="
                              currentSubTabFeatureType == 'TEMPLATE' && showData
                            "
                          >
                            <fields-preview-template
                              :templateData="
                                getCurrentStep(currentSubTab.split('#')[0])
                                  .templateInfo
                              "
                              :has-next="false"
                              :entityDataId="entityDataId"
                              :workflow-step-id="
                                getCurrentStep(currentSubTab.split('#')[0])._id
                              "
                              :template-data-id="
                                getCurrentStep(currentSubTab.split('#')[0])
                                  .templateDataId
                              "
                              :currentStep="
                                getCurrentStep(currentSubTab.split('#')[0])
                              "
                              :is-execute="isEdit"
                              :layout="customization.active_layout"
                              :currentEntity="currentEntity"
                              :entitySettings="entitySettings"
                              :isEdit="isEdit"
                              :entityData="entityData"
                              :currentMenu="currentMenu()"
                              :entityDataExists="entityDataExists"
                              :profilePicture="getUserprofilepicture"
                              :customization="customization"
                              :saveTemp="saveTemp"
                              :openNew="openNew"
                              :allEntitiesDataFromParent="allEntitiesData"
                              :allFormTemplatesFromParent="allFormTemplates"
                              :allCompanyUserTypesDataFromParent="
                                allCompanyUserTypesData
                              "
                              @goToNextTab="goToNextTab"
                              @formDataUpdated="handleFormUpdate"
                              @validationFailed="checkingIsValid"
                              :formDataInTabsChange="formDataToSend"
                              @templateUpdated="templateUpdated"
                              @closeParent="backToEntityInfoList"
                              :getSuggestionFormData="getSuggestionFormData"
                              v-on:getDataFromTemplatesPreview="
                                getDataFromPreviewTemplate
                              "
                              :saveAndSwitch="saveAndSwitch"
                              :isTabClickedWithoutSaving="
                                isTabClickedWithoutSaving
                              "
                              :previousSavingTab="previousSavingTab"
                            ></fields-preview-template>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'RELATIONSHIP' &&
                              showData
                            "
                          >
                            <EntityReationshipDataTable
                              :relationshipDetails="
                                getCurrentRelation(currentSubTab.split('#')[0])
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :currentEntityData="currentEntityData"
                              :currentTabInfo="
                                getTabInfo(currentSubTab.split('#')[0])
                              "
                              :customization="customization"
                            />
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType ==
                                'NESTED_RELATIONSHIP' && showData
                            "
                          >
                            <EntityNestedReationshipDataTable
                              :relationshipDetails="
                                getCurrentNestedRelaiton(
                                  currentSubTab.split('#')[0]
                                )
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :currentTabInfo="
                                getTabInfo(currentSubTab.split('#')[0])
                              "
                              :customization="customization"
                            />
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Files'
                            "
                          >
                            <entity-data-documents
                              v-if="!refresh"
                              :entityDataId="entityDataId"
                              :entityId="entity_id"
                              :folder="folder"
                              :isApplicationUserSide="isApplicationUserSide"
                              :getEntityPermission="getEntityPermission"
                              :primaryLabel="getPrimaryLabel"
                              @moveToFolder="moveToFolder"
                            ></entity-data-documents>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'eSignatures'
                            "
                          >
                            <entity-documents
                              :entityId="entity_id"
                              :entityDataId="entityDataId"
                              :isDocument="true"
                              :isATemplate="false"
                              :customization="customization"
                            ></entity-documents>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Contacts'
                            "
                          >
                            <entity-contacts
                              :contactType="entity_id"
                              :accountName="entityDataId"
                              :entityDataId="currentEntity"
                              :hideLabel="true"
                            ></entity-contacts>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Emails'
                            "
                          >
                            <EntityEmails />
                          </el-row>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="profile-view-container" v-else-if="activeLayout == 'COVER'">
          <el-row>
            <div class="profile-upper">
              <div id="profile-banner-image">
                <img
                  v-if="getFieldValue('cover_profile')"
                  :src="getFieldValue('cover_profile')"
                />
                <div class="empty-profile-banner-image" v-else>
                  <i class="el-icon-picture-outline"></i>
                </div>
              </div>
              <div id="profile-d">
                <div
                  id="profile-pic"
                  @click="checkAndUpdateProfile"
                  v-if="!upLoadEffect"
                >
                  <img
                    v-if="getFieldValue('profile')"
                    :src="getFieldValue('profile')"
                    style="border-radius: 50%"
                  />
                  <div v-else>
                    <!-- <i class="el-icon-picture-outline"></i> -->
                    <img
                      v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                      src="@/assets/img/defaultAvatar/Individual.svg"
                      alt="icon"
                      style="border-radius: 50%"
                    />
                    <img
                      v-else
                      src="@/assets/img/defaultAvatar/Business.svg"
                      alt="icon"
                      height="20"
                      width="20"
                    />
                  </div>
                </div>
              </div>
              <div id="black-grd"></div>
            </div>
          </el-row>
          <el-row :gutter="15">
            <el-col :xl="5" :lg="5" :md="24" :sm="24" :xs="24">
              <div class="filter-card">
                <div class="card-body" style="margin-top: 75px !important">
                  <p class="card-title" v-if="getFieldValue('title')">
                    {{ getFieldValue("title") | truncate(24, "...") }}
                  </p>
                  <p
                    class="card-descripion"
                    v-if="getFieldValue('description')"
                  >
                    {{ getFieldValue("description") | truncate(140, "...") }}
                  </p>
                </div>
                <el-scrollbar wrap-style="max-height: 300px">
                  <div class="card-fields">
                    <div class="card-fields-inner">
                      <div
                        v-for="(field, i) in showFields.fields"
                        :key="field.key + '_show_fields_1'"
                        class="mt-1"
                      >
                        <!-- <div v-if="i < 5">
                        <span
                          class="field-label"
                          v-if="field && field.template_id && field.key"
                        >{{ field.label }}</span>
                        <p
                          class="field-value"
                          v-if="getFieldValue('fields', field)"
                        >
                          {{
                            getFieldValue("fields", field) | truncate(30, "...")
                          }}
                        </p>
                        </div>-->
                        <div v-if="i < 5">
                          <span
                            class="field-label"
                            v-if="field && field.template_id && field.key"
                            >{{ field.label }}</span
                          >
                          <div v-if="field && field.input_type == 'DATE'">
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | globalDateFormat
                              }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'DATE_TIME'"
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field)
                                  | moment("MM-DD-YYYY hh:mm:ss A")
                              }}
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{
                                getFieldValue("fields", field) | toDateTimeRange
                              }}
                              <!-- | toDateTimeRange -->
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'TIME_RANGE'
                            "
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toTimeRange }}
                            </p>
                          </div>
                          <div
                            v-else-if="field && field.input_type == 'WEEKDAYS'"
                          >
                            <p class="field-value">
                              {{ getFieldValue("fields", field) | toWeekDays }}
                            </p>
                          </div>
                          <div
                            v-else-if="
                              field && field.input_type == 'DATE_RANGE'
                            "
                          >
                            <p class="field-value">
                              <!-- {{getFieldValue(scope.row, field)}}<br /> -->
                              {{ getFieldValue("fields", field) | toDateRange }}
                              <!-- | toDateRange -->
                            </p>
                          </div>
                          <div v-else>
                            <p
                              class="field-value"
                              v-if="getFieldValue('fields', field)"
                            >
                              {{
                                getFieldValue("fields", field)
                                  | truncate(30, "...")
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </el-col>
            <el-col :xl="19" :lg="19" :md="24" :sm="24" :xs="24">
              <div class="data-card-container">
                <div
                  v-if="
                    customization == null ||
                    (customization &&
                      customization.tab_groups &&
                      customization.tab_groups.length == 0)
                  "
                >
                  <el-tabs
                    type="card"
                    @tab-click="changeTab('tab')"
                    v-model="currrentTab"
                    class="data-card-tabs"
                  >
                    <el-tab-pane
                      label="Details"
                      name="entities_data"
                    ></el-tab-pane>
                    <template>
                      <el-tab-pane
                        v-for="(reationship, index) of relationshipsData"
                        :key="'reationships' + index"
                        :label="reationship.relationship_title"
                        :name="reationship.relationship_id"
                      ></el-tab-pane>
                      <template v-if="showNestedRelaships">
                        <el-tab-pane
                          v-for="(
                            reationship, index
                          ) of nestedRelationshipsData"
                          :key="'nestedRelationshipsData' + index"
                          :label="reationship.relationship_title"
                          :name="reationship._id"
                        ></el-tab-pane>
                      </template>
                    </template>
                    <el-tab-pane label="Files" name="files"></el-tab-pane>
                    <el-tab-pane
                      label="eSignatures"
                      name="esignature"
                      v-if="!isApplicationUserSide"
                    ></el-tab-pane>
                    <el-tab-pane
                      label="Notifications"
                      name="emails"
                    ></el-tab-pane>
                  </el-tabs>
                  <div class="cover-view-card">
                    <el-row
                      v-if="showTemplate && currrentTab == 'entities_data'"
                    >
                      <entity-template-view
                        v-if="currrentTab == 'entities_data'"
                        :currentEntity="currentEntity"
                        :entityData="entityData"
                        :isEdit="isEdit"
                        :layout="activeLayout"
                        :entityDataExists="entityDataExists"
                        :profilePicture="getUserprofilepicture"
                        :allEntitiesDataFromParent="allEntitiesData"
                        :allFormTemplatesFromParent="allFormTemplates"
                        :allCompanyUserTypesDataFromParent="
                          allCompanyUserTypesData
                        "
                        v-on:refresh-page="refreshThePage"
                        @closeParent="backToEntityInfoList"
                        :getSuggestionFormData="getSuggestionFormData"
                      ></entity-template-view>
                    </el-row>
                    <el-row v-else-if="currrentTab == 'esignature'">
                      <entity-documents
                        :entityId="entity_id"
                        :entityDataId="entityDataId"
                        :isDocument="true"
                      ></entity-documents>
                    </el-row>
                    <el-row v-else-if="currrentTab == 'files'">
                      <entity-data-documents
                        v-if="!refresh"
                        :entityDataId="entityDataId"
                        :entityId="entity_id"
                        :folder="folder"
                        :isApplicationUserSide="isApplicationUserSide"
                        :getEntityPermission="getEntityPermission"
                        :primaryLabel="getPrimaryLabel"
                        @moveToFolder="moveToFolder"
                      ></entity-data-documents>
                    </el-row>
                    <el-row v-else-if="currrentTab == 'emails'">
                      <EntityEmails />
                    </el-row>
                    <el-row v-else-if="isCurrentSlugIsRelationship && showData">
                      <EntityReationshipDataTable
                        :relationshipDetails="getCurrentRelationshipDetails"
                        :nextRealationshipDetails="hasNextRelationshipDetails"
                        :hideLabel="true"
                        :currentEntityData="currentEntityData"
                        :customization="customization"
                      />
                    </el-row>

                    <el-row
                      v-else-if="isCurrentSlugIsNestedRelationship && showData"
                    >
                      <EntityNestedReationshipDataTable
                        :relationshipDetails="
                          getCurrentNestedRelationshipDetails
                        "
                        :nextRealationshipDetails="null"
                        :hideLabel="true"
                        :customization="customization"
                      />
                    </el-row>
                  </div>
                </div>
                <div v-else>
                  <el-tabs
                    v-model="currrentTab"
                    type="card"
                    class="data-card-tabs"
                    @tab-click="changeTab('tab')"
                  >
                    <el-tab-pane
                      v-for="(tab, index) in customization.tab_groups"
                      :key="index"
                      :name="tab.label"
                      :label="tab.label"
                    >
                      <div v-if="tab.tabs.length == 1">
                        <el-row
                          v-if="
                            tab.tabs[0].feature_type == 'TEMPLATE' && showData
                          "
                        >
                          <fields-preview-template
                            :templateData="
                              getCurrentStep(tab.tabs[0].feature_id)
                                .templateInfo
                            "
                            :has-next="false"
                            :entityDataId="entityDataId"
                            :workflow-step-id="
                              getCurrentStep(tab.tabs[0].feature_id)._id
                            "
                            :template-data-id="
                              getCurrentStep(tab.tabs[0].feature_id)
                                .templateDataId
                            "
                            :currentStep="
                              getCurrentStep(tab.tabs[0].feature_id)
                            "
                            :is-execute="isEdit"
                            :layout="customization.active_layout"
                            :currentEntity="currentEntity"
                            :entitySettings="entitySettings"
                            :isEdit="isEdit"
                            :entityData="entityData"
                            :currentMenu="currentMenu()"
                            :entityDataExists="entityDataExists"
                            :profilePicture="getUserprofilepicture"
                            :customization="customization"
                            :saveTemp="saveTemp"
                            :openNew="openNew"
                            :allEntitiesDataFromParent="allEntitiesData"
                            :allFormTemplatesFromParent="allFormTemplates"
                            :allCompanyUserTypesDataFromParent="
                              allCompanyUserTypesData
                            "
                            @goToNextTab="goToNextTab"
                            @closeParent="backToEntityInfoList"
                            :getSuggestionFormData="getSuggestionFormData"
                          ></fields-preview-template>
                        </el-row>
                        <el-row
                          v-else-if="
                            tab.tabs[0].feature_type == 'RELATIONSHIP' &&
                            showData &&
                            currrentTab == tab.label
                          "
                        >
                          <EntityReationshipDataTable
                            :relationshipDetails="
                              getCurrentRelation(tab.tabs[0].feature_id)
                            "
                            :nextRealationshipDetails="null"
                            :hideLabel="true"
                            :currentEntityData="currentEntityData"
                            :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                            :customization="customization"
                          />
                        </el-row>
                        <el-row
                          v-else-if="
                            tab.tabs[0].feature_type == 'NESTED_RELATIONSHIP' &&
                            showData &&
                            currrentTab == tab.label
                          "
                        >
                          <EntityNestedReationshipDataTable
                            :relationshipDetails="
                              getCurrentNestedRelaiton(tab.tabs[0].feature_id)
                            "
                            :nextRealationshipDetails="null"
                            :hideLabel="true"
                            :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                            :customization="customization"
                          />
                        </el-row>
                        <el-row
                          v-else-if="tab.tabs[0].feature_type == 'OTHERS'"
                        >
                          <div v-if="tab.tabs[0].feature_id == 'Files'">
                            <entity-data-documents
                              v-if="!refresh"
                              :entityDataId="entityDataId"
                              :entityId="entity_id"
                              :folder="folder"
                              :isApplicationUserSide="isApplicationUserSide"
                              :getEntityPermission="getEntityPermission"
                              :primaryLabel="getPrimaryLabel"
                              @moveToFolder="moveToFolder"
                            ></entity-data-documents>
                          </div>
                          <div
                            v-else-if="tab.tabs[0].feature_id == 'eSignatures'"
                          >
                            <entity-documents
                              :entityId="entity_id"
                              :entityDataId="entityDataId"
                              :isDocument="true"
                              :customization="customization"
                            ></entity-documents>
                          </div>
                          <div v-else-if="tab.tabs[0].feature_id == 'Contacts'">
                            <entity-contacts
                              :contactType="entity_id"
                              :accountName="entityDataId"
                              :entityDataId="currentEntity"
                              :hideLabel="true"
                            ></entity-contacts>
                          </div>
                          <div v-else-if="tab.tabs[0].feature_id == 'Emails'">
                            <EntityEmails />
                          </div>
                        </el-row>
                      </div>
                      <div v-else>
                        <el-tabs
                          v-model="currentSubTab"
                          @tab-click="changeTab('sub')"
                          :before-leave="
                            (newTab, oldTab) =>
                              handleBeforeLeave('sub', newTab, oldTab)
                          "
                        >
                          <el-tab-pane
                            v-for="(sub_tab, sub_index) in tab.tabs"
                            :key="sub_index"
                            :name="sub_tab.feature_id + '#' + sub_index"
                            :label="sub_tab.label"
                          ></el-tab-pane>
                        </el-tabs>
                        <div style="width: 100% !important" class="filter-card">
                          <el-row
                            v-if="
                              currentSubTabFeatureType == 'TEMPLATE' && showData
                            "
                          >
                            <fields-preview-template
                              :templateData="
                                getCurrentStep(currentSubTab.split('#')[0])
                                  .templateInfo
                              "
                              :has-next="false"
                              :entityDataId="entityDataId"
                              :formDataUpdated="handleFormDataUpdate"
                              :workflow-step-id="
                                getCurrentStep(currentSubTab.split('#')[0])._id
                              "
                              :template-data-id="
                                getCurrentStep(currentSubTab.split('#')[0])
                                  .templateDataId
                              "
                              :currentStep="
                                getCurrentStep(currentSubTab.split('#')[0])
                              "
                              :is-execute="isEdit"
                              :layout="customization.active_layout"
                              :currentEntity="currentEntity"
                              :entitySettings="entitySettings"
                              :isEdit="isEdit"
                              :entityData="entityData"
                              :currentMenu="currentMenu()"
                              :entityDataExists="entityDataExists"
                              :profilePicture="getUserprofilepicture"
                              :customization="customization"
                              :saveTemp="saveTemp"
                              :openNew="openNew"
                              :allEntitiesDataFromParent="allEntitiesData"
                              :allFormTemplatesFromParent="allFormTemplates"
                              :allCompanyUserTypesDataFromParent="
                                allCompanyUserTypesData
                              "
                              @goToNextTab="goToNextTab"
                              @closeParent="backToEntityInfoList"
                              :getSuggestionFormData="getSuggestionFormData"
                              @formDataUpdated="handleFormUpdate"
                              @validationFailed="checkingIsValid"
                              :formDataInTabsChange="formDataToSend"
                              @templateUpdated="templateUpdated"
                              v-on:getDataFromTemplatesPreview="
                                getDataFromPreviewTemplate
                              "
                              :saveAndSwitch="saveAndSwitch"
                              :isTabClickedWithoutSaving="
                                isTabClickedWithoutSaving
                              "
                              :previousSavingTab="previousSavingTab"
                            ></fields-preview-template>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'RELATIONSHIP' &&
                              showData
                            "
                          >
                            <EntityReationshipDataTable
                              :relationshipDetails="
                                getCurrentRelation(currentSubTab.split('#')[0])
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :currentEntityData="currentEntityData"
                              :currentTabInfo="
                                getTabInfo(currentSubTab.split('#')[0])
                              "
                              :customization="customization"
                            />
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType ==
                                'NESTED_RELATIONSHIP' && showData
                            "
                          >
                            <EntityNestedReationshipDataTable
                              :relationshipDetails="
                                getCurrentNestedRelaiton(
                                  currentSubTab.split('#')[0]
                                )
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :currentTabInfo="
                                getTabInfo(currentSubTab.split('#')[0])
                              "
                              :customization="customization"
                            />
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Files'
                            "
                          >
                            <entity-data-documents
                              v-if="!refresh"
                              :entityDataId="entityDataId"
                              :entityId="entity_id"
                              :folder="folder"
                              :isApplicationUserSide="isApplicationUserSide"
                              :getEntityPermission="getEntityPermission"
                              :primaryLabel="getPrimaryLabel"
                              @moveToFolder="moveToFolder"
                            ></entity-data-documents>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'eSignatures'
                            "
                          >
                            <entity-documents
                              :entityId="entity_id"
                              :entityDataId="entityDataId"
                              :isDocument="true"
                              :customization="customization"
                            ></entity-documents>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Contacts'
                            "
                          >
                            <entity-contacts
                              :contactType="entity_id"
                              :accountName="entityDataId"
                              :entityDataId="currentEntity"
                              :hideLabel="true"
                            ></entity-contacts>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Emails'
                            "
                          >
                            <EntityEmails />
                          </el-row>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else-if="activeLayout == 'SPLIT_VIEW'">
          <div class="mt-1">
            <el-row class="split-view-container">
              <el-col
                class="split-view-col"
                :span="7"
                style="background: #cde4e436; height: 100vh; max-width: 350px"
              >
                <template>
                  <div class="company-details-container mb-2 mt-0">
                    <div class="company-view-icon">
                      <i class="el-icon-office-building"></i>
                    </div>

                    <div class="company-header-container">
                      <el-tooltip
                        v-if="getFieldValue('title')?.length > 20"
                        class="item"
                        effect="company-view-tooltip"
                        :content="getFieldValue('title')"
                        placement="top"
                      >
                        <div class="bold-key company-title">
                          {{ getFieldValue("title") | truncate(20, "...") }}
                        </div>
                      </el-tooltip>

                      <div
                        v-else-if="getFieldValue('title')?.length < 20"
                        class="bold-key company-title"
                      >
                        {{ getFieldValue("title") }}
                      </div>

                      <el-tooltip
                        v-if="getFieldValue('description')?.length > 20"
                        class="item"
                        effect="company-view-tooltip"
                        :content="getFieldValue('description')"
                        placement="top-end"
                      >
                        <div
                          v-if="getFieldValue('description')"
                          class="bold-key"
                        >
                          {{
                            getFieldValue("description") | truncate(20, "...")
                          }}
                        </div>
                      </el-tooltip>
                      <div
                        v-else-if="getFieldValue('description')?.length < 20"
                        class="bold-key"
                      >
                        {{ getFieldValue("description") }}
                      </div>
                    </div>
                  </div>
                  <template v-if="allEntityFields">
                    <ul
                      class="circle-buttons-container d-flex flex-row mb-1 mt-4"
                      v-if="combinedIcons && combinedIcons.length"
                    >
                      <div
                        class="d-flex flex-column align-items-center"
                        v-if="showFields.call && showFields.phoneField"
                      >
                      <el-button
                    round
                    circle
                    @click.prevent="$showPhoneNumberDialog(formattedPhoneNumber)"
                    class="custom-icon-button"
                    style="margin-right: 8px; font-size: 20px"
                  >
                    <el-tooltip
                      class="item"
                      effect="company-view-tooltip"
                      content="Call"
                      placement="bottom"
                    >
                      <p class="el-icon-phone-outline"></p>
                    </el-tooltip>
                  </el-button>
                      </div>
                      <div
                        class="d-flex flex-column align-items-center"
                        v-if="showFields.email && showFields.emailField"

                      >
                        <el-button
                          round
                          circle
                          class="custom-icon-button"
                          style="margin-right: 8px; font-size: 20px"
                          @click.prevent="$showMailCompose(formattedEmail)"
                        >
                          <el-tooltip
                          class="item"
                          effect="company-view-tooltip"
                          content="Email"
                          placement="bottom"
                        >
                          <p class="el-icon-message"></p>
                        </el-tooltip>
                        </el-button>
                        <!-- Dynamic Label -->

                      </div>
                      <li
                        v-for="(activity, index) in getActivitiesToShow"
                        :key="index"
                        class="circle-button-label"
                      >
                        <div class="d-flex flex-column align-items-center">
                          <el-button
                        round
                        circle
                        @click="openActivity(activity)"
                        class="custom-icon-button"
                        style="margin-right: 8px"
                      >
                        <el-tooltip
                          class="item"
                          effect="company-view-tooltip"
                          :content="activity.label"
                          placement="bottom"
                        >
                          <icons :iconName="activity.icon"></icons>
                        </el-tooltip>
                      </el-button>
                        </div>
                      </li>
                      <li v-if="combinedIcons.length > 4">
                        <div class="d-flex flex-column align-items-center">
                          <el-dropdown trigger="click">
                            <el-tooltip
                            class="item"
                            effect="company-view-tooltip"
                            content="More"
                            placement="bottom"
                          >
                            <el-button
                              class="circle-more-button"
                              round
                              icon="el-icon-more-outline"
                              circle
                            />
                            </el-tooltip>
                            <el-dropdown-menu>
                              <el-dropdown-item
                                v-for="(activity, index) in combinedIcons.slice(
                                  4
                                )"
                                :key="index"
                                @click.native="openActivity(activity)"
                              >
                                <div class="activity-item">
                                  <icons
                                    class="activity-icon"
                                    :iconName="activity.icon"
                                  />
                                  <span class="activity-label">{{
                                    activity.label
                                  }}</span>
                                </div>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </li>
                    </ul>
                  </template>
                  <template>
                    <el-collapse
                      v-model="activeCollapse"
                      class="data-card-collapse"
                    >
                      <el-collapse-item name="about_this_company">
                        <template #title>
                          <div class="collapse-header">
                            <span class="title-text"
                              >About this {{ currentEntity.name }}</span
                            >
                          </div>
                        </template>

                        <div
                          v-if="
                            customization == null ||
                            (customization &&
                              customization.tab_groups &&
                              customization.tab_groups.length == 0)
                          "
                        >
                          <el-row
                            style="width: 100% !important"
                            class="filter-card new-profile-design"
                            v-if="
                              showTemplate && currrentTab == 'entities_data'
                            "
                          >
                            <entity-template-view
                              class="edit-data-view"
                              :isSplitView="true"
                              :showFields="showFields"
                              :currentEntity="currentEntity"
                              :entityData="entityData"
                              :isEdit="isEdit"
                              :layout="activeLayout"
                              :isApplicationUserSide="isApplicationUserSide"
                              :entityDataExists="entityDataExists"
                              :profilePicture="getUserprofilepicture"
                              :allEntitiesDataFromParent="allEntitiesData"
                              :allFormTemplatesFromParent="allFormTemplates"
                              :allCompanyUserTypesDataFromParent="
                                allCompanyUserTypesData
                              "
                              v-on:refresh-page="refreshThePage"
                              @closeParent="backToEntityInfoList"
                              :getSuggestionFormData="getSuggestionFormData"
                            ></entity-template-view>
                          </el-row>
                          <el-row v-else-if="currrentTab == 'esignature'">
                            <entity-documents
                              :entityId="entity_id"
                              :entityDataId="entityDataId"
                              :entityData="entityData"
                              :isDocument="false"
                              :isATemplate="true"
                            ></entity-documents>
                          </el-row>
                          <el-row v-else-if="currrentTab == 'files'">
                            <entity-data-documents
                              v-if="!refresh"
                              :entityDataId="entityDataId"
                              :entityId="entity_id"
                              :folder="folder"
                              :isApplicationUserSide="isApplicationUserSide"
                              :getEntityPermission="getEntityPermission"
                              :primaryLabel="getPrimaryLabel"
                              @moveToFolder="moveToFolder"
                            ></entity-data-documents>
                          </el-row>
                          <el-row v-else-if="currrentTab == 'emails'">
                            <EntityEmails />
                          </el-row>
                          <el-row
                            v-else-if="isCurrentSlugIsRelationship && showData"
                          >
                            <EntityReationshipDataTable
                              :isSplitView="true"
                              :showFields="showFields"
                              :relationshipDetails="
                                getCurrentRelationshipDetails
                              "
                              :nextRealationshipDetails="
                                hasNextRelationshipDetails
                              "
                              :hideLabel="true"
                              :currentEntityData="currentEntityData"
                              :customization="customization"
                            />
                          </el-row>

                          <el-row
                            v-else-if="
                              isCurrentSlugIsNestedRelationship && showData
                            "
                          >
                            <EntityNestedReationshipDataTable
                              :relationshipDetails="
                                getCurrentNestedRelationshipDetails
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :customization="customization"
                            />
                          </el-row>
                        </div>
                        <div
                          style="width: 100% !important"
                          class="filter-card new-profile-design"
                          v-else
                        >
                          <el-row
                            v-if="
                              currentSubTabFeatureType == 'TEMPLATE' && showData
                            "
                          >
                            <fields-preview-template
                              :isSplitView="true"
                              :templateData="
                                getCurrentStep(currentSubTab.split('#')[0])
                                  .templateInfo
                              "
                              :has-next="false"
                              :entityDataId="entityDataId"
                              :workflow-step-id="
                                getCurrentStep(currentSubTab.split('#')[0])._id
                              "
                              :template-data-id="
                                getCurrentStep(currentSubTab.split('#')[0])
                                  .templateDataId
                              "
                              :currentStep="
                                getCurrentStep(currentSubTab.split('#')[0])
                              "
                              :is-execute="isEdit"
                              :layout="customization.active_layout"
                              :currentEntity="currentEntity"
                              :entitySettings="entitySettings"
                              :isEdit="isEdit"
                              :entityData="entityData"
                              :currentMenu="currentMenu()"
                              :entityDataExists="entityDataExists"
                              :profilePicture="getUserprofilepicture"
                              :customization="customization"
                              :saveTemp="saveTemp"
                              :openNew="openNew"
                              :allEntitiesDataFromParent="allEntitiesData"
                              :allFormTemplatesFromParent="allFormTemplates"
                              :allCompanyUserTypesDataFromParent="
                                allCompanyUserTypesData
                              "
                              @goToNextTab="goToNextTab"
                              @closeParent="backToEntityInfoList"
                              :getSuggestionFormData="getSuggestionFormData"
                            ></fields-preview-template>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'RELATIONSHIP' &&
                              showData
                            "
                          >
                            <EntityReationshipDataTable
                              :isSplitView="true"
                              :showFields="showFields"
                              :relationshipDetails="
                                getCurrentRelation(currentSubTab.split('#')[0])
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :currentEntityData="currentEntityData"
                              :currentTabInfo="
                                getTabInfo(currentSubTab.split('#')[0])
                              "
                              :customization="customization"
                            />
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType ==
                                'NESTED_RELATIONSHIP' && showData
                            "
                          >
                            <EntityNestedReationshipDataTable
                              :relationshipDetails="
                                getCurrentNestedRelaiton(
                                  currentSubTab.split('#')[0]
                                )
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :currentTabInfo="
                                getTabInfo(currentSubTab.split('#')[0])
                              "
                              :customization="customization"
                            />
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Files'
                            "
                          >
                            <entity-data-documents
                              v-if="!refresh"
                              :entityDataId="entityDataId"
                              :entityId="entity_id"
                              :folder="folder"
                              :isApplicationUserSide="isApplicationUserSide"
                              :getEntityPermission="getEntityPermission"
                              :primaryLabel="getPrimaryLabel"
                              @moveToFolder="moveToFolder"
                            ></entity-data-documents>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'eSignatures'
                            "
                          >
                            <entity-documents
                              :entityId="entity_id"
                              :entityDataId="entityDataId"
                              :isDocument="true"
                              :isATemplate="false"
                              :customization="customization"
                            ></entity-documents>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Contacts'
                            "
                          >
                            <entity-contacts
                              :contactType="entity_id"
                              :accountName="entityDataId"
                              :entityDataId="currentEntity"
                              :hideLabel="true"
                            ></entity-contacts>
                          </el-row>
                          <el-row
                            v-else-if="
                              currentSubTabFeatureType == 'OTHERS' &&
                              currentSubTab.split('#')[0] == 'Emails'
                            "
                          >
                            <EntityEmails />
                          </el-row>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </template>
                </template>
              </el-col>
              <el-col
                :span="
                  showFields.split_col1 && showFields.split_col1.length
                    ? 10
                    : 17
                "
                style="margin-top: 0px; width: 150vw; min-height: 120vh"
                class="profile-panel-height split-view-col"
              >
                <el-tabs
                  v-model="activeTab"
                  type="card"
                  class="activity-overview-tab"
                >
                  <el-tab-pane label="Overview" name="overview">
                    <el-card class="box-card header-bottom-border-zero">
                      <div
                        slot="header"
                        class="d-flex flex-row data-highlights"
                      >
                        <h5 class="m-0">Data highlights</h5>
                      </div>
                      <div class="text item mt-0 data-highlights">
                        <div class="d-flex flex-wrap">
                          <div class="d-flex flex-column w-50 mb-2">
                            <small
                              class="text-uppercase d-flex justify-content-center"
                            >
                              <strong>Created Date</strong>
                            </small>
                            <small
                              class="d-flex justify-content-center text-center"
                              >{{ createdAt }}</small
                            >
                          </div>
                          <div class="d-flex flex-column w-50 mb-2">
                            <small
                              class="text-uppercase d-flex justify-content-center"
                            >
                              <strong>Recent activities</strong>
                            </small>
                            <small
                              class="d-flex justify-content-center text-center"
                              >{{ updatedAt }}</small
                            >
                          </div>
                        </div>
                      </div>
                    </el-card>
                    <div class="cover-view-card">
                      <el-row
                        v-for="(relationship, index) in relationshipsData"
                        :key="'relationship' + index"
                        :gutter="20"
                      >
                        <el-col :span="24">
                          <el-card class="relationship-card">
                            <div slot="header">
                              <h5 class="spacing">
                                {{ relationship.relationship_title }}
                              </h5>
                            </div>
                            <EntityReationshipDataTable
                              :isSplitView="true"
                              :split_col2="true"
                              :showFields="showFields"
                              :relationshipDetails="relationship"
                              :nextRealationshipDetails="
                                hasNextRelationshipDetails
                              "
                              :hideLabel="true"
                              :currentEntityData="currentEntityData"
                              :customization="customization"
                            />
                          </el-card>
                        </el-col>
                      </el-row>

                      <!-- Nested Relationships -->
                      <el-row
                        v-for="(
                          nestedRelationship, index
                        ) in nestedRelationshipsData"
                        :key="'nestedRelationship' + index"
                        :gutter="20"
                      >
                        <el-col :span="24">
                          <el-card class="nested-relationship-card">
                            <div slot="header">
                              <h5 class="spacing">
                                {{ nestedRelationship.relationship_title }}
                              </h5>
                            </div>
                            <EntityNestedReationshipDataTable
                              :relationshipDetails="
                                getCurrentNestedRelationshipDetails
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :customization="customization"
                            />
                          </el-card>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="Activities" name="activities" >
                    <template
                      v-if="
                        showFields.split_col2 &&
                        showFields.split_col2.length > 0
                      "
                    >
                      <div v-if="!customization">
                        <el-tabs v-model="activeSubTab" class="activities-tab" type="border-card">
                          <el-tab-pane
                            v-for="(activity, index) in showFields.split_col2"
                            :key="'activity_' + index"
                            :label="getActivityLabel(activity)"
                            :name="activity"
                            :lazy="true"
                            
                          >
                          <template v-if="activity === 'kf_email'">
                            <span slot="label"><i class="el-icon-message"></i> Emails</span>
                            <SplitEmails v-if="showFields.email && showFields.emailField" :email="formattedEmail" />
                            <ErrorMessage  v-else/>    
                          </template>

                          <template v-else-if="activity === 'call'">
                            <span slot="label"><i class="el-icon-phone"></i> Calls</span>
                            <CommingSoon  v-if="showFields.call && showFields.phoneField"  />
                            <ErrorMessage v-else />
                          </template>
                            <template
                              v-else-if="activity.startsWith('relationship_')"
                            >
                              <el-card class="relationship-card spacing">
                                <EntityReationshipDataTable
                                  :isSplitView="true"
                                  :showFields="showFields"
                                  :relationshipDetails="
                                    getRelationshipDetails(activity)
                                  "
                                  :nextRealationshipDetails="
                                    hasNextRelationshipDetails
                                  "
                                  :hideLabel="true"
                                  :currentEntityData="currentEntityData"
                                  :customization="customization"
                                />
                              </el-card>
                            </template>
                            <template
                              v-else-if="
                                activity.startsWith('nestedRelationship_')
                              "
                            >
                              <el-card class="relationship-card spacing">
                                <EntityNestedReationshipDataTable
                                  :relationshipDetails="
                                    getCurrentNestedRelaiton(activity)
                                  "
                                  :nextRealationshipDetails="null"
                                  :hideLabel="true"
                                  :customization="customization"
                                />
                              </el-card>
                            </template>
                            <template v-else-if="activity === 'files'">
                              <el-card class="relationship-card spacing">
                                <entity-data-documents
                                  :entityDataId="entityDataId"
                                  :entityId="entity_id"
                                  :folder="folder"
                                  :isApplicationUserSide="isApplicationUserSide"
                                  :getEntityPermission="getEntityPermission"
                                  :primaryLabel="getPrimaryLabel"
                                  @moveToFolder="moveToFolder"
                                />
                              </el-card>
                            </template>
                            <template v-else-if="activity === 'esignature'">
                              <entity-documents
                                :entityId="entity_id"
                                :entityDataId="entityDataId"
                                :isDocument="true"
                              />
                            </template>
                            <template v-else-if="activity === 'emails'">
                              <EntityEmails />
                            </template>
                          </el-tab-pane>
                        </el-tabs>
                      </div>
                      <div v-else>
                        <el-tabs v-model="activeSubTab" class="activities-tab" type="border-card">
                          <el-tab-pane
                            v-for="(tab, index) in filteredActivityTabGroups"
                            :key="index"
                            :name="tab.label"
                            :label="tab.label"
                            :lazy="true"
                          >
                          
                            <div v-if="tab.tabs.length == 1">
                              <el-row
                                v-if="
                                  tab.tabs[0].feature_type == 'TEMPLATE' &&
                                  showData
                                "
                              >
                                <fields-preview-template
                                  :templateData="
                                    getCurrentStep(tab.tabs[0].feature_id)
                                      .templateInfo
                                  "
                                  :has-next="false"
                                  :entityDataId="entityDataId"
                                  :workflow-step-id="
                                    getCurrentStep(tab.tabs[0].feature_id)._id
                                  "
                                  :template-data-id="
                                    getCurrentStep(tab.tabs[0].feature_id)
                                      .templateDataId
                                  "
                                  :currentStep="
                                    getCurrentStep(tab.tabs[0].feature_id)
                                  "
                                  :is-execute="isEdit"
                                  :layout="customization.active_layout"
                                  :currentEntity="currentEntity"
                                  :entitySettings="entitySettings"
                                  :isEdit="isEdit"
                                  :entityData="entityData"
                                  :currentMenu="currentMenu()"
                                  :entityDataExists="entityDataExists"
                                  :profilePicture="getUserprofilepicture"
                                  :customization="customization"
                                  :saveTemp="saveTemp"
                                  :openNew="openNew"
                                  :allEntitiesDataFromParent="allEntitiesData"
                                  :allFormTemplatesFromParent="allFormTemplates"
                                  :allCompanyUserTypesDataFromParent="
                                    allCompanyUserTypesData
                                  "
                                  @goToNextTab="goToNextTab"
                                  @closeParent="backToEntityInfoList"
                                  :getSuggestionFormData="getSuggestionFormData"
                                ></fields-preview-template>
                              </el-row>
                              <el-row
                                v-else-if="
                                  tab.tabs[0].feature_type == 'RELATIONSHIP' &&
                                  showData
                                "
                              >
                                <el-card class="relationship-card spacing">
                                  <EntityReationshipDataTable
                                    :isSplitView="true"
                                    :showFields="showFields"
                                    :relationshipDetails="
                                      getCurrentRelation(tab.tabs[0].feature_id)
                                    "
                                    :nextRealationshipDetails="null"
                                    :hideLabel="true"
                                    :currentEntityData="currentEntityData"
                                    :currentTabInfo="
                                      getTabInfo(tab.tabs[0].feature_id)
                                    "
                                    :customization="customization"
                                  />
                                </el-card>
                              </el-row>
                              <el-row
                                v-else-if="
                                  tab.tabs[0].feature_type ==
                                    'NESTED_RELATIONSHIP' && showData
                                "
                              >
                                <el-card class="relationship-card spacing">
                                  <EntityNestedReationshipDataTable
                                    :relationshipDetails="
                                      getCurrentNestedRelaiton(
                                        tab.tabs[0].feature_id
                                      )
                                    "
                                    :nextRealationshipDetails="null"
                                    :hideLabel="true"
                                    :currentTabInfo="
                                      getTabInfo(tab.tabs[0].feature_id)
                                    "
                                    :customization="customization"
                                  />
                                </el-card>
                              </el-row>
                              <el-row
                                v-else-if="tab.tabs[0].feature_type == 'OTHERS'"
                              >
                                <div v-if="tab.tabs[0].feature_id == 'Files'">
                                  <el-card class="relationship-card spacing">
                                    <entity-data-documents
                                      v-if="!refresh"
                                      :entityDataId="entityDataId"
                                      :entityId="entity_id"
                                      :folder="folder"
                                      :isApplicationUserSide="
                                        isApplicationUserSide
                                      "
                                      :getEntityPermission="getEntityPermission"
                                      :primaryLabel="getPrimaryLabel"
                                      @moveToFolder="moveToFolder"
                                    ></entity-data-documents>
                                  </el-card>
                                </div>
                                <div
                                  v-else-if="
                                    tab.tabs[0].feature_id == 'eSignatures'
                                  "
                                >
                                  <entity-documents
                                    :entityId="entity_id"
                                    :entityDataId="entityDataId"
                                    :isDocument="true"
                                    :customization="customization"
                                  ></entity-documents>
                                </div>
                                <div
                                  v-else-if="
                                    tab.tabs[0].feature_id == 'Contacts'
                                  "
                                >
                                  <entity-contacts
                                    :contactType="entity_id"
                                    :accountName="entityDataId"
                                    :entityDataId="currentEntity"
                                    :hideLabel="true"
                                  ></entity-contacts>
                                </div>
                                <div
                                  v-else-if="tab.tabs[0].feature_id == 'Emails'"
                                >
                                  <EntityEmails />
                                </div>
                              </el-row>
                            </div>
                            <template v-else-if="tab._id === 'kf_email'">
                              <span slot="label"><i class="el-icon-message"></i> {{tab.label}}</span>
                              <SplitEmails v-if="showFields.email && showFields.emailField" :email="formattedEmail" />
                              <ErrorMessage v-else/>
                            </template>
                            <template v-else-if="tab._id === 'call'">
                              <span slot="label"><i class="el-icon-phone"></i> {{tab.label}}</span>
                              <CommingSoon  v-if="showFields.call && showFields.phoneField"  />
                              <ErrorMessage v-else />
                            </template>
                            <div v-else>
                              <el-tabs
                                v-model="currentSubTab"
                                @tab-click="changeTab('sub')"
                                :before-leave="
                                  (newTab, oldTab) =>
                                    handleBeforeLeave('sub', newTab, oldTab)
                                "
                              >
                                <el-tab-pane
                                  v-for="(sub_tab, sub_index) in tab.tabs"
                                  :key="sub_index"
                                  :name="sub_tab.feature_id + '#' + sub_index"
                                  :label="sub_tab.label"
                                ></el-tab-pane>
                              </el-tabs>
                              <div
                                style="width: 100% !important"
                                class="filter-card"
                              >
                                <el-row
                                  v-if="
                                    currentSubTabFeatureType == 'TEMPLATE' &&
                                    showData
                                  "
                                >
                                  <fields-preview-template
                                    :templateData="
                                      getCurrentStep(
                                        currentSubTab.split('#')[0]
                                      ).templateInfo
                                    "
                                    :has-next="false"
                                    :entityDataId="entityDataId"
                                    :workflow-step-id="
                                      getCurrentStep(
                                        currentSubTab.split('#')[0]
                                      )._id
                                    "
                                    :template-data-id="
                                      getCurrentStep(
                                        currentSubTab.split('#')[0]
                                      ).templateDataId
                                    "
                                    :currentStep="
                                      getCurrentStep(
                                        currentSubTab.split('#')[0]
                                      )
                                    "
                                    :is-execute="isEdit"
                                    :layout="customization.active_layout"
                                    :currentEntity="currentEntity"
                                    :entitySettings="entitySettings"
                                    :isEdit="isEdit"
                                    :entityData="entityData"
                                    :currentMenu="currentMenu()"
                                    :entityDataExists="entityDataExists"
                                    :profilePicture="getUserprofilepicture"
                                    :customization="customization"
                                    :saveTemp="saveTemp"
                                    :openNew="openNew"
                                    :allEntitiesDataFromParent="allEntitiesData"
                                    :allFormTemplatesFromParent="
                                      allFormTemplates
                                    "
                                    :allCompanyUserTypesDataFromParent="
                                      allCompanyUserTypesData
                                    "
                                    @goToNextTab="goToNextTab"
                                    @closeParent="backToEntityInfoList"
                                    :getSuggestionFormData="
                                      getSuggestionFormData
                                    "
                                    @formDataUpdated="handleFormUpdate"
                                    @validationFailed="checkingIsValid"
                                    :formDataInTabsChange="formDataToSend"
                                    @templateUpdated="templateUpdated"
                                    v-on:getDataFromTemplatesPreview="
                                      getDataFromPreviewTemplate
                                    "
                                    :saveAndSwitch="saveAndSwitch"
                                    :isTabClickedWithoutSaving="
                                      isTabClickedWithoutSaving
                                    "
                                    :previousSavingTab="previousSavingTab"
                                  ></fields-preview-template>
                                </el-row>
                                <el-row
                                  v-else-if="
                                    currentSubTabFeatureType ==
                                      'RELATIONSHIP' && showData
                                  "
                                >
                                  <EntityReationshipDataTable
                                    :relationshipDetails="
                                      getCurrentRelation(
                                        currentSubTab.split('#')[0]
                                      )
                                    "
                                    :nextRealationshipDetails="null"
                                    :hideLabel="true"
                                    :currentEntityData="currentEntityData"
                                    :currentTabInfo="
                                      getTabInfo(currentSubTab.split('#')[0])
                                    "
                                    :customization="customization"
                                  />
                                </el-row>
                                <el-row
                                  v-else-if="
                                    currentSubTabFeatureType ==
                                      'NESTED_RELATIONSHIP' && showData
                                  "
                                >
                                  <EntityNestedReationshipDataTable
                                    :relationshipDetails="
                                      getCurrentNestedRelaiton(
                                        currentSubTab.split('#')[0]
                                      )
                                    "
                                    :nextRealationshipDetails="null"
                                    :hideLabel="true"
                                    :customization="customization"
                                  />
                                </el-row>
                                <el-row
                                  v-else-if="
                                    currentSubTabFeatureType == 'OTHERS' &&
                                    currentSubTab.split('#')[0] == 'Files'
                                  "
                                >
                                  <entity-data-documents
                                    v-if="!refresh"
                                    :entityDataId="entityDataId"
                                    :entityId="entity_id"
                                    :folder="folder"
                                    :isApplicationUserSide="
                                      isApplicationUserSide
                                    "
                                    :getEntityPermission="getEntityPermission"
                                    :primaryLabel="getPrimaryLabel"
                                    @moveToFolder="moveToFolder"
                                  ></entity-data-documents>
                                </el-row>
                                <el-row
                                  v-else-if="
                                    currentSubTabFeatureType == 'OTHERS' &&
                                    currentSubTab.split('#')[0] == 'eSignatures'
                                  "
                                >
                                  <entity-documents
                                    :entityId="entity_id"
                                    :entityDataId="entityDataId"
                                    :isDocument="true"
                                    :customization="customization"
                                  ></entity-documents>
                                </el-row>
                                <el-row
                                  v-else-if="
                                    currentSubTabFeatureType == 'OTHERS' &&
                                    currentSubTab.split('#')[0] == 'Contacts'
                                  "
                                >
                                  <entity-contacts
                                    :contactType="entity_id"
                                    :accountName="entityDataId"
                                    :entityDataId="currentEntity"
                                    :hideLabel="true"
                                  ></entity-contacts>
                                </el-row>
                                <el-row
                                  v-else-if="
                                    currentSubTabFeatureType == 'OTHERS' &&
                                    currentSubTab.split('#')[0] == 'Emails'
                                  "
                                >
                                  <EntityEmails />
                                </el-row>
                              </div>
                            </div>
                          </el-tab-pane>
                        </el-tabs>
                      </div>
                    </template>
                    <template v-else>
                      <div class="text item mt-0">There are no activities.</div>
                    </template>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
              <el-col
                :span="7"
                v-if="showFields.split_col1 && showFields.split_col1.length"
                style="margin-top: 0px; min-height: 120vh"
                class="profile-panel-height split-view-col"
              >
                <el-card style="overflow-y: auto; scrollbar-width: thin;">
                  <div v-if="!customization">
                    <el-collapse class="data-card-collapse" style="word-wrap: break-word;">
                      <!-- Relationships -->
                      <el-collapse-item
                        v-for="(relationship, index) in filteredRelationships"
                        :key="'relationship' + index"
                        :title="relationship.relationship_title"
                        :name="'relationship' + index"
                      >
                        <EntityReationshipDataTable
                          :isSplitView="true"
                          :showFields="showFields"
                          :split_col3="true"
                          :entity_id="entity_id"
                          :allEntitiesData="currentEntity"
                          :relationshipDetails="relationship"
                          :nextRealationshipDetails="hasNextRelationshipDetails"
                          :hideLabel="true"
                          :currentEntityData="currentEntityData"
                          :customization="customization"
                        />
                      </el-collapse-item>

                      <!-- Nested Relationships -->
                      <el-collapse-item
                        v-for="(
                          nestedRelationship, index
                        ) in filteredNestedRelationships"
                        :key="'nestedRelationship' + index"
                        :title="nestedRelationship.relationship_title"
                        :name="'nestedRelationship' + index"
                      >
                        <EntityNestedReationshipDataTable
                          :relationshipDetails="nestedRelationship"
                          :nextRealationshipDetails="null"
                          :hideLabel="true"
                          :customization="customization"
                        />
                      </el-collapse-item>

                      <!-- Static Sections -->
                      <el-collapse-item
                        v-if="showFields.split_col1.includes('files')"
                        title="Files"
                        name="files"
                      >
                        <entity-data-documents
                          v-if="!refresh"
                          :entityDataId="entityDataId"
                          :entityId="entity_id"
                          :folder="folder"
                          :isApplicationUserSide="isApplicationUserSide"
                          :getEntityPermission="getEntityPermission"
                          :primaryLabel="getPrimaryLabel"
                          @moveToFolder="moveToFolder"
                        />
                      </el-collapse-item>

                      <el-collapse-item
                        v-if="showFields.split_col1.includes('esignature')"
                        title="eSignatures"
                        name="esignatures"
                      >
                        <entity-documents
                          :entityId="entity_id"
                          :entityDataId="entityDataId"
                          :isDocument="true"
                        />
                      </el-collapse-item>

                      <el-collapse-item
                        v-if="showFields.split_col1.includes('emails')"
                        title="Notifications"
                        name="emails"
                      >
                        <EntityEmails />
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                  <div v-else>
                    <el-collapse class="data-card-collapse">
                      <el-collapse-item
                        v-for="(tab, index) in filteredTabGroups"
                        :key="'tab_' + index"
                        :name="'tab_' + index"
                      >
                        <template #title>{{ tab.label }}</template>

                        <div v-if="tab.tabs.length == 1">
                          <el-row
                            v-if="
                              tab.tabs[0].feature_type === 'TEMPLATE' &&
                              showData
                            "
                          >
                            <fields-preview-template
                              :templateData="
                                getCurrentStep(tab.tabs[0].feature_id)
                                  .templateInfo
                              "
                              :has-next="false"
                              :entityDataId="entityDataId"
                              :workflow-step-id="
                                getCurrentStep(tab.tabs[0].feature_id)._id
                              "
                              :template-data-id="
                                getCurrentStep(tab.tabs[0].feature_id)
                                  .templateDataId
                              "
                              :currentStep="
                                getCurrentStep(tab.tabs[0].feature_id)
                              "
                              :is-execute="isEdit"
                              :layout="customization.active_layout"
                              :currentEntity="currentEntity"
                              :entitySettings="entitySettings"
                              :isEdit="isEdit"
                              :entityData="entityData"
                              :currentMenu="currentMenu()"
                              :entityDataExists="entityDataExists"
                              :profilePicture="getUserprofilepicture"
                              :customization="customization"
                              :saveTemp="saveTemp"
                              :openNew="openNew"
                              :allEntitiesDataFromParent="allEntitiesData"
                              :allFormTemplatesFromParent="allFormTemplates"
                              :allCompanyUserTypesDataFromParent="
                                allCompanyUserTypesData
                              "
                              @goToNextTab="goToNextTab"
                              @closeParent="backToEntityInfoList"
                              :getSuggestionFormData="getSuggestionFormData"
                            ></fields-preview-template>
                          </el-row>

                          <!-- Relationship Feature -->
                          <el-row
                            v-else-if="
                              tab.tabs[0].feature_type === 'RELATIONSHIP' &&
                              showData
                            "
                          >
                            <EntityReationshipDataTable
                              :isSplitView="true"
                              :showFields="showFields"
                              :split_col3="true"
                              :entity_id="entity_id"
                              :allEntitiesData="currentEntity"
                              :relationshipDetails="
                                getCurrentRelation(tab.tabs[0].feature_id)
                              "
                              :nextRealationshipDetails="null"
                              :hideLabel="true"
                              :currentEntityData="currentEntityData"
                              :currentTabInfo="
                                getTabInfo(tab.tabs[0].feature_id)
                              "
                              :customization="customization"
                            />
                          </el-row>

                          <!-- Other Features -->
                          <el-row
                            v-else-if="tab.tabs[0].feature_type === 'OTHERS'"
                          >
                            <div v-if="tab.tabs[0].feature_id === 'Files'">
                              <entity-data-documents
                                v-if="!refresh"
                                :entityDataId="entityDataId"
                                :entityId="entity_id"
                                :folder="folder"
                                :isApplicationUserSide="isApplicationUserSide"
                                :getEntityPermission="getEntityPermission"
                                :primaryLabel="getPrimaryLabel"
                                @moveToFolder="moveToFolder"
                              ></entity-data-documents>
                            </div>
                            <div
                              v-else-if="
                                tab.tabs[0].feature_id === 'eSignatures'
                              "
                            >
                              <entity-documents
                                :entityId="entity_id"
                                :entityDataId="entityDataId"
                                :isDocument="true"
                                :customization="customization"
                              ></entity-documents>
                            </div>
                            <div
                              v-else-if="tab.tabs[0].feature_id === 'Contacts'"
                            >
                              <entity-contacts
                                :contactType="entity_id"
                                :accountName="entityDataId"
                                :entityDataId="currentEntity"
                                :hideLabel="true"
                              ></entity-contacts>
                            </div>
                            <div
                              v-else-if="tab.tabs[0].feature_id === 'Emails'"
                            >
                              <EntityEmails />
                            </div>
                          </el-row>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else class="default-view-container">
          <div class="default-profile" v-if="checkIsOneFieldselected">
            <div class="left-card" v-if="showFields['profile'] && !showFields?.vertical_view">
              <div
                class="profile-holder"
                @click="checkAndUpdateProfile"
                v-if="!upLoadEffect"
              >
                <img
                  v-if="getFieldValue('profile')"
                  :src="getFieldValue('profile')"
                />
                <div v-else>
                  <!-- <i class="el-icon-picture-outline"></i> -->
                  <img
                    v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                    src="@/assets/img/defaultAvatar/Individual.svg"
                    alt="icon"
                    style="border-radius: 50%"
                  />
                  <img
                    v-else
                    src="@/assets/img/defaultAvatar/Business.svg"
                    alt="icon"
                  />
                </div>
              </div>
            </div>

            <div :class="['right-card']">
              <div
                :class="[
                  'profile-details',
                  { 'vertical-view': showFields.vertical_view },
                ]"
              >
                <div class="d-flex mx-2">
                  <div
                    v-if="!upLoadEffect && showFields.vertical_view"
                    @click="checkAndUpdateProfile"
                  >
                    <img
                      v-if="getFieldValue('profile')"
                      :src="getFieldValue('profile')"
                      style="border-radius: 50%"
                    />
                    <img
                      v-else
                      src="@/assets/img/defaultAvatar/Individual.svg"
                      alt="icon"
                      style="border-radius: 50%"
                    />
                  </div>

                  <div v-else>
                    <div v-if="showFields.vertical_view">
                      <img
                        v-if="
                          getFieldValue('profile') && showFields.vertical_view
                        "
                        :src="getFieldValue('profile')"
                        alt="icon"
                        style="border-radius: 50%"
                      />
                      <img
                        v-else
                        src="@/assets/img/defaultAvatar/Business.svg"
                        alt="icon"
                        height="20"
                        width="20"
                      />
                    </div>
                  </div>
                  <div class="mx-2">
                    <p
                      class="font-weight-bold text-dark"
                      v-if="getFieldValue('title')"
                    >
                      {{ getFieldValue("title") | truncate(24, "...") }}
                    </p>
                    <p
                      class="card-descripion mt-1"
                      v-if="getFieldValue('description')"
                    >
                      {{ getFieldValue("description") | truncate(140, "...") }}
                    </p>
                  </div>
                  <div class="default-card-fields">
                    <div
                      v-for="(field, i) in showFields.fields.filter(
                        (e) => e.inputType != 'SIGNATURE'
                      )"
                      :key="field.key + '_show_fields_7' + i"
                      class="mt-1"
                    >
                      <!-- <div v-if="i < 6">
                    <span
                      class="field-label"
                      v-if="field && field.template_id && field.key"
                      >{{ field.label }}</span
                    >
                    <p
                      class="field-value"
                      v-if="getFieldValue('fields', field)"
                    >
                      {{ getFieldValue("fields", field) | truncate(50, "...") }}
                    </p>
                    </div>-->
                      <div v-if="i < 6" class="d-flex mt-4">
                        <div class="vr mx-2"></div>
                        <div
                          class="field-label"
                          v-if="field && field.template_id && field.key"
                        >
                          {{ field.label }}:
                        </div>

                        <div
                          v-if="
                            field &&
                            field.input_type == 'DATE' &&
                            getFieldValue('fields', field)
                          "
                        >
                          <p class="field-value">
                            {{
                              getFieldValue("fields", field) | globalDateFormat
                            }}
                          </p>
                        </div>
                        <div v-else-if="
                            field &&
                            field.input_type == 'PHONE_COUNTRY_CODE' &&
                            getFieldValue('fields', field)
                          ">
                           <p class="field-value">
                            {{
                              getFieldValue("fields", field)
                            }}
                          </p></div>
                        <div
                          v-else-if="
                            field &&
                            field.input_type == 'DATE_TIME' &&
                            getFieldValue('fields', field)
                          "
                        >
                          <p class="field-value">
                            {{
                              getFieldValue("fields", field)
                                | moment("MM-DD-YYYY hh:mm:ss A")
                            }}
                          </p>
                        </div>

                        <div
                          v-else-if="
                            field &&
                            field.input_type == 'DATE_TIME_RANGE' &&
                            getFieldValue('fields', field)
                          "
                        >
                          <p class="field-value">
                            {{
                              getFieldValue("fields", field) | toDateTimeRange
                            }}
                            <!-- | toDateTimeRange -->
                          </p>
                        </div>
                        <div
                          v-else-if="
                            field &&
                            field.input_type == 'TIME_RANGE' &&
                            getFieldValue('fields', field)
                          "
                        >
                          <p class="field-value">
                            {{ getFieldValue("fields", field) | toTimeRange }}
                          </p>
                        </div>
                        <div
                          v-else-if="
                            field &&
                            field.input_type == 'WEEKDAYS' &&
                            getFieldValue('fields', field)
                          "
                        >
                          <p class="field-value">
                            {{ getFieldValue("fields", field) | toWeekDays }}
                          </p>
                        </div>
                        <div
                          v-else-if="
                            field &&
                            field.input_type == 'DATE_RANGE' &&
                            getFieldValue('fields', field)
                          "
                        >
                          <p class="field-value">
                            <!-- {{getFieldValue(scope.row, field)}}<br /> -->
                            {{ getFieldValue("fields", field) | toDateRange }}
                            <!-- | toDateRange -->
                          </p>
                        </div>
                        <div v-else>
                          <p
                            class="field-value"
                            v-if="
                              getFieldValue('fields', field) &&
                              getFieldValue('fields', field)
                            "
                          >
                            {{
                              getFieldValue("fields", field)
                                | truncate(30, "...")
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <div
              v-if="
                customization == null ||
                (customization &&
                  customization.tab_groups &&
                  customization.tab_groups.length == 0)
              "
            >
              <el-collapse
                v-model="currrentTab"
                class="data-card-collapse"
                v-if="showFields.vertical_view"
              >
                <el-collapse-item name="entities_data" :title="'Details'">
                  <el-row
                    v-if="showTemplate && currrentTab == 'entities_data'"
                    class="standard-view-card"
                  >
                    <entity-template-view
                      :currentEntity="currentEntity"
                      :entityData="entityData"
                      :isEdit="isEdit"
                      :layout="'STANDARD'"
                      :entityDataExists="entityDataExists"
                      :profilePicture="getUserprofilepicture"
                      :allEntitiesDataFromParent="allEntitiesData"
                      :allFormTemplatesFromParent="allFormTemplates"
                      :allCompanyUserTypesDataFromParent="
                        allCompanyUserTypesData
                      "
                      v-on:refresh-page="refreshThePage"
                      @closeParent="backToEntityInfoList"
                      :getSuggestionFormData="getSuggestionFormData"
                    ></entity-template-view>
                  </el-row>
                </el-collapse-item>

                <template>
                  <el-collapse-item
                    v-for="(relationship, index) in relationshipsData"
                    :key="'relationships' + index"
                    :name="relationship.relationship_id"
                    :title="relationship.relationship_title"
                  >
                  </el-collapse-item>

                  <template v-if="showNestedRelaships">
                    <el-collapse-item
                      v-for="(relationship, index) in nestedRelationshipsData"
                      :key="'nestedRelationshipsData' + index"
                      :name="relationship._id"
                      :title="relationship.relationship_title"
                    >
                    </el-collapse-item>
                  </template>
                </template>

                <el-collapse-item name="files" :title="'Files'">
                  <el-row v-if="currrentTab == 'files'">
                    <entity-data-documents
                      v-if="!refresh"
                      :entityDataId="entityDataId"
                      :entityId="entity_id"
                      :folder="folder"
                      :isApplicationUserSide="isApplicationUserSide"
                      :getEntityPermission="getEntityPermission"
                      :primaryLabel="getPrimaryLabel"
                      @moveToFolder="moveToFolder"
                    ></entity-data-documents>
                  </el-row>
                </el-collapse-item>

                <el-collapse-item
                  v-if="!isApplicationUserSide"
                  name="esignature"
                  :title="'eSignatures'"
                >
                  <el-row v-if="currrentTab == 'esignature'">
                    <entity-documents
                      :entityId="entity_id"
                      :entityDataId="entityDataId"
                      :isDocument="true"
                    ></entity-documents>
                  </el-row>
                </el-collapse-item>

                <el-collapse-item name="emails" :title="'Notifications'">
                  <el-row v-if="currrentTab == 'emails'">
                    <EntityEmails />
                  </el-row>
                </el-collapse-item>

                <el-collapse-item
                  v-if="isCurrentSlugIsRelationship && showData"
                  :name="'relationships'"
                  :title="'Relationships'"
                >
                  <EntityReationshipDataTable
                    :relationshipDetails="getCurrentRelationshipDetails"
                    :nextRealationshipDetails="hasNextRelationshipDetails"
                    :hideLabel="true"
                    :currentEntityData="currentEntityData"
                    :customization="customization"
                  />
                </el-collapse-item>

                <el-collapse-item
                  v-if="isCurrentSlugIsNestedRelationship && showData"
                  :name="'nested_relationships'"
                  :title="'Nested Relationships'"
                >
                  <EntityNestedReationshipDataTable
                    :relationshipDetails="getCurrentNestedRelationshipDetails"
                    :nextRealationshipDetails="null"
                    :hideLabel="true"
                    :customization="customization"
                  />
                </el-collapse-item>
              </el-collapse>
              <div v-else>
                <el-tabs
                  @tab-click="changeTab('tab')"
                  v-model="currrentTab"
                  type="card"
                  class="data-card-tabs"
                >
                  <el-tab-pane
                    label="Details"
                    name="entities_data"
                  ></el-tab-pane>
                  <template>
                    <el-tab-pane
                      v-for="(reationship, index) of relationshipsData"
                      :key="'reationships' + index"
                      :label="reationship.relationship_title"
                      :name="reationship.relationship_id"
                    ></el-tab-pane>
                    <template v-if="showNestedRelaships">
                      <el-tab-pane
                        v-for="(reationship, index) of nestedRelationshipsData"
                        :key="'nestedRelationshipsData' + index"
                        :label="reationship.relationship_title"
                        :name="reationship._id"
                      ></el-tab-pane>
                    </template>
                  </template>
                  <el-tab-pane label="Files" name="files"></el-tab-pane>
                  <el-tab-pane
                    label="eSignatures"
                    name="esignature"
                    v-if="!isApplicationUserSide"
                  ></el-tab-pane>
                  <el-tab-pane
                    label="Notifications"
                    name="emails"
                  ></el-tab-pane>
                </el-tabs>
                <div style="width: 99% !important">
                  <el-row
                    v-if="showTemplate && currrentTab == 'entities_data'"
                    class="standard-view-card"
                  >
                    <entity-template-view
                      v-if="currrentTab == 'entities_data'"
                      :currentEntity="currentEntity"
                      :entityData="entityData"
                      :isEdit="isEdit"
                      :layout="'STANDARD'"
                      :entityDataExists="entityDataExists"
                      :profilePicture="getUserprofilepicture"
                      :allEntitiesDataFromParent="allEntitiesData"
                      :allFormTemplatesFromParent="allFormTemplates"
                      :allCompanyUserTypesDataFromParent="
                        allCompanyUserTypesData
                      "
                      v-on:refresh-page="refreshThePage"
                      @closeParent="backToEntityInfoList"
                      :getSuggestionFormData="getSuggestionFormData"
                    ></entity-template-view>
                  </el-row>
                  <el-row v-else-if="currrentTab == 'esignature'">
                    <entity-documents
                      :entityId="entity_id"
                      :entityDataId="entityDataId"
                      :isDocument="true"
                    ></entity-documents>
                  </el-row>
                  <el-row v-else-if="currrentTab == 'files'">
                    <entity-data-documents
                      v-if="!refresh"
                      :entityDataId="entityDataId"
                      :entityId="entity_id"
                      :folder="folder"
                      :isApplicationUserSide="isApplicationUserSide"
                      :getEntityPermission="getEntityPermission"
                      :primaryLabel="getPrimaryLabel"
                      @moveToFolder="moveToFolder"
                    ></entity-data-documents>
                  </el-row>
                  <el-row v-else-if="currrentTab == 'emails'">
                    <EntityEmails />
                  </el-row>
                  <el-row v-else-if="isCurrentSlugIsRelationship && showData">
                    <EntityReationshipDataTable
                      :relationshipDetails="getCurrentRelationshipDetails"
                      :nextRealationshipDetails="hasNextRelationshipDetails"
                      :hideLabel="true"
                      :currentEntityData="currentEntityData"
                      :customization="customization"
                    />
                  </el-row>

                  <el-row
                    v-else-if="isCurrentSlugIsNestedRelationship && showData"
                  >
                    <EntityNestedReationshipDataTable
                      :relationshipDetails="getCurrentNestedRelationshipDetails"
                      :nextRealationshipDetails="null"
                      :hideLabel="true"
                      :customization="customization"
                    />
                  </el-row>
                </div>
              </div>
            </div>
            <div v-else>
              <el-collapse
                v-model="activeMainCollapse"
                v-if="showFields.vertical_view"
                class="custom-collapse"
              >
                <el-collapse-item
                  v-for="(tab, index) in customization.tab_groups"
                  :key="index"
                  :name="tab.label"
                  class="custom-collapse-item"
                >
                  <template #title class="custom-title" style="">
                          <span class="mx-2"
                            ><icons
                             :iconName="tab.icon ? tab.icon : 'block-portfolio.svg'"
                            ></icons
                          ></span>
                         {{ tab.label }}
                      
                  </template>
                  <!-- For Single Tab Inside the Group -->
                  <div v-if="tab.tabs.length === 1" class="m-2">
                    <div
                      v-if="tab.tabs[0].feature_type === 'TEMPLATE' && showData"
                    >
                      <fields-preview-template
                        :isVerticalView="showFields.vertical_view"
                        :isStandardView="standard_vertical_view"
                        :templateData="
                          getCurrentStep(tab.tabs[0].feature_id).templateInfo
                        "
                        :has-next="false"
                        :entityDataId="entityDataId"
                        :workflow-step-id="
                          getCurrentStep(tab.tabs[0].feature_id)._id
                        "
                        :template-data-id="
                          getCurrentStep(tab.tabs[0].feature_id).templateDataId
                        "
                        :currentStep="getCurrentStep(tab.tabs[0].feature_id)"
                        :is-execute="isEdit"
                        :layout="customization.active_layout"
                        :currentEntity="currentEntity"
                        :entitySettings="entitySettings"
                        :isEdit="isEdit"
                        :entityData="entityData"
                        :currentMenu="currentMenu()"
                        :entityDataExists="entityDataExists"
                        :profilePicture="getUserprofilepicture"
                        :customization="customization"
                        :saveTemp="saveTemp"
                        :openNew="openNew"
                        :allEntitiesDataFromParent="allEntitiesData"
                        :allFormTemplatesFromParent="allFormTemplates"
                        :allCompanyUserTypesDataFromParent="
                          allCompanyUserTypesData
                        "
                        @goToNextTab="goToNextTab"
                        @closeParent="backToEntityInfoList"
                        :getSuggestionFormData="getSuggestionFormData"
                      />
                    </div>
                    <div
                      v-else-if="
                        tab.tabs[0].feature_type === 'RELATIONSHIP' && showData
                      "
                    >
                      <EntityReationshipDataTable
                        :isVerticalView="showFields.vertical_view"
                        :relationshipDetails="
                          getCurrentRelation(tab.tabs[0].feature_id)
                        "
                        :nextRealationshipDetails="null"
                        :hideLabel="true"
                        :currentEntityData="currentEntityData"
                        :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                        :customization="customization"
                      />
                    </div>
                    <div
                      v-else-if="
                        tab.tabs[0].feature_type === 'NESTED_RELATIONSHIP' &&
                        showData
                      "
                    >
                      <EntityNestedReationshipDataTable
                        :relationshipDetails="
                          getCurrentNestedRelaiton(tab.tabs[0].feature_id)
                        "
                        :nextRealationshipDetails="null"
                        :hideLabel="true"
                        :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                        :customization="customization"
                      />
                    </div>
                    <div v-else-if="tab.tabs[0].feature_type === 'OTHERS'">
                      <div v-if="tab.tabs[0].feature_id === 'Files'">
                        <entity-data-documents
                          v-if="!refresh"
                          :entityDataId="entityDataId"
                          :entityId="entity_id"
                          :folder="folder"
                          :isApplicationUserSide="isApplicationUserSide"
                          :getEntityPermission="getEntityPermission"
                          :primaryLabel="getPrimaryLabel"
                          @moveToFolder="moveToFolder"
                        />
                      </div>
                      <div v-else-if="tab.tabs[0].feature_id === 'eSignatures'">
                        <entity-documents
                          :entityId="entity_id"
                          :entityDataId="entityDataId"
                          :isDocument="true"
                          :customization="customization"
                        />
                      </div>

                      <div v-else-if="tab.tabs[0].feature_id === 'Contacts'">
                        <entity-contacts
                          :contactType="entity_id"
                          :accountName="entityDataId"
                          :entityDataId="currentEntity"
                          :hideLabel="true"
                        />
                      </div>
                      <div v-else-if="tab.tabs[0].feature_id === 'Emails'">
                        <EntityEmails />
                      </div>
                    </div>
                  </div>

                  <!-- For Multiple Tabs Inside the Group -->
                  <div v-else>
                    <el-collapse
                      v-model="activeSubCollapse"
                      class="custom-sub-collapse"
                    >
                      <el-collapse-item
                        v-for="(sub_tab, sub_index) in tab.tabs"
                        :key="sub_index"
                        :name="sub_tab.feature_id + '#' + sub_index"
                        class="custom-collapse-sub-item"
                      >
                        <template #title class="custom-title" style="">
                          <span class="mx-2"
                            ><icons
                              :iconName="
                                getCurrentStep(sub_tab.feature_id).templateInfo
                                  ?.icon
                              "
                            ></icons
                          ></span>
                          {{ sub_tab.label }}
                        </template>
                        <!-- <el-button
                          type="text"
                          :icon="getCurrentStep(sub_tab.feature_id).templateInfo.icon"
                         
                        ></el-button> -->
                        <div
                          v-if="sub_tab.feature_type === 'TEMPLATE' && showData"
                          class="tab-content-template m-2"
                        >
                          <fields-preview-template
                            :isVerticalView="showFields.vertical_view"
                            :isStandardView="standard_vertical_view"
                            :templateData="
                              getCurrentStep(sub_tab.feature_id).templateInfo
                            "
                            :has-next="false"
                            :entityDataId="entityDataId"
                            :workflow-step-id="
                              getCurrentStep(sub_tab.feature_id)._id
                            "
                            :template-data-id="
                              getCurrentStep(sub_tab.feature_id).templateDataId
                            "
                            :currentStep="getCurrentStep(sub_tab.feature_id)"
                            :is-execute="isEdit"
                            :layout="customization.active_layout"
                            :currentEntity="currentEntity"
                            :entitySettings="entitySettings"
                            :isEdit="isEdit"
                            :entityData="entityData"
                            :currentMenu="currentMenu()"
                            :entityDataExists="entityDataExists"
                            :profilePicture="getUserprofilepicture"
                            :customization="customization"
                            :saveTemp="saveTemp"
                            :openNew="openNew"
                            :allEntitiesDataFromParent="allEntitiesData"
                            :allFormTemplatesFromParent="allFormTemplates"
                            :allCompanyUserTypesDataFromParent="
                              allCompanyUserTypesData
                            "
                            @goToNextTab="goToNextTab"
                            @closeParent="backToEntityInfoList"
                            :getSuggestionFormData="getSuggestionFormData"
                          />
                        </div>
                        <div
                          v-else-if="
                            sub_tab.feature_type === 'RELATIONSHIP' && showData
                          "
                          class="m-2"
                        >
                          <EntityReationshipDataTable
                            :relationshipDetails="
                              getCurrentRelation(sub_tab.feature_id)
                            "
                            :nextRealationshipDetails="null"
                            :hideLabel="true"
                            :currentEntityData="currentEntityData"
                            :currentTabInfo="getTabInfo(sub_tab.feature_id)"
                            :customization="customization"
                          />
                        </div>
                        <div
                          v-else-if="
                            sub_tab.feature_type === 'NESTED_RELATIONSHIP' &&
                            showData
                          "
                          class="m-2"
                        >
                          <EntityNestedReationshipDataTable
                            :relationshipDetails="
                              getCurrentNestedRelaiton(sub_tab.feature_id)
                            "
                            :nextRealationshipDetails="null"
                            :hideLabel="true"
                            :currentTabInfo="getTabInfo(sub_tab.feature_id)"
                            :customization="customization"
                          />
                        </div>
                        <div
                          v-else-if="
                            sub_tab.feature_type === 'OTHERS' &&
                            sub_tab.feature_id === 'Files'
                          "
                          class="m-2"
                        >
                          <entity-data-documents
                            v-if="!refresh"
                            :entityDataId="entityDataId"
                            :entityId="entity_id"
                            :folder="folder"
                            :isApplicationUserSide="isApplicationUserSide"
                            :getEntityPermission="getEntityPermission"
                            :primaryLabel="getPrimaryLabel"
                            @moveToFolder="moveToFolder"
                          />
                        </div>
                        <div
                          v-else-if="
                            sub_tab.feature_type === 'OTHERS' &&
                            sub_tab.feature_id === 'eSignatures'
                          "
                          class="m-2"
                        >
                          <entity-documents
                            :entityId="entity_id"
                            :entityDataId="entityDataId"
                            :isDocument="true"
                            :customization="customization"
                          />
                        </div>
                        <div
                          v-else-if="
                            sub_tab.feature_type === 'OTHERS' &&
                            sub_tab.feature_id === 'Contacts'
                          "
                          class="m-2"
                        >
                          <entity-contacts
                            :contactType="entity_id"
                            :accountName="entityDataId"
                            :entityDataId="currentEntity"
                            :hideLabel="true"
                          />
                        </div>
                        <div
                          v-else-if="
                            sub_tab.feature_type === 'OTHERS' &&
                            sub_tab.feature_id === 'Emails'
                          "
                          class="m-2"
                        >
                          <EntityEmails />
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-collapse-item>
              </el-collapse>
              <el-tabs
                v-else
                v-model="currrentTab"
                type="card"
                class="data-card-tabs"
                @tab-click="changeTab('tab')"
              >
                <el-tab-pane
                  v-for="(tab, index) in customization.tab_groups"
                  :key="index"
                  :name="tab.label"
                  :label="tab.label"
                >
                  <div v-if="tab.tabs.length == 1">
                    <el-row
                      v-if="tab.tabs[0].feature_type == 'TEMPLATE' && showData"
                    >
                      <fields-preview-template
                        :templateData="
                          getCurrentStep(tab.tabs[0].feature_id).templateInfo
                        "
                        :has-next="false"
                        :entityDataId="entityDataId"
                        :workflow-step-id="
                          getCurrentStep(tab.tabs[0].feature_id)._id
                        "
                        :template-data-id="
                          getCurrentStep(tab.tabs[0].feature_id).templateDataId
                        "
                        :currentStep="getCurrentStep(tab.tabs[0].feature_id)"
                        :is-execute="isEdit"
                        :layout="customization.active_layout"
                        :currentEntity="currentEntity"
                        :entitySettings="entitySettings"
                        :isEdit="isEdit"
                        :entityData="entityData"
                        :currentMenu="currentMenu()"
                        :entityDataExists="entityDataExists"
                        :profilePicture="getUserprofilepicture"
                        :customization="customization"
                        :saveTemp="saveTemp"
                        :openNew="openNew"
                        :allEntitiesDataFromParent="allEntitiesData"
                        :allFormTemplatesFromParent="allFormTemplates"
                        :allCompanyUserTypesDataFromParent="
                          allCompanyUserTypesData
                        "
                        @goToNextTab="goToNextTab"
                        @closeParent="backToEntityInfoList"
                        :getSuggestionFormData="getSuggestionFormData"
                      ></fields-preview-template>
                    </el-row>
                    <el-row
                      v-else-if="
                        tab.tabs[0].feature_type == 'RELATIONSHIP' &&
                        showData &&
                        currrentTab == tab.label
                      "
                    >
                      <EntityReationshipDataTable
                        :relationshipDetails="
                          getCurrentRelation(tab.tabs[0].feature_id)
                        "
                        :nextRealationshipDetails="null"
                        :hideLabel="true"
                        :currentEntityData="currentEntityData"
                        :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                        :customization="customization"
                      />
                    </el-row>
                    <el-row
                      v-else-if="
                        tab.tabs[0].feature_type == 'NESTED_RELATIONSHIP' &&
                        showData &&
                        currrentTab == tab.label
                      "
                    >
                      <EntityNestedReationshipDataTable
                        :relationshipDetails="
                          getCurrentNestedRelaiton(tab.tabs[0].feature_id)
                        "
                        :nextRealationshipDetails="null"
                        :hideLabel="true"
                        :currentTabInfo="getTabInfo(tab.tabs[0].feature_id)"
                        :customization="customization"
                      />
                    </el-row>
                    <el-row v-else-if="tab.tabs[0].feature_type == 'OTHERS'">
                      <div v-if="tab.tabs[0].feature_id == 'Files'">
                        <entity-data-documents
                          v-if="!refresh"
                          :entityDataId="entityDataId"
                          :entityId="entity_id"
                          :folder="folder"
                          :isApplicationUserSide="isApplicationUserSide"
                          :getEntityPermission="getEntityPermission"
                          :primaryLabel="getPrimaryLabel"
                          @moveToFolder="moveToFolder"
                        ></entity-data-documents>
                      </div>
                      <div v-else-if="tab.tabs[0].feature_id == 'eSignatures'">
                        <entity-documents
                          :entityId="entity_id"
                          :entityDataId="entityDataId"
                          :isDocument="true"
                          :customization="customization"
                        ></entity-documents>
                      </div>
                      <div v-else-if="tab.tabs[0].feature_id == 'Contacts'">
                        <entity-contacts
                          :contactType="entity_id"
                          :accountName="entityDataId"
                          :entityDataId="currentEntity"
                          :hideLabel="true"
                        ></entity-contacts>
                      </div>
                      <div v-else-if="tab.tabs[0].feature_id == 'Emails'">
                        <EntityEmails />
                      </div>
                    </el-row>
                  </div>
                  <div v-else>
                    <el-tabs
                      v-model="currentSubTab"
                      @tab-click="changeTab('sub')"
                      :before-leave="
                        (newTab, oldTab) =>
                          handleBeforeLeave('sub', newTab, oldTab)
                      "
                    >
                      <el-tab-pane
                        v-for="(sub_tab, sub_index) in tab.tabs"
                        :key="sub_index"
                        :name="sub_tab.feature_id + '#' + sub_index"
                        :label="sub_tab.label"
                      ></el-tab-pane>
                    </el-tabs>
                    <div style="width: 100% !important" class="filter-card">
                      <el-row
                        v-if="
                          currentSubTabFeatureType == 'TEMPLATE' && showData
                        "
                      >
                        <fields-preview-template
                          :templateData="
                            getCurrentStep(currentSubTab.split('#')[0])
                              .templateInfo
                          "
                          :has-next="false"
                          :entityDataId="entityDataId"
                          :workflow-step-id="
                            getCurrentStep(currentSubTab.split('#')[0])._id
                          "
                          :template-data-id="
                            getCurrentStep(currentSubTab.split('#')[0])
                              .templateDataId
                          "
                          :currentStep="
                            getCurrentStep(currentSubTab.split('#')[0])
                          "
                          :is-execute="isEdit"
                          :layout="customization.active_layout"
                          :currentEntity="currentEntity"
                          :entitySettings="entitySettings"
                          :isEdit="isEdit"
                          :entityData="entityData"
                          :currentMenu="currentMenu()"
                          :entityDataExists="entityDataExists"
                          :profilePicture="getUserprofilepicture"
                          :customization="customization"
                          :saveTemp="saveTemp"
                          :openNew="openNew"
                          :allEntitiesDataFromParent="allEntitiesData"
                          :allFormTemplatesFromParent="allFormTemplates"
                          :allCompanyUserTypesDataFromParent="
                            allCompanyUserTypesData
                          "
                          @goToNextTab="goToNextTab"
                          @closeParent="backToEntityInfoList"
                          :getSuggestionFormData="getSuggestionFormData"
                          @formDataUpdated="handleFormUpdate"
                          @validationFailed="checkingIsValid"
                          :formDataInTabsChange="formDataToSend"
                          @templateUpdated="templateUpdated"
                          v-on:getDataFromTemplatesPreview="
                            getDataFromPreviewTemplate
                          "
                          :saveAndSwitch="saveAndSwitch"
                          :isTabClickedWithoutSaving="isTabClickedWithoutSaving"
                          :previousSavingTab="previousSavingTab"
                        ></fields-preview-template>
                      </el-row>
                      <el-row
                        v-else-if="
                          currentSubTabFeatureType == 'RELATIONSHIP' && showData
                        "
                      >
                        <EntityReationshipDataTable
                          :relationshipDetails="
                            getCurrentRelation(currentSubTab.split('#')[0])
                          "
                          :nextRealationshipDetails="null"
                          :hideLabel="true"
                          :currentEntityData="currentEntityData"
                          :currentTabInfo="
                            getTabInfo(currentSubTab.split('#')[0])
                          "
                          :customization="customization"
                        />
                      </el-row>
                      <el-row
                        v-else-if="
                          currentSubTabFeatureType == 'NESTED_RELATIONSHIP' &&
                          showData
                        "
                      >
                        <EntityNestedReationshipDataTable
                          :isSplitView="true"
                          :showFields="showFields"
                          :relationshipDetails="
                            getCurrentNestedRelaiton(
                              currentSubTab.split('#')[0]
                            )
                          "
                          :nextRealationshipDetails="null"
                          :hideLabel="true"
                          :currentTabInfo="
                            getTabInfo(currentSubTab.split('#')[0])
                          "
                          :customization="customization"
                        />
                      </el-row>
                      <el-row
                        v-else-if="
                          currentSubTabFeatureType == 'OTHERS' &&
                          currentSubTab.split('#')[0] == 'Files'
                        "
                      >
                        <entity-data-documents
                          v-if="!refresh"
                          :entityDataId="entityDataId"
                          :entityId="entity_id"
                          :folder="folder"
                          :isApplicationUserSide="isApplicationUserSide"
                          :getEntityPermission="getEntityPermission"
                          :primaryLabel="getPrimaryLabel"
                          @moveToFolder="moveToFolder"
                        ></entity-data-documents>
                      </el-row>
                      <el-row
                        v-else-if="
                          currentSubTabFeatureType == 'OTHERS' &&
                          currentSubTab.split('#')[0] == 'eSignatures'
                        "
                      >
                        <entity-documents
                          :entityId="entity_id"
                          :entityDataId="entityDataId"
                          :isDocument="true"
                          :customization="customization"
                        ></entity-documents>
                      </el-row>
                      <el-row
                        v-else-if="
                          currentSubTabFeatureType == 'OTHERS' &&
                          currentSubTab.split('#')[0] == 'Contacts'
                        "
                      >
                        <entity-contacts
                          :contactType="entity_id"
                          :accountName="entityDataId"
                          :entityDataId="currentEntity"
                          :hideLabel="true"
                        ></entity-contacts>
                      </el-row>
                      <el-row
                        v-else-if="
                          currentSubTabFeatureType == 'OTHERS' &&
                          currentSubTab.split('#')[0] == 'Emails'
                        "
                      >
                        <EntityEmails />
                      </el-row>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
      <el-drawer
        title="Layout Options"
        :visible.sync="entityViewModal"
        :direction="'rtl'"
        class="layout-drawer"
        :size="getIsMobile ? '50%' : '30%'"
        :before-close="checkForFilterUpdate"
      >
        <div class="layout-container">
          <!-- <h7 class="layout-head">Layout style</h7> -->
          <div class="layout-head">Layout style</div>
          <el-select
            size="mini"
            v-model="activeLayout"
            placeholder="Select layout"
            style="width: 100%; margin-top: 7px"
          >
            <el-option
              v-for="layout in ['STANDARD', 'PROFILE', 'COVER', $route.query.dataId ? 'SPLIT_VIEW' : ''].filter(Boolean)"
              :key="layout + '_sidebar'"
              :label="layout"
              :value="layout"
            ></el-option>
          </el-select>
          <!-- <h7 class="layout-head">Profile settings</h7> -->
          <el-checkbox
            v-if="
              !isApplicationUserSide &&
              showNestedRelationshipCheckbox &&
              activeLayout !== 'SPLIT_VIEW' &&
              this.getIframeUrlInfo == null
            "
            v-model="showNestedRelaships"
            style="margin-top: 5px"
            >Show nested relationships</el-checkbox
          >
          <div class="layout-head" style="width: 100%; margin-top: 15px">
            Profile settings
          </div>
          <div
            style="width: 100%; margin-top: 7px"
            v-if="activeLayout !== 'SPLIT_VIEW'"
          >
            <div class="sub-head">Cover photo</div>
            <el-select
              size="mini"
              v-model="showFields.cover_profile"
              placeholder="Select cover photo"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="image in getEntityFieldsByType(['IMAGE'])"
                :key="image.key + '_cover'"
                :label="image.label"
                :value="image.key"
              ></el-option>
            </el-select>
          </div>
          <div style="width: 100%; margin-top: 10px">
            <div class="sub-head">Profile image</div>
            <el-select
              size="mini"
              v-model="showFields.profile"
              placeholder="Select cover photo"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="image in getEntityFieldsByType(['IMAGE'])"
                :key="image.key + '_cover'"
                :label="image.label"
                :value="image.key"
              ></el-option>
            </el-select>
          </div>
          <div
            style="width: 100%; margin-top: 10px"
            v-if="activeLayout == 'STANDARD'"
          >
            <div class="sub-head">Enable Vertical View</div>
            <el-checkbox
              v-model="showFields.vertical_view"
              label="vertical view"
              size="large"
            ></el-checkbox>
          </div>
          <div
            style="width: 100%; margin-top: 10px"
            v-if="activeLayout == 'SPLIT_VIEW'"
          >
            <div class="sub-head">Enable</div>
              <table>
                <tr>
                  <td>
                    <el-checkbox v-if="emailFields.length"
                    v-model="showFields.email"
                    label="EMAIL"
                    size="large"
                    slot="prepend"
                    @change="changeActivetab('kf_email')"></el-checkbox>

                  </td>
                  <td>
                    <el-select   v-model="showFields.emailField" placeholder="Select" class="input-with-select" clearable v-if="showFields.email && emailFields.length" >
                
                      <el-option
                        v-for="(field, i) in emailFields"
                        :key="field + i"
                        :value="field.template_key"
                        :label="field.label"
                      >
                      </el-option>
                      
                  </el-select>
                  </td>

                </tr>
                <tr>
                  <td>
                    <el-checkbox
              v-if="phoneNumberFields.length"
              v-model="showFields.call"
              label="Call"
              size="large"
              @change="changeActivetab('call')"
            />
                  </td>
                  <td>
                    <el-select   v-model="showFields.phoneField" placeholder="Select" class="input-with-select" clearable v-if="showFields.call && phoneNumberFields.length" >
                      <el-option
                        v-for="(field, i) in phoneNumberFields"
                        :key="field + i"
                        :value="field.template_key"
                        :label="field.label"
                      >
                      </el-option>
                  </el-select>
                  </td>
                </tr>
              </table>

            
          </div>
          <div v-if="activeLayout == 'SPLIT_VIEW'">
            <div style="width: 100%; margin-top: 10px">
              <div class="sub-head">select your last column</div>
              <el-select
                size="mini"
                v-model="showFields.split_col1"
                placeholder="Select collapse View"
                style="width: 100%"
                clearable
                multiple
                collapse-tags
              >
                <template
                  v-if="
                    customization &&
                    customization.tab_groups &&
                    customization.tab_groups.length > 0
                  "
                >
                  <el-option
                    v-for="(tab, index) in customization.tab_groups.slice(1)"
                    :key="'tab_' + index"
                    :label="tab.label"
                    :value="tab._id"
                  />
                </template>
                <template v-else>
                  <el-option
                    v-for="relationship in relationshipsData"
                    :key="relationship._id"
                    :label="relationship.relationship_title"
                    :value="relationship._id"
                  />
                  <el-option
                    v-for="nestedRelationship in nestedRelationshipsData"
                    :key="nestedRelationship._id"
                    :label="nestedRelationship.relationship_title"
                    :value="nestedRelationship._id"
                  />
                  <el-option label="Files" value="files" />
                  <el-option label="eSignatures" value="esignature" />
                  <el-option label="Notifications" value="emails" />
                </template>
              </el-select>
            </div>
            <div style="width: 100%; margin-top: 10px">
              <div class="sub-head">select your Activities</div>
              <el-select
                size="mini"
                v-model="showFields.split_col2"
                placeholder="Select Activities"
                style="width: 100%"
                clearable
                multiple
                collapse-tags
              >
                <template
                  v-if="
                    customization &&
                    customization.tab_groups &&
                    customization.tab_groups.length > 0
                  "
                >
                  <el-option
                    v-for="(tab, index) in customization.tab_groups.slice(1)"
                    :key="'tab_' + index"
                    :label="tab.label"
                    :value="tab._id"
                  />
                </template>
                <template v-else>
                  <el-option
                    v-for="(relationship, index) in relationshipsData"
                    :key="'relationship_' + index"
                    :label="relationship.relationship_title"
                    :value="'relationship_' + index"
                  />
                  <el-option
                    v-for="(
                      nestedRelationship, index
                    ) in nestedRelationshipsData"
                    :key="'nestedRelationship_' + index"
                    :label="nestedRelationship.relationship_title"
                    :value="'nestedRelationship_' + index"
                  />
                  <el-option label="Files" value="files" />
                  <el-option label="eSignatures" value="esignature" />
                  <el-option label="Notifications" value="emails" />
                </template>
                  <el-option label="Call Logs" value="call" />
                  <el-option label="Email" value="kf_email" />
              </el-select>
            </div>
            <div style="width: 100%; margin-top: 10px">
              <div class="sub-head">Quick Add</div>
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="AddActivity"
              ></el-button>
              <el-button
                v-if="showFields.Activities && showFields.Activities.length"
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="openEditPopup"
              ></el-button>
            </div>
            <div style="width: 100%; margin-top: 10px" v-if="!customization">
              <div class="sub-head">Action Buttons</div>
              <el-checkbox
                v-model="showFields.preview"
                label="Preview"
                size="large"
              ></el-checkbox>
              <el-checkbox
                v-model="showFields.redirect_to"
                label="Redirect To"
                size="large"
              ></el-checkbox>
              <el-checkbox
                v-model="showFields.delete_btn"
                label="Delete"
                size="large"
              ></el-checkbox>
            </div>
          </div>
          <div style="width: 100%; margin-top: 10px">
            <div class="sub-head">Heading</div>
            <el-select
              size="mini"
              v-model="showFields.title"
              placeholder="Select heading field"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="(image, i) in getEntityFieldsByType()"
                :key="i + '_heading'"
                :label="image.label"
                :value="image.key"
              ></el-option>
            </el-select>
          </div>
          <div style="width: 100%; margin-top: 10px">
            <div class="sub-head">Description</div>
            <el-select
              size="mini"
              v-model="showFields.description"
              placeholder="Select description field"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="image in getEntityFieldsByType([
                  'MULTI_LINE_TEXT',
                  'SINGLE_LINE_TEXT',
                ])"
                :key="image.key + 'description'"
                :label="image.label"
                :value="image.key"
              ></el-option>
            </el-select>
          </div>
          <!-- <h3 class="layout-head mt-1">Profile fields</h3> -->
          <div
            class="layout-head"
            style="width: 100%; margin-top: 10px"
            v-if="activeLayout !== 'SPLIT_VIEW'"
          >
            Profile fields
            <p class="warning" style="font-size: 65%">
              *ONLY YOU CAN CHOOSE UPTO 5 FIELDS*
            </p>
          </div>
          <div
            style="width: 100%; margin-top: 7px"
            v-if="activeLayout !== 'SPLIT_VIEW'"
          >
            <el-input
              placeholder="Find field"
              v-model="search_field"
              clearable
              size="mini"
            ></el-input>
            <div
              style="width: 100%; margin-top: 5px"
              v-if="activeLayout !== 'SPLIT_VIEW'"
            >
              <el-scrollbar wrap-style="padding: 10px; max-height: 280px;">
                <div v-if="search_field">
                  <el-collapse
                    accordion
                    v-if="getSearchData && getSearchData.length"
                    v-model="activeTemplate"
                  >
                    <el-collapse-item
                      v-for="temp in getSearchData"
                      :key="temp.template_id + '_template'"
                      name="1"
                    >
                      <template slot="title">
                        <div style="width: 100%">
                          {{ temp.templateInfo.name }}
                        </div>
                      </template>
                      <div
                        v-if="
                          temp &&
                          temp.templateInfo &&
                          temp.templateInfo.sections &&
                          temp.templateInfo.sections[0].fields
                        "
                      >
                        <div
                          class="field-selection"
                          v-for="field in temp.templateInfo.sections[0].fields.filter(
                            (e) => contentFields.indexOf(e.input_type) === -1
                          )"
                          :key="field.key + '_show_fields_4'"
                          @click="selectField(field, temp.template_id)"
                        >
                          {{ field.label }}
                          <div class="float-right">
                            <img
                              v-if="checkfieldVisible(field, temp.template_id)"
                              src="@/assets/img/icons/entityViews/EYE.svg"
                            />
                            <img
                              v-else
                              src="@/assets/img/icons/entityViews/HIDE.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                  <span v-else>No field found</span>
                </div>
                <el-collapse accordion v-else v-model="activeTemplate">
                  <el-collapse-item
                    v-for="temp in (currentEntity.templates || []).filter(
                      (e) => !e.is_repeatable
                    )"
                    :key="temp.template_id + '_template_1'"
                    name="1"
                  >
                    <template slot="title">
                      <div style="width: 100%">
                        {{ temp.templateInfo.name }}
                      </div>
                    </template>
                    <div
                      v-if="
                        temp &&
                        temp.templateInfo &&
                        temp.templateInfo.sections &&
                        temp.templateInfo.sections[0].fields
                      "
                    >
                      <div
                        class="field-selection"
                        v-for="field in temp.templateInfo.sections[0].fields.filter(
                          (e) =>
                            contentFields.indexOf(e.input_type) === -1 &&
                            e.input_type != 'SIGNATURE'
                        )"
                        :key="field.key + '_show_fields_5'"
                        @click="selectField(field, temp.template_id)"
                      >
                        {{ field.label }}
                        <div class="float-right">
                          <img
                            v-if="checkfieldVisible(field, temp.template_id)"
                            src="@/assets/img/icons/entityViews/EYE.svg"
                          />
                          <img
                            v-else
                            src="@/assets/img/icons/entityViews/HIDE.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </el-drawer>
      <el-dialog
        title="Primary Data"
        :visible.sync="addPrimaryDataDialog"
        :before-close="backToEntityInfoList"
        class="primary-data-popup"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-scrollbar
          wrap-style="max-height: 400px;"
          v-loading="primaryDataAddLoading"
          :element-loading-text="primaryDataAddLoadingText"
        >
          <el-form
            ref="form"
            :model="form"
            v-if="getPrimaryFields && getPrimaryFields.length"
            @submit.native.prevent
          >
            <PrimaryDataForm
              :fieldsListData="getPrimaryFields"
              :form="form"
              :entityDataId="this.entityDataId"
              :parentyEntityId="entity_id"
            ></PrimaryDataForm>
          </el-form>
        </el-scrollbar>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="addPrimaryDataToEntity('form')"
            size="small"
          >
            <i class="el-icon-plus"></i> Add Primary Info
          </el-button>
          <el-button @click="backToEntityInfoList" size="small"
            >Cancel</el-button
          >
        </span>
      </el-dialog>
    </div>
    <dialog-component
      title="Send Notification"
      :visible="sendNotificationDialog"
      v-loading="emailConfigLoading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="true"
        :entityData="selectedNotifyEntity"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeSendNotificationDialog"
      ></EmailConfig>
    </dialog-component>
    <dialog-component
      title="Send WA Notification"
      :visible="sendWANotificationDialog"
      v-loading="emailConfigLoading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendWANotificationDialog = false"
      :isShowFooter="false"
    >
      <WAConfig
        :currentEntity="currentEntity"
        :isSendIndividual="true"
        :entityData="selectedNotifyEntity"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeSendNotificationDialog"
      ></WAConfig>
    </dialog-component>
    <dialog-component
      :containerMaxHeight="getIsMobile ? '100%' : '90%'"
      :containerMinWidth="
        getIsMobile ? '100%' : uploadOption === 'upload' ? '40%' : '70%'
      "
      title="Update Profile Picture"
      :visible="outerVisible"
      @before-close="handleDialogClose"
    >
      <div class="uploader">
        <div class="radio-group-wrapper">
          <el-radio-group
            v-model="uploadOption"
            size="mini"
            @change="handleUploadOptionChange"
          >
            <el-radio-button :label="'upload'"
              >Upload from device</el-radio-button
            >
            <el-radio-button :label="'capture'"
              >Capture from device</el-radio-button
            >
          </el-radio-group>
        </div>
        <div v-if="uploadOption === 'upload'">
          <vue-anka-cropper
            class="upload-file"
            :options="{
              aspectRatio: false,
              closeOnSave: true,
              cropArea: 'circle',
              croppedHeight: 1000,
              croppedWidth: 1000,
              cropperHeight: false,
              dropareaMessage:
                ' Drag & Drop  file here or use the button below.',
              frameLineDash: [1, 1000],
              frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
              handleFillColor: 'rgba(255, 255, 255, 0.2)',
              handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
              handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
              handleSize: 5,
              handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
              layoutBreakpoint: 40,
              maxCropperHeight: 1000,
              maxFileSize: 8000000,
              overlayFill: 'rgba(0, 0, 0, 0.5)',
              previewOnDrag: true,
              previewQuality: 0.65,
              resultQuality: 0.8,
              resultMimeType: 'image/jpeg',
              resultMimeType: 'image/png',
              resultMimeType: 'image/jpg',
              resultMimeType: 'image/gif',
              selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

              showPreview: true,
              skin: 'light',
              uploadData: {},
              uploadTo: false,
            }"
            name="logo"
            @cropper-error="fileError"
            @cropper-preview="uploadFile"
            @cropper-file-selected="uploadFile"
            @cropper-saved="onUpdate"
            v-loading="upLoadEffect"
            accept="image/jpeg,image/png,image/jpg,image/gif"
          ></vue-anka-cropper>
        </div>

        <div v-else>
          <div
            :class="
              capturedImage && getIsMobile
                ? 'mobileStylingContainer imageEditorContainer'
                : 'mobileStylingContainer2 imageEditorContainer'
            "
          >
            <div class="cameraContainer">
              <div>
                <el-button
                  @click="initializeCamera(false)"
                  type="primary"
                  size="mini"
                  icon="el-icon-camera"
                  v-if="!cameraStream"
                  >Click here to start</el-button
                >
                <video ref="video" autoplay style="width: 100%"></video>
                <el-button
                  @click="takePicture()"
                  size="mini"
                  type="primary"
                  v-if="cameraStream"
                >
                  <i class="fa fa-camera"></i> Capture
                </el-button>
                <el-tooltip content="Flip camera" placement="bottom">
                  <el-button
                    @click="toggleCamera()"
                    size="mini"
                    type="primary"
                    v-if="cameraStream && getIsMobile"
                    round
                  >
                    <i class="fa fa-refresh"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
            <div class="previewContainer">
              <canvas
                ref="canvas"
                style="width: 50px; height: 40px; visibility: hidden"
              ></canvas>
              <div class="preview-text" style="width: 400px">Preview</div>
              <div
                :style="{
                  width: '400px',
                  height: '300px',
                  border: 'black dotted',
                  position: 'relative',
                }"
              >
                <img
                  ref="image"
                  :src="capturedImage"
                  style="width: 100%; height: 100%"
                  v-if="capturedImage"
                  @load="initializeCropper"
                />
                <div
                  ref="cropperContainer"
                  style="
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    overflow: hidden;
                    border-radius: 50%;
                  "
                >
                  <cropper
                    ref="cropper"
                    :src="capturedImage"
                    :options="cropperOptions"
                    style="width: 200%; height: 200%"
                  ></cropper>
                </div>
              </div>
              <el-button
                @click="checkCroppedImage()"
                type="primary"
                size="mini"
                class="retake-btn"
                :disabled="!capturedImage"
              >
                <i class="fa fa-cloud-upload"></i>Upload</el-button
              >
              <el-button
                @click="reCapturePic()"
                size="mini"
                class="retake-btn"
                type="primary"
                :disabled="!capturedImage"
              >
                <i class="fa fa-camera-retro"></i>Retake</el-button
              >
              <el-button
                @click="rotateLeft"
                size="mini"
                type="primary"
                :disabled="!capturedImage"
                ><i class="fa fa-undo"></i
              ></el-button>
              <el-button
                @click="rotateRight"
                size="mini"
                type="primary"
                :disabled="!capturedImage"
                ><i class="fa fa-redo"></i
              ></el-button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            outerVisible = false;
            files = null;
            handleDialogClose();
          "
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="onUpdate()"
          v-if="uploadOption === 'upload'"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <el-drawer
      title="Privacy Link"
      :visible.sync="copyModal"
      :direction="'rtl'"
      :with-header="true"
      :size="getIsMobile ? '50%' : '20%'"
    >
      <IframeLink
        :routeKey="url_key"
        type="EntityDetailedView"
        :templateData="templateData"
      >
      </IframeLink>
    </el-drawer>
    <dialog-component
      title="Add Activity"
      :visible="addActivity"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="addActivity = false"
      :modalAppendToBody="true"
    >
      <div class="form-container">
        <label>Stat Icon</label>
        <el-button
          type="text"
          class="text-center ml-1"
          @click="iconPickerdialogVisible = true"
        >
          <div class="menu-icon">
            <icons
              :iconName="newActivity.icon ? newActivity.icon : 'dashboard'"
            ></icons>
          </div>
        </el-button>
        <el-form :model="newActivity">
          <el-form-item label="Label">
            <el-input
              v-model="newActivity.label"
              placeholder="Enter label"
            ></el-input>
          </el-form-item>
          <el-form-item label="Select Relationship Entity">
            <br />
            <el-select
              v-model="newActivity.feature"
              placeholder="Select an entity"
            >
              <template
                v-if="
                  customization &&
                  customization.tab_groups &&
                  customization.tab_groups.length > 0
                "
              >
                <el-option
                  v-for="(tab, index) in customization.tab_groups.slice(1)"
                  :key="'tab_' + index"
                  :label="tab.label"
                  :value="tab._id"
                />
              </template>
              <template v-else>
                <el-option
                  v-for="(relationship, index) in relationshipsData"
                  :key="'relationship_' + index"
                  :label="relationship.relationship_title"
                  :value="relationship._id"
                />
              </template>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addActivityToCol" size="small">
          <i class="el-icon-plus"></i> ADD
        </el-button>
        <el-button @click="addActivity = false" size="small">Cancel</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :title="'Select Stat Icon'"
      :visible="iconPickerdialogVisible"
      @before-close="iconPickerdialogVisible = false"
      top="25px"
      :containerMaxWidth="'77%'"
      :isShowFooter="false"
    >
      <div class="iconslistScrollable">
        <icons-list @icon-data="setIconPath"></icons-list>
      </div>
    </dialog-component>
    <dialog-component
      :visible.sync="editPopupVisible"
      title="Edit Activities"
      width="50%"
      @before-close="handleCloseEditPopup"
    >
      <el-table :data="showFields.Activities" style="width: 100%" border>
        <el-table-column label="Icon" prop="icon" width="80">
          <template slot-scope="scope">
            <icons :iconName="scope.row.icon" />
          </template>
        </el-table-column>
        <el-table-column
          label="Label"
          prop="label"
          width="200"
        ></el-table-column>
        <el-table-column label="Actions" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteActivity(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editPopupVisible = false" size="small"
          >Cancel</el-button
        >
        <el-button type="primary" @click="saveChanges" size="small"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      title="Confirm Tab Change"
      :visible="showTabChangeDialog"
      @before-close="cancelTabChange()"
      width="30%"
    >
      <div>
        <p
          class="mt-1 ml-2"
          style="color: #ff0000; font-size: 16px; font-weight: 900"
        >
          Unsaved changes detected!
        </p>
        <p class="mb-2 mr-2 ml-2">
          Do you want to save your changes before switching tabs?
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeTab('sub', true)">Switch Tab</el-button>
        <!-- <el-button v-if="checkIsContactDetailsOrNot" type="primary" @click="cancelTabChange"
          >Close</el-button
        > -->
        <el-button type="primary" @click="saveDataSwitching()"
          >Save Data</el-button
        >
      </span>
    </dialog-component>
    <add-entity-reationship-data-widget
      v-if="showAddRelationshipDataDialog"
      :relationshipDetails="selectedRelationshipDetails"
      :existedData="selectedRelationshipExistedIds"
      :autoPopup="true"
      v-on:entityDataClosed="showAddRelationshipDataDialog = false"
    ></add-entity-reationship-data-widget>
  </section>
</template>
<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { makeSlug } from "../../helpers/appHelper";
import SplitButton from "../customComponents/SplitButton.vue";
import NavigationHelper from "@/mixins/navigationHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import EntityContacts from "./EntityContacts.vue";
import EntityEmails from "./EntityDataEmails.vue";
import EntityHelper from "@/mixins/EntitiesHelper";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
import TryUsingAI from "@/components/templates/formComponentsExecute/TryUsingAI.vue";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import moment from "moment";
import axios from "@/config/axios";
var menuIconsData = require("@/assets/data/menu_icons.json");
var menuIconsDataApp = require("@/assets/data/menu_icons_application.json");
// import { $bus } from "../../main";
import { fetchEntitiesByPagination, fetchEntityById } from "@/repo/entityRepo";
import {
  fetchAllEntityCustomizationsById,
  fetchActiveEntityCustomizationsByEntity,
} from "@/repo/entityCustomizationRepo";
import { fetchFilterById } from "@/repo/filtersRepo";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  data() {
    return {
      entityDataWithIcon:{},
      isCancelTriggered:false,
      formDataToSend: {},
      switchTab: false,
      saveAndSwitch: false,
      isTabClickedWithoutSaving: false,
      previousSavingTab: null,
      showTabChangeDialog: false,
      formDataUpdation: {},
      isValid: false,
      nextTab: false,
      prevTab: false,
      initialFormData: {},
      Mounted: false,
      isModified: false,
      isTabChangeConfirmed: false,
      standard_vertical_view: false,
      activeDocumentsCollapse: "",
      activeMainCollapse: "",
      activeSubCollapse: "",
      collapseCard: true,
      split_col3: "",
      isEditActive: false,
      isSplitView: false,
      showTryUsingAI: false,
      formData: {},
      iconSrc: require("@/assets/Enter-Active.svg"),
      activeName: "1",
      activeLayout: "PROFILE",
      loading: false,
      currentEntity: {},
      loadingText: "Gathering data...",
      entity_id: "",
      entityDataId: "",
      relationshipsData: [],
      nestedRelationshipsData: [],
      showError: false,
      entitySettings: null,
      errorMessage: "Someting went wrong...!",
      showFields: {
        vertical_view: false,
        preview: false,
        redirect_to: false,
        delete_btn: false,
        profile: "defaultpic",
        cover_profile: "",
        title: "",
        description: "",
        fields: [],
        split_col1: [],
        split_col2: [],
        Activities: [],
        email: false,
        call: false,
        emailField:'',
        phoneField:''
      },
      newActivity: {
        label: "",
        type: "",
        feature: "",
        icon: "",
      },
      currentEntityData: null,
      currrentTab: "",
      isEdit: false,
      showTemplate: false,
      contentFields: [
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "VIDEO",
        "SINGLE_LINE_CONTENT",
        "HORIZONTAL_LINE",
        "DIVISION",
        "HTML_CONTENT",
        "ACTION_BUTTON",
        "HTML_CONTENT",
        "MASKED",
        "HYPER_LINK",
        "DATA_TABLE",
        "GIF",
      ],
      entityViewModal: false,
      search_field: "",
      activeTemplate: "1",
      addPrimaryDataDialog: false,
      form: {},
      entityAllFields: [],
      primaryDataAddLoading: false,
      primaryDataAddLoadingText: "",
      defaultTabs: ["entities_data", "files", "document_data"],
      currentRelationshipDetails: {},
      currentEntityNestedRelationships: {},
      templateKey: "",
      currentRelationshipIndex: -1,
      hasNextRelationshipDetails: null,
      isApplicationUserSide: false,
      showData: true,
      showNestedRelaships: false,
      folder: "",
      refresh: false,
      entityDataExists: false,
      dialogVisible: true,
      printEntityData: false,
      currentSubTab: "",
      currentSubTabFeatureType: "",
      tabsFlag: "",
      sendNotificationDialog: false,
      sendWANotificationDialog: false,
      emailConfigLoading: false,
      paramsData: {},
      selectedNotifyEntity: null,
      showNestedRelationshipCheckbox: true,
      defaultLoading: true,
      profilePicture: null,
      outerVisible: false,
      files: null,
      upLoadEffect: false,
      uploadOption: "upload",
      fileData: null,
      capturedImage: null,
      warningmsg: "You have not saved the changes,do you wish to continue ?",
      cameraStream: null,
      cropper: null,
      cropperOptions: {
        aspectRatio: 1,
        viewMode: 2,
        autoCropArea: 0.5,
        responsive: true,
        rotatable: true,
        dragMode: "move",
      },
      cropperInstance: null,
      facingMode: "user",
      showEditPreview: false,
      customization_id: "",
      customization: null,
      saveTemp: false,
      openNew: false,
      mountedData: false,
      getEntityDataById: null,
      getSingleFilterData: null,
      allEntityFields: [],
      copyModal: false,
      url_key: "",
      templateData: [],
      checkRoute_key: "",
      allEntitiesData: [],
      allFormTemplates: [],
      allCompanyUserTypesData: [],
      entityFormData: {},
      createdAt: "",
      updatedAt: "",
      activeTab: "overview",
      activeSubTab: "",
      selectedCollapseItems: [],
      addActivity: false,
      menuIcons: menuIconsData,
      menuIconsForApp: menuIconsDataApp.categories,
      iconPickerdialogVisible: false,
      editPopupVisible: false,
      editingActivity: null,
      selectedRelationshipDetails: [],
      showAddRelationshipDataDialog: false,
      selectedRelationshipExistedIds: [],
      isSaveAsDraft: false,
      getEntityVariableFieldsInputTypes:[],
      currentEntityFields:[],
      emailFields:[],
      phoneNumberFields:[],
    };
  },
  setup() {
    const activeCollapse = ref("about_this_company");
    return {
      activeCollapse,
    };
  },
  computed: {
    formattedPhoneNumber() {
      let data={}
      this.currentEntityData?.templates_data.map((info)=>{
        data[info?.template_id]= info?.template_data_id
        return info.template_data_id  ;
      });
      if(this.showFields?.phoneField)
      {
      let field= this.showFields?.phoneField.split("#") ||[];
      let selectedData=data?.[field?.[0]]?.template_data?.[field?.[1]] && data[field[0]].template_data[`${field[1]}_code`]&& data[field[0]].template_data[`${field[1]}_code`].formattedNumber?data[field[0]].template_data[`${field[1]}_code`].formattedNumber : "";
      return selectedData;
    }
      else{
        return "";
      }
    },
    formattedEmail(){
      let data={};
      (this.currentEntityData?.templates_data || []).map((info)=>{
        data[info?.template_id]= info?.template_data_id
        return info.template_data_id  ;
      });
      if(this.showFields?.emailField)
      {
      let field= this.showFields?.emailField.split("#") ||[];
      return data?.[field?.[0]]?.template_data?.[field?.[1]] || "";
      }
      else{
        return "";
      }
    },
    getActivitiesToShow() {
      // Ensure showFields exists and has Activities
      if (
        this.showFields?.Activities &&
        Array.isArray(this.showFields.Activities)
      ) {
        if (this.showFields.email && this.showFields.call) {
          return this.showFields.Activities.slice(0, 2); // Show first 2 activities
        } else if (this.showFields.email || this.showFields.call) {
          return this.showFields.Activities.slice(0, 3); // Show first 3 activities
        } else {
          return this.showFields.Activities.slice(0, 4); // Show first 4 activities
        }
      }

      // Return an empty array if Activities is undefined or not an array
      return [];
    },
    shouldDisableDropdown() {
      return this.getMainButtonData().name == "Edit";
    },
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityNestedRelationships",
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getCurrentEntityNestedRelationships",
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("entities", [
      // "getEntityDataById",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getCompanyEntityDetails",
      "getEntityPrimaryData",
      "getEntityShowFields",
      "getEntityDataExists",
      "getEntityDataByIdError",
      "getSentEntityNotificationStatus",
      "getSentEntityNotificationStatusErrors",
      "getSentEntityDataNotificationStatus",
    ]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getDefaultDateFormat",
      "getUserType",
    ]),
    ...mapGetters("applicationUsersPermission", ["getEntityPermission"]),
    ...mapGetters("entitiesData", [
      "getCreateDocumentForEntityData",
      "getTempEntityData",
    ]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getFileUploadStatus",
      "getBase64FileUpload",
    ]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus"]),
    ...mapGetters("entityCustomization", [
      "getCustomizationById",
      "getEntityActiveCustomization",
    ]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("templates", ["getSuggestionFormData"]),

    entityData() {
      return this.currentEntityData;
    },
    combinedIcons() {
      let icons = [];

      if (this.showFields.email) {
        icons.push({ icon: "el-icon-message", label: "Email" });
      }

      if (this.showFields.call) {
        icons.push({ icon: "el-icon-phone-outline", label: "Call" });
      }

      if (this.showFields.Activities) {
        icons = icons.concat(this.showFields.Activities);
      }

      return icons;
    },
    filteredRelationships() {
      return this.relationshipsData.filter((relation) =>
        this.showFields.split_col1.includes(relation._id)
      );
    },
    filteredNestedRelationships() {
      return this.nestedRelationshipsData.filter((nested) =>
        this.showFields.split_col1.includes(nested._id)
      );
    },
    filteredTabGroups() {
      return this.customization?.tab_groups.filter((tab) =>
        this.showFields.split_col1.includes(tab._id)
      );
    },
    filteredActivityTabGroups() {
      // return ()=>{
        let customTabs= this.customization?.tab_groups.filter((tab) =>
        this.showFields.split_col2.includes(tab._id)
      );
        let defult=[{_id:'kf_email',label:'Email',tabs:[]},{_id:'call',label:'Calls',tabs:[]}];
        let defultTabs= defult.filter((tab) =>
        this.showFields.split_col2.includes(tab._id)
      );
      return [...customTabs,...defultTabs];
      
    },
    getUpdatedForm() {
      return this.formData;
    },
    getGalleryImages() {
      return [];
    },
    getSearchData() {
      let result = [];
      if (this.search_field) {
        this.currentEntity.templates.forEach((td) => {
          let template = JSON.parse(JSON.stringify(td));
          if (template?.templateInfo?.sections && !template.is_repeatable) {
            let filterFields = template.templateInfo.sections[0].fields.filter(
              (field) =>
                field?.label &&
                field.label
                  .toLowerCase()
                  .includes(this.search_field.toLocaleLowerCase())
            );
            if (filterFields && filterFields.length > 0) {
              template.templateInfo.sections[0].fields = [];
              template.templateInfo.sections[0].fields = [...filterFields];
              result.push(template);
            }
          }
        });
      }
      return result;
    },
    getPrimaryFields() {
      if (this.getEntityDataById && this.getEntityDataById.primaryFields) {
        let fields = this.entityAllFields.map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }
          return e;
        });
        return fields.filter((e) => {
          return (
            this.getEntityDataById.primaryFields
              .flatMap((e) => e.key)
              .indexOf(e.key) !== -1
          );
        });
      }
      return [];
    },
    getRequired() {
      return this.getPrimaryFields.filter(
        (e) => this.form[e.key] || typeof this.form[e.key] === "number"
      ).length;
    },
    getTotalCount() {
      return this.getPrimaryFields.filter((e) => e.key).length;
    },
    isCurrentSlugIsRelationship() {
      let relationship = this.$route.query.relationship_id;
      if (relationship) {
        return (
          this.getCurrentRelationshipDetails &&
          this.getCurrentRelationshipDetails.relationship_id == relationship
        );
      }
      return false;
    },
    isCurrentSlugIsNestedRelationship() {
      let relationship = this.$route.query.relationship_id;
      return (
        relationship &&
        this.getCurrentNestedRelationshipDetails &&
        this.getCurrentNestedRelationshipDetails._id == relationship
      );
    },
    getCurrentRelationshipDetails() {
      return this.currentRelationshipDetails;
    },
    getCurrentNestedRelationshipDetails() {
      return this.currentEntityNestedRelationships;
    },
    checkIsOneFieldselected() {
      let selectedFields = ["profile", "title", "description", "fields"];
      if (this.showFields && Object.keys(this.showFields).length > 0) {
        let field = Object.keys(this.showFields).find(
          (e) =>
            selectedFields.indexOf(e) != -1 &&
            this.showFields[e] &&
            this.showFields[e].length
        );
        if (field) {
          return true;
        }
      }
      return false;
    },
    getPrimaryLabel() {
      if (
        this.currentEntity?.primaryFields &&
        this.currentEntityData?.templates_data
      ) {
        let [primaryField] = this.currentEntity.primaryFields;
        let templateData = this.currentEntityData.templates_data.find(
          (t) => t.template_id == primaryField.template_id
        );
        if (templateData?.template_data_id?.template_data?.[primaryField.key]) {
          return templateData.template_data_id.template_data[primaryField.key];
        }
      }
      return "";
    },
    getUserprofilepicture() {
      return this.profilePicture;
    },
    displayBackButton() {
      if (this.getIframeUrlInfo) {
        let modifiedKey = this.checkRoute_key;
        if (this.checkRoute_key.startsWith("if#")) {
          const parts = this.checkRoute_key.split("#");
          if (parts.length > 2) {
            modifiedKey = parts.slice(2).join("#");
          }
        }
        if (modifiedKey === this.getIframeUrlInfo.url_key) {
          return false;
        }
      }
      return true;
    },
    isExactEntityEditRoute() {
      let route = this.$route.path;
      return route.includes(`/entity/edit`);
    },
    shouldShowLink() {
      return (
        this.getIframeUrlInfo === null &&
        !this.isApplicationUserSide &&
        !this.isExactEntityEditRoute
      );
    },
    checkIsDeletedData() {
      if (
        this.currentEntityData?.status == "ARCHIVED" &&
        !this.currentEntityData?.isCreatedFromFormbuilder
      ) {
        return false;
      }
      return true;
    },
  },
  mixins: [
    NavigationHelper,
    entityRelationshipMixin,
    EntityHelper,
    templateBuilderHelper,
  ],
  created() {
    // Initialize the first tab as active if data is available
    if (this.showFields.split_col2.length > 0) {
      this.activeSubTab = this.showFields.split_col2[0];
    }
  },

  async mounted() {
    this.isTabChangeConfirmed = true;

    let [entities, entitySettings] = await Promise.all([
      fetchEntitiesByPagination({
        get_all: true,
        forceFetchFromDB: true,
      }),
      this.fetchCurrentEntitySettings(),
    ]);
    if (entitySettings?.data) {
      this.entitySettings = entitySettings.data;
    }
    if (entities?.data) {
      this.allEntitiesData = [...entities.data];
      this.entityDataWithIcon=[...entities.data]
    }
    this.$store.commit(
      "entities/setEntityPrimaryLabels",
      {},
      {
        root: true,
      }
    );
    this.entity_id = this.$route.params.entity_id;
    this.entityDataId = this.$route.query.dataId;
    if (this.$route.query.layout) {
      this.activeLayout = this.$route.query.layout;
    }
    if (this.$route.query.tab) {
      this.currrentTab = this.$route.query.tab;
    }
    if (this.$route.query.subTab && this.$route.query.tabOrd) {
      this.currentSubTab =
        this.$route.query.subTab + "#" + this.$route.query.tabOrd;
    }
    if (this.$route.query.subType) {
      this.currentSubTabFeatureType = this.$route.query.subType;
    }
    if (this.$route.query.customization) {
      this.customization_id = this.$route.query.customization;
    }
    if (
      this.$route.name == "EntityDetailedEdit" ||
      this.$route.name == "ApEntityExecute" ||
      this.$route.name == "EntityDetailedEdit2_External"
    ) {
      this.isEdit = true;
    }
    if (
      this.$route.name == "ApEntityExecute" ||
      this.$route.name == "ApPreviewEntityData" ||
      this.$route.path.includes("/ap/")
    ) {
      this.isApplicationUserSide = true;
    }
    if (this.isApplicationUserSide && !this.$route.query.menu_id) {
      let menuItems = [];
      if (this.getEntityMenu?.menu_list) {
        this.getEntityMenu.menu_list.map((menu) => {
          if (!menu.children.length) {
            menuItems.push(menu);
          } else {
            menu.children.map((sub) => {
              if (!sub.children.length) {
                menuItems.push(sub);
              } else {
                menuItems = [...menuItems, ...sub.children];
              }
            });
          }
        });
      }
      let menuItem = menuItems.find(
        (e) =>
          e &&
          e.feature == "entities" &&
          (e.entity_id || e.relational_entity || e.nested_relational_entity) &&
          [
            e.entity_id,
            e.relational_entity,
            e.nested_relational_entity,
          ].includes(this.entity_id)
      );
      this.$route.query["key"] = Math.floor(Math.random() * 100000);
      if (menuItem && menuItem._id) {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            key: Math.floor(Math.random() * 100000),
            menu_id: menuItem._id,
            filter: menuItem.filters?._id || "",
          },
        });
      }
    }
    if (this.entity_id) {
      await this.getNecessaryInfo();
    } else {
      this.showError = true;
      this.errorMessage = "Invalid url. Please check url..!";
    }
    this.$bus.$on("fetch-entity-data", () => {
      this.entityDataId = this.$route.query.dataId;
      this.fetchNewEntityData();
    });
    if (this.$route?.query?.folder) {
      this.folder = this.$route.query.folder;
    }
    this.defaultLoading = false;
    await this.checkUrlType();
    this.mountedData = true;
    this.checkRoute_key = this.generateOutputFromUrl(this.$route.fullPath, [
      "key",
      "page",
      "pageSize",
    ]);
    [
      this.allCompanyUserTypesData,
      this.allEntitiesData,
      this.allFormTemplates,
    ] = await Promise.all([
      this.fetchCompanyUsersTypes(),
      this.fetchAllEntities(),
      this.fetchAllTemplates(),
    ]);
    if (this.currentEntity?.templates?.length === 1) {
      this.templateData = this.currentEntity.templates[0];
    }
    this.Mounted = true;
    await this.getCurrentEntityFields();
  },
  components: {
    SplitButton,
    TryUsingAI,
    EntityTemplateView: () => import("./EntityTemplateView.vue"),
    PrimaryDataForm: () => import("./PrimaryDataCollectionForm"),
    EntityReationshipDataTable: () =>
      import("./EntityReationshipDataTable.vue"),
    EntityDocuments: () =>
      import("@/components/entity/userProfileEntityDocumentTemplates"),
    EntityNestedReationshipDataTable: () =>
      import("./EntityNestedReationshipDataTable.vue"),
    EntityDataDocuments: () => import("./EntityDataDocuments.vue"),
    FieldsPreviewTemplate: () =>
      import("../templates/templateFieldsPreview.vue"),
    EntityContacts,
    EntityEmails,
    EmailConfig: () => import("../notifications/emailConfig.vue"),
    WAConfig: () => import("../notifications/WAConfig.vue"),
    vueAnkaCropper,
    IframeLink: () => import("../../views/iframeLink.vue"),
    // CompanyDetailsAction: () => import('./companyDetailsAction.vue')
    AddEntityReationshipDataWidget: () =>
      import("./../widgets/AddEntityReationshipDataWidget.vue"),
    CommingSoon :() => import ("./commingsoon.vue"),
    SplitEmails : () => import ("./splitEmail.vue"),
    ErrorMessage : () => import ("./../../views/common/error.vue"),
  },
  methods: {
    async getData()
    {
      let data={}
      this.currentEntityData?.templates_data.map((info)=>{
        data[info?.template_id]= info?.template_data_id
        return info.template_data_id  ;
      });
      let field= this.showFields?.emailField.split("#");
      return data?.[field?.[0]]?.template_data?.[field?.[1]] || "";
    },
    async getCurrentEntityFields()
    {
      this.currentEntityFields = await this.fetchEntityDetails(
      this.entity_id ,
        true,
        true
      );

      this.emailFields = [];
      this.phoneNumberFields = [];
      let entityVariablesAndEntityFields = [];
      let fieldsObject = {};
      let standardTemplateData = await axios.get("/get-standard-template");
      let standardTemplate = standardTemplateData.data.data;
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "ENTITY_TABLE",
        "MULTI_LINE_TEXT",
        "GIF",
      ];
      //getting all doc and email fields
      this.currentEntityFields = this.currentEntityFields.map((e) => {
        if (["ENTITY_VARIABLE", "ENTITY"].includes(e.input_type)) {
          entityVariablesAndEntityFields.push(e);
          if (
            e.global_variable_entity_field == `${standardTemplate._id}#email`
          ) {
            this.emailFields.push(e);
          }
          else if (
            e.global_variable_entity_field == `${standardTemplate._id}#phone`
          ) {
            this.phoneNumberFields.push(e);
          }
        } else if (
          e.input_type == "SIGNATURE"
          // e.type == "CONTENT"
          // (e.properties?.filed_content && e.properties?.filed_content == "Hide")
        ) {
          return;
        } else if (e.input_type == "PHONE_COUNTRY_CODE") {
          this.phoneNumberFields.push(e);
        } else if (e.input_type == "EMAIL") {
          this.emailFields.push(e);
        } else if (e.template_key == `${standardTemplate._id}#email`) {
          this.emailFields.push(e);
        }
        //generating new object with key and field
        fieldsObject[e.key] = e;
        return {
          label: e.label,
          template_key: e.template_key,
          id: e.template_key,
          input_type: e.inputType,
          key: e.key,
          isSubjectSupported: !notSupportedSubjectTypes.includes(e.inputType),
        };
      });
      this.currentEntityFields = this.currentEntityFields.filter(
        (e) => e != undefined || e != null
      );
      if (
        entityVariablesAndEntityFields &&
        entityVariablesAndEntityFields.length
      ) {
        // await this.$store.dispatch("entities/fetchEntityFieldsInputTypes", {
        //   entityVariablesAndEntityFields,
        // });
        let response = await postAPICall("POST", "/get-entities-input-types", {entityVariablesAndEntityFields});
        if(response.data) {
          this.getEntityVariableFieldsInputTypes = response.data;
        }
        if (this.getEntityVariableFieldsInputTypes) {
          await (this.getEntityVariableFieldsInputTypes || []).map((e) => {
            if (e.input_type == "PHONE_COUNTRY_CODE") {
              let field = fieldsObject[e.key];
              if (field) {
                this.phoneNumberFields.push(field);
              }
              return ;
            } else if (e.input_type == "EMAIL") {
              let field = fieldsObject[e.key];
              if (field) {
                this.emailFields.push(field);
              }
            }
          });
        }
      }
      entityVariablesAndEntityFields?.map((fd) => {
        if (fd.input_type == "ENTITY_VARIABLE") {
          let input_type = fd.input_type,
            count = 0,
            newField;
          let global_variable_entity_field = fd.global_variable_entity_field;
          while (input_type == "ENTITY_VARIABLE" && count <= 10) {
            newField =
              this.companyAllTemplateFields?.[global_variable_entity_field];
            input_type = newField?.input_type || "SINGLE_LINE_TEXT";
            global_variable_entity_field =
              newField?.global_variable_entity_field;
            if(global_variable_entity_field == `${standardTemplate._id}#email`)
              {
                  this.emailFields.push(fd);
              }
            else  if(global_variable_entity_field == `${standardTemplate._id}#phone`)
              {
                  this.phoneNumberFields.push(fd);
              }
            count += 1;
          }
          if (
            input_type == "EMAIL" ||
            newField?.template_key == `${standardTemplate._id}#email`
          ) {
            this.emailFields.push(fd);
          } else if (fd.input_type == "PHONE_COUNTRY_CODE" ||
          newField?.template_key == `${standardTemplate._id}#phone`) {
            this.phoneNumberFields.push(fd);
          }
        }
      });
      this.phoneNumberFields = Array.from(new Set(this.phoneNumberFields));
      this.emailFields = Array.from(new Set(this.emailFields));
    },
    changeActivetab(value)
    {
      console.log("chnageActivetab",value,this.showFields)
      if (value === 'kf_email' && this.showFields.email) {
        if (!this.showFields.split_col2.includes("kf_email")) {
          this.showFields.split_col2.push("kf_email");
        }
        this.activeSubTab= this.showFields?.split_col2?.[0]||'';
      } 

      else if (value === 'call' && this.showFields.call) {
          if (!this.showFields.split_col2.includes("call")) {
            this.showFields.split_col2.push("call");
            this.activeSubTab= this.showFields?.split_col2?.[0]||'';
          }
      }
       else {
          this.showFields.split_col2 = this.showFields.split_col2.filter(item => item !== value);
          this.activeSubTab= this.showFields?.split_col2?.[0]||'';
      }
    },
    templateUpdated() {
      this.showTabChangeDialog = false;
      this.isTabChangeConfirmed = true;
      this.Mounted = false;
      this.initialFormData = {};
      this.formDataUpdation = {};
      this.previousSavingTab = null;
    },
    saveDataSwitching() {
      this.saveAndSwitch = true;
      this.previousSavingTab = this.prevTab;
    },
    getDataFromPreviewTemplate(data) {
      this.formDataUpdation = {
        ...data,
      };
      if (this.isCancelTriggered) {
        this.$nextTick(() => {
          this.isCancelTriggered = false;
          this.isTabClickedWithoutSaving = false;
        });
      }
    },
    handleFormUpdate(data) {
      if (
        this.isTabChangeConfirmed === true ||
        this.isTabChangeConfirmed === "true" ||
        this.isValid
      ) {
        this.Mounted = true;
        this.initialFormData = { ...data };
      }
    },
    deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;
      if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
      ) {
        return false;
      }
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (!keys2.includes(key)) return false;
        if (!this.deepEqual(obj1[key], obj2[key])) return false;
      }
      return true;
    },
    isFormModified() {
      return !this.deepEqual(this.formDataUpdation, this.initialFormData);
    },

    async handleBeforeLeave(tab, newTab, oldTab) {
      if (this.isValid) {
        this.Mounted = true;
      }
      if (this.Mounted) {
        this.nextTab = newTab;
        this.prevTab = oldTab;
        this.isModified = this.isFormModified();
        if (
          this.isModified === true ||
          (this.isModified === "true" &&
            this.currentEntity &&
            this.currentEntity.is_tab_switching_alert &&
            this.isEdit)
        ) {
          this.showTabChangeDialog = true;
          this.isTabClickedWithoutSaving = true;
          this.currentSubTab = this.prevTab;
          this.switchTab = false;
          return false;
        } else {
          this.currentSubTab = this.nextTab ? this.nextTab : newTab;
          await this.changeTab(tab, false);
        }
      }
    },
    async checkingIsValid(form, flag) {
      if (this.Mounted) {
        this.isValid = flag;
        if (this.isValid) {
          this.formDataUpdation = {
            ...form,
          };
          this.initialFormData = {
            ...form,
          };
          this.formDataToSend = { ...form };
          this.nextTab = null;
          this.currentSubTab = this.prevTab;
          this.saveAndSwitch = false;
          this.showTabChangeDialog = false;
          this.Mounted = false;
          this.isCancelTriggered = false;
          this.isTabChangeConfirmed = false;
        } else {
          this.showTabChangeDialog = false;
          const newRoute = await this.waitForRouteUpdate();
          if (newRoute) {
            this.isTabClickedWithoutSaving = false;
            this.switchTab = true;
            this.isCancelTriggered = false;
            this.currentSubTab = this.nextTab;
            await this.changeTab("sub", true);
          }
          this.saveAndSwitch = false;
        }
      }
    },
    waitForRouteUpdate() {
      return new Promise((resolve) => {
        const checkDataId = () => {
          if (this.$route.query.dataId) {
            resolve(this.$route);
          }
        };
        checkDataId();
        const interval = setInterval(() => {
          if (this.$route.query.dataId) {
            clearInterval(interval);
            resolve(this.$route);
          }
        }, 100);
      });
    },
    async cancelTabChange() {
      this.showTabChangeDialog = false;
      this.currentSubTab = this.prevTab;
      this.Mounted = false;
      this.initialFormData = this.formDataUpdation;
      this.formDataToSend = this.formDataUpdation;
      this.isTabChangeConfirmed = true;
      this.saveAndSwitch = false;
      this.isCancelTriggered = true;
      this.isTabClickedWithoutSaving = true;
      await this.changeTab("sub", false);
    },
    getTabInfo(featureId) {
      let tab = this.customization.tab_groups
        .flatMap((group) => group.tabs)
        .find((tab) => tab.feature_id.toString() == featureId.toString());
      return tab || {};
    },
    openActivity(activity) {
      const relationship = this.relationshipsData.find(
        (rel) => rel._id === activity.feature
      );
      if (relationship) {
        this.selectedRelationshipDetails = { ...relationship };
        this.selectedRelationshipExistedIds = [];
        this.showAddRelationshipDataDialog = true;
      }
      const customization = this.customization?.tab_groups?.find(
        (tab) => tab._id === activity.feature
      );
      if (customization) {
        const customizationFeature = customization.tabs[0].feature_id;
        const dataDetails = this.relationshipsData.find(
          (rel) => rel._id == customizationFeature
        );
        this.selectedRelationshipDetails = { ...dataDetails };
        this.selectedRelationshipExistedIds = [];
        this.showAddRelationshipDataDialog = true;
      }
    },
    openEditPopup() {
      this.entityViewModal = false;
      this.editPopupVisible = true;
    },

    handleCloseEditPopup() {
      this.editingActivity = null;
      this.editPopupVisible = false;
      
    },

    deleteActivity(activity) {
      const index = this.showFields.Activities.indexOf(activity);
      if (index !== -1) {
        this.showFields.Activities.splice(index, 1);
      }
    },
    saveChanges() {
      this.editPopupVisible = false;
      this.entityViewModal = true;
    },
    addActivityToCol() {
      if (this.newActivity.label) {
        if (!Array.isArray(this.showFields.Activities)) {
          this.$set(this.showFields, "Activities", []);
        }
        this.showFields.Activities.push({ ...this.newActivity });
        this.newActivity = {
          label: "",
          type: "",
          feature: "",
          icon: "dashboard",
        };
        this.addActivity = false;
        this.entityViewModal = true;
      }
    },
    setIconPath(name) {
      this.newActivity.icon = name;
      this.iconPickerdialogVisible = false;
    },
    AddActivity() {
      this.entityViewModal = false;
      this.addActivity = true;
    },
    editCompanyDetails() {
      this.isEditActive = true;
    },
    getActivityLabel(activity) {
      if (activity.startsWith("relationship_")) {
        const index = parseInt(activity.split("_")[1], 10);
        return this.relationshipsData[index]?.relationship_title || "Unknown";
      }
      if (activity && activity.startsWith("nestedRelationship_")) {
        const index = parseInt(activity.split("_")[1], 10);
        return (
          this.nestedRelationshipsData[index]?.relationship_title || "Unknown"
        );
      }
      const labels = {
        files: "Files",
        esignature: "eSignatures",
        emails: "Notifications",
        kf_email: "Email",
        call: "Calls"
      };
      return labels[activity] || "Unknown";
    },

    // Get relationship details for tabs
    getRelationshipDetails(activity) {
      const index = parseInt(activity.split("_")[1], 10);
      return this.relationshipsData[index] || {};
    },
    formatDateCreateDate(dateString) {
      const dateObj = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
        dateObj
      );
      const [day, month, year, hour, minute] = formattedDate.match(/\d+/g);
      const isPM = dateObj.getHours() >= 12 ? "PM" : "AM";
      const formattedTime = `${hour}:${minute} ${isPM}`;

      return `${month}/${day}/${year} ${formattedTime}`;
    },
    checkRolePermission(permission) {
      if (this.getUserType?.permissionSet && !this.isApplicationUserSide) {
        return this.getUserType.permissionSet.includes(permission);
      }
      return true;
    },
    getDataFromEntityTemplateView(form) {
      this.formData = {
        ...form,
      };
    },
    handleCopyContent({ fieldKey, dataToCopy }) {
      if (fieldKey) {
        this.$set(this.form, fieldKey, dataToCopy);
      } else {
        this.$message.warning("No output field specified.");
      }
    },
    async fetchCurrentEntitySettings() {
      return await postAPICall(
        "GET",
        `/entities/settings/${this.$route.params.entity_id}`
      );
    },
    getCustomizedButtonName(index) {
      if (this.customization?.buttons?.[index]?.value) {
        return this.customization?.buttons?.[index]?.value;
      }
      return index == 0 ? "Save" : "Edit";
    },
    showTryUsingAIMethod() {
      this.showTryUsingAI = true;
    },
    handleCloseTryUsingAI() {
      this.showTryUsingAI = false;
    },
    saveTemplateData(openNew) {
      this.openNew = openNew;
      this.saveTemp = true;
      setTimeout(() => {
        this.saveTemp = false;
      }, 100);
    },

    checkTemplateAccess(templateId) {
      if (this.isApplicationUserSide) {
        let menu = this.currentMenu();
        if (this.getEntityPermission) {
          if (menu?.entity_type == "ENTITY") {
            return this.getEntityPermission?.other_entities?.[
              this.currentEntity._id
            ]?.templates?.[templateId].includes("VIEW");
          } else if (menu?.entity_type == "RELATIONAL_ENTITY") {
            return this.getEntityPermission?.relational_entities?.[
              this.currentEntity._id
            ]?.templates?.[templateId].includes("VIEW");
          } else if (menu?.entity_type == "NESTED_RELATIONAL_ENTITY") {
            return this.getEntityPermission?.nested_relational_entities?.[
              menu.nested_relational_entity
            ]?.templates?.[templateId].includes("VIEW");
          }
        }
      }
      // else if(this.getIframeUrlInfo){
      //   return this.getIframeUrlInfo?.permissions?.templatesPermissions[templateId].includes("VIEW")
      // }
      return true;
    },
    checkAndUpdateProfile() {
      if (this.isEdit && this.currentEntity?.entity_type == "INDIVIDUAL") {
        this.outerVisible = true;
      }
    },
    handleDialogClose() {
      this.turnOffCamera();
      if (this.cropper) {
        this.cropper.destroy();
      }
      this.files = null;
      this.capturedImage = null;
      this.cropper = null;
      this.uploadOption = "upload";
      this.outerVisible = false;
    },
    handleUploadOptionChange(newOption) {
      if (newOption === "upload") {
        this.turnOffCamera();
      }
      this.files = null;
    },
    turnOffCamera() {
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
        this.cameraStream = null;
      }
      this.files = null;
    },
    async onUpdate() {
      this.upLoadEffect = true;
      if (
        this.files &&
        (this.files.type == "image/jpeg" ||
          this.files.type == "image/png" ||
          this.files.type == "image/jpg")
      ) {
        var formData = new FormData();
        formData.append(
          "files",
          this.files !== undefined ? this.files : this.files
        );

        await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);
        if (this.getFileUploadData) {
          if (this.currentEntityData?.contact_id?._id) {
            await this.$store.dispatch("contacts/updateContactData", {
              contact: {
                avatar: this.getFileUploadData,
                contact_id: this.currentEntityData?.contact_id?._id,
              },
            });
            await this.fetchNewEntityData();
            this.$notify({
              title: "Success",
              message: "Profile picture updated successfully",
              type: "success",
            });
            this.profilePicture = this.getFileUploadData;
            this.outerVisible = false;
            this.upLoadEffect = false;
          } else {
            this.$notify({
              title: "Success",
              message: "Please click on save button",
              type: "success",
            });
            this.profilePicture = this.getFileUploadData;
            this.outerVisible = false;
            this.upLoadEffect = false;
          }
        } else {
          this.loading = false;
          this.upLoadEffect = false;
          this.$alert("Error while uploading file");
        }
      } else {
        this.loading = false;
        this.upLoadEffect = false;
        this.$alert("Please upload a image file");
      }
    },
    async updateCapturedImage() {
      this.loading = true;
      this.loadingText = "Updating profile picture...";
      let params = {
        files: this.files,
        path: "profile-picture",
      };
      await this.$store.dispatch("s3FileUpload/uploadBase64File", params);
      if (this.getBase64FileUpload) {
        if (this.currentEntityData?.contact_id?._id) {
          await this.$store.dispatch("contacts/updateContactData", {
            contact: {
              avatar: this.getBase64FileUpload,
              contact_id: this.currentEntityData?.contact_id?._id,
            },
          });
          await this.fetchNewEntityData();
          this.$notify({
            title: "Success",
            message: "Profile picture updated successfully",
            type: "success",
          });
          this.profilePicture = this.getBase64FileUpload;
          this.outerVisible = false;
          this.upLoadEffect = false;
        } else {
          this.$notify({
            title: "Success",
            message: "Please click on save button",
            type: "success",
          });
          this.profilePicture = this.getBase64FileUpload
            ? this.getBase64FileUpload
            : this.getFileUploadData;
          this.outerVisible = false;
          this.upLoadEffect = false;
          this.loading = false;
        }
        this.handleDialogClose();
      } else {
        this.loading = false;
        this.upLoadEffect = false;
        this.$alert("Error while uploading file");
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    uploadFile(file) {
      if (file && typeof file == "string") {
        this.files = this.dataURLtoFile(file, "image1");
      } else {
        this.files = file;
      }
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    currentMenu() {
      if (
        this.isApplicationUserSide &&
        this.$route?.query?.menu_id &&
        this.getEntityMenu?.menu_list
      ) {
        let menu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              }
            });
          }
        });
        return menu;
      }
      return null;
    },
    checkAddPermission() {
      let appAddPermission = this.checkPerimission("ADD");
      let applicationUserSidePermission = true;
      if (this.isApplicationUserSide) {
        applicationUserSidePermission =
          this.$route.query.type == "s7aLF3NnAQg=" ? true : false;
      } else if (this.getIframeUrlInfo != null) {
        if (this.IframePermissions("ADD", "ViewEntityData") == true) {
          return true;
        } else {
          return false;
        }
      }
      return appAddPermission && applicationUserSidePermission;
    },
    checkEditPermission() {
      let appEditPermission = this.checkPerimission("EDIT");
      let applicationUserSidePermission = true;
      if (this.isApplicationUserSide) {
        applicationUserSidePermission =
          this.$route.query.type == "AUfYWb7SC6U=" ? true : false;
      } else if (this.getIframeUrlInfo != null) {
        if (this.IframePermissions("EDIT", "ViewEntityData") == true) {
          return true;
        } else {
          return false;
        }
      }
      return appEditPermission && applicationUserSidePermission;
    },
    checkFilterPermission(access) {
      if (!this.getSingleFilterData?.permissions?.access_set?.length) {
        return true;
      }
      return this.getSingleFilterData?.permissions?.access_set?.includes(
        access == "EDIT" ? "UPDATE" : access
      );
    },
    checkPerimission(access) {
      //If approver user
      let menu = null;
      let templateId = this.currentEntity.templates[0].template_id;
      if (
        this.isApplicationUserSide &&
        this.$route?.query?.menu_id &&
        this.getEntityMenu?.menu_list
      ) {
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              }
            });
          }
        });
      }
      if (this.isUserApprovingForm) {
        return false;
      }
      if (this.isApplicationUserSide) {
        if (this.getEntityPermission) {
          if (menu) {
            if (
              (menu?.entity_type == "ENTITY" &&
                this.getEntityPermission.other_entities &&
                this.getEntityPermission.other_entities[this.currentEntity._id]
                  ?.templates?.[templateId] &&
                this.getEntityPermission.other_entities[
                  this.currentEntity._id
                ]?.permissions.indexOf(access) === -1) ||
              !this.checkFilterPermission(access)
            ) {
              return false;
            } else if (
              (menu?.entity_type == "RELATIONAL_ENTITY" &&
                this.getEntityPermission.relational_entities &&
                this.getEntityPermission.relational_entities[
                  this.currentEntity._id
                ]?.permissions &&
                this.getEntityPermission.relational_entities[
                  this.currentEntity._id
                ]?.permissions.indexOf(access) === -1) ||
              !this.checkFilterPermission(access)
            ) {
              return false;
            } else if (
              (menu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
                menu?.nested_relational_entity &&
                this.getEntityPermission.nested_relational_entities &&
                this.getEntityPermission.nested_relational_entities[
                  menu?.nested_relational_entity
                ]?.permissions.indexOf(access) === -1) ||
              !this.checkFilterPermission(access)
            ) {
              return false;
            }
          } else if (
            this.$route.query.fromCustom ||
            this.$route.query.fromEntityView
          ) {
            if (
              this.getEntityPermission?.other_entities &&
              this.getEntityPermission?.other_entities[
                this.currentEntity._id
              ]?.permissions.indexOf(access) === -1
            ) {
              return false;
            }
            return true;
          }
        }
        return true;
      } else {
        if (
          access == "DOCUMENT" ||
          access == "SEND NOTIFICATION" ||
          access == "LAYOUT" ||
          access == "LAYOUT OPTIONS"
        ) {
          return true;
        }
        if (access == "EDIT") {
          access = "UPDATE";
        }
        if (
          this.$route.query?.filter &&
          this.getSingleFilterData &&
          this.currentEntity &&
          this.currentEntity._id == this.getSingleFilterData.entity_id &&
          this.getSingleFilterData?.permissions?.access_set
        ) {
          if (!this.getSingleFilterData.permissions.access_set.length) {
            return true;
          }
          return (
            this.getSingleFilterData.permissions.access_set.indexOf(access) !==
            -1
          );
        }
        return true;
      }
    },
    saveAndPrint(isPrint) {
      this.$bus.$emit("save-template-print", isPrint);
    },
    getCurrentRelation(relation_id) {
      let relation = this.relationshipsData.find(
        (e) => e && e._id.toString() == relation_id.toString()
      );
      if (relation) {
        let tab;
        if (this.activeLayout == "SPLIT_VIEW") {
          this.customization.tab_groups.some((e) => {
            const data = e.tabs.find(
              (f) => f.feature_id.toString() === relation_id.toString()
            );
            if (data) {
              tab = data;
            }
          });
        } else {
          tab = this.customization.tab_groups
            .find((e) => e.label.trim() == this.currrentTab.trim())
            .tabs.find(
              (e) => e.feature_id.toString() == relation_id.toString()
            );
        }
        relation["permissions"] =
          tab && tab.permissions
            ? tab.permissions
            : {
                add: true,
                assign: true,
                total: false,
                view: true,
                redirect: false,
                bulkUpdate: false,
                rowEdit: false,
                assign_type: "default_view",
              };
        relation["permissions"] = {
          ...{
            add: true,
            assign: true,
            view: true,
            redirect: true,
            bulkUpdate: false,
            rowEdit: false,
            assign_type: "default_view",
          },
          ...relation["permissions"],
        };
        relation["filter"] = tab && tab.filter ? tab.filter : "";
        relation["addDataLabel"] =
          tab && tab.addDataLabel ? tab.addDataLabel : "Add Data";
        relation["addNewLabel"] =
          tab && tab.addNewLabel ? tab.addNewLabel : "Add New";
        relation["assignLabel"] =
          tab && tab.assignLabel ? tab.assignLabel : "Assign";
        relation["aggregations"] = tab?.aggregations ? tab.aggregations : [];
        relation.parent_entity_data_id = this.entityDataId;
        return relation;
      }
      return null;
    },
    getCurrentNestedRelaiton(nested_relation_id) {
      let relation = this.nestedRelationshipsData.find(
        (e) => e && e._id.toString() == nested_relation_id.toString()
      );
      if (relation) {
        return relation;
      }
      return {};
    },
    getTabLabel(sub_tab, tab) {
      if (tab.templates.includes(sub_tab)) {
        let template = this.currentEntity.templates.find(
          (e) =>
            e &&
            e.templateInfo &&
            e.templateInfo._id.toString() == sub_tab.toString()
        );
        if (template) {
          return template.templateInfo.name;
        }
        return "";
      } else if (tab.relationships.includes(sub_tab)) {
        let relation = this.relationshipsData.find(
          (e) => e && e._id.toString() == sub_tab.toString()
        );
        if (relation) {
          return relation.relationship_title;
        }
        return "";
      } else if (tab.nested_relationships.includes(sub_tab)) {
        let relation = this.nestedRelationshipsData.find(
          (e) => e && e._id.toString() == sub_tab.toString()
        );
        if (relation) {
          return relation.relationship_title;
        }
        return "";
      } else {
        return sub_tab;
      }
    },
    checkButtonVisible(button) {
      if (
        this.customization &&
        this.customization.buttons &&
        this.customization.buttons.length
      ) {
        if (button == "Save & New") {
          return this.customization.buttons[2].visibility;
        } else if (button == "Duplicate") {
          return this.customization.buttons[5].visibility;
        }
      }
      return true;
    },
    emitSaveTemplate1() {
      this.$bus.$emit("save-template", false);
    },
    emitSaveTemplate2() {
      this.$bus.$emit("save-template", true);
    },
    async emitGoToEditMode() {
      if (this.templateData?.templateInfo?.isRestrictMultiple) {
        let response = await postAPICall("GET", "/cache/getCacheData", {
          cacheKey: this.$route.query.dataId,
        });
        if (response.error) {
          await postAPICall("POST", "/cache/setCacheData", {
            cacheKey: this.$route.query.dataId,
          });
        } else {
          return this.$notify.error({
            title: "Error",
            message: "Another user is already editing this record!",
          });
        }
      }

      this.$bus.$emit("go-to-edit-mode", true);
    },
    emitCloneEntityData() {
      this.$bus.$emit("clone-entity-data", true);
    },
    async refreshThePage() {
      this.entity_id = this.$route.params.entity_id;
      this.entityDataId = this.$route.query.dataId;
      this.showTemplate = false;
      this.showData = false;
      this.loading = true;
      if (!this.entityDataId) {
        this.entityDataExists = false;
        this.currentEntityData = null;
      }
      setTimeout(() => {
        this.showTemplate = true;
        this.showData = true;
        this.loading = false;
      }, 100);
    },
    async refreshThePageFromTemplateFieldsPreview() {
      if (this.isApplicationUserSide) {
        this.$router.push({
          path: `/ap/entity-execute/${this.entity_id}`,
          query: {
            layout: this.activeLayout,
            type: "s7aLF3NnAQg=",
          },
        });
      } else {
        let UrlPath = "";
        if (this.getIframeUrlInfo != null) {
          UrlPath = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
        } else {
          UrlPath = `/entity/edit/${this.entity_id}`;
        }
        this.$router.push({
          path: UrlPath,
          query: { layout: this.activeLayout },
        });
      }
      setTimeout(() => {
        this.showData = true;
        this.loading = false;
      }, 100);
    },
    async moveToFolder(folderId) {
      this.refresh = true;
      this.folder = folderId != "root" ? folderId : "";
      let query = this.getNavigationQuery(this.$route.query);
      if (this.folder) {
        query["folder"] = this.folder;
      } else {
        if (query.folder) {
          delete query.folder;
        }
      }
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
    async addPrimaryDataToEntity() {
      try {
        this.loading = true;
        this.primaryFields = [...this.getPrimaryFields];
        if (this.form && this.getRequired === this.getTotalCount) {
          this.primaryFields = [...this.getPrimaryFields];

          this.primaryFields.forEach((e) => {
            if (
              e.input_type == "ENTITY" &&
              e.entity_id &&
              this.form[e.key] &&
              this.form[e.key + "/name"]
            ) {
              e["entity_data_key"] = e.key + "/name";
            }
          });
          //setting auto increment data
          let incrementFields = this.primaryFields.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }
          await this.checkForDuplicateData();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
        this.loading = false;
      } catch (err) {
        console.log("addPrimaryDataToEntity", err);
      }
    },
    async checkForDuplicateData() {
      this.primaryDataAddLoading = true;
      this.primaryDataAddLoadingText = "Checking duplicate data...";
      let templateData = [];
      this.primaryFields.forEach((pf) => {
        let findIndex = templateData.findIndex(
          (e) => e.template_id == pf.template_id
        );
        if (findIndex > -1) {
          let existedData = templateData[findIndex];
          existedData.form = {
            ...existedData.form,
            ...{ [pf.key]: this.form[pf.key] },
          };
          templateData[findIndex] = { ...existedData };
        } else {
          templateData.push({
            template_id: pf.template_id,
            form: { [pf.key]: this.form[pf.key] },
          });
        }
      });
      let params = {
        entity_id: this.$route.params.entity_id,
        parent_entityDataId: null,
        data: templateData,
      };
      await this.$store.dispatch("templatesData/checkDuplicateData", params);
      if (this.getDuplicateDataCheck) {
        if (!this.getDuplicateDataCheck?.allowed) {
          this.$notify.warning({
            title: "Warning",
            message:
              "With this data already " +
              (this.getEntityDataById && this.getEntityDataById.name
                ? this.getEntityDataById.name + " is"
                : "data is") +
              " created.",
          });
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          return;
        }
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.createEntityData();
      } else {
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.$message({
          message: "Error while checking data",
          type: "error",
        });
      }
    },
    async createEntityData() {
      this.primaryDataAddLoading = true;
      this.primaryDataAddLoadingText = "Creating new entity data...";
      let data = {
        primaryFields: this.getEntityDataById.primaryFields,
        formData: this.form,
        templates: this.getEntityDataById.templates,
        entity_id: this.$route.params.entity_id,
      };
      await this.$store.dispatch("templatesData/createEntityPrimaryData", data);
      if (
        this.getPrimaryEntityDataStatus.message ===
        "Primary fields data required"
      ) {
        this.primaryDataAddLoading = false;
        this.primaryDataAddLoadingText = "";
        this.$notify.error({
          title: "Error",
          message:
            "This form contains unique field.Cannot not create duplicate value",
        });
      } else {
        if (this.getPrimaryEntityDataStatus) {
          this.addPrimaryDataDialog = false;
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          this.$notify.success({
            title: "Success",
            message: "Primary Data saved successfully",
          });
          if (this.getNewEntityData) {
            this.entityDataId = this.getNewEntityData._id;
            await this.checkAndSetRelationship();
            let query = this.getNavigationQuery(this.$route.query);
            query["dataId"] = this.entityDataId;
            this.showTemplate = true;
            if (
              this.getCurrentEntityRelationships &&
              this.getCurrentEntityRelationships.length
            ) {
              await this.setRelationshipDetails();
            }
            this.$router.push({
              name: this.$route.name,
              query: query,
            });
            // window.location.reload();
            await this.getNecessaryInfo();
          }
        } else {
          this.primaryDataAddLoading = false;
          this.primaryDataAddLoadingText = "";
          this.$notify.error({
            title: "Error",
            message: "Error at saving data",
          });
        }
      }
    },
    async checkAndSetRelationship() {
      if (
        this.$route.query.menu_id &&
        this.getEntityMenu &&
        this.getEntityMenu.menu_list
      ) {
        let currentMenu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            currentMenu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                currentMenu = el;
              }
            });
          }
        });
        if (currentMenu?.relational_entity == this.$route.params.entity_id) {
          let relationshipsData = [...(this.getEntityRelationships || [])];

          relationshipsData = relationshipsData.map((e) => {
            if (e.parent_entity_id == this.$route.params.entity_id) {
              e.owner_type = "PARENT";
              e.relationship_title = e.child_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.child_entity_id;
            } else {
              e.owner_type = "CHILD";
              e.relationship_title = e.parent_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.parent_entity_id;
            }
            e.parent_entity_data_id = this.entityDataId;
            return e;
          });

          relationshipsData = relationshipsData.filter((e) => {
            if (e.owner_type == "CHILD" && !e.is_bidirectional) {
              return false;
            } else {
              return true;
            }
          });
          let currentActiveWorkspace =
            this.getAuthenticatedUser.contact_types.find((e) => {
              let id =
                e.contact_type && e.contact_type._id
                  ? e.contact_type._id
                  : e.contact_type;
              if (id && id == this.getActiveContactType?.contact_type?._id) {
                return true;
              }
            });
          if (
            currentActiveWorkspace &&
            currentActiveWorkspace.contact_type &&
            currentActiveWorkspace.account_data_id
          ) {
            let currentRelationship = relationshipsData.find((e) => {
              let id = currentActiveWorkspace.contact_type._id
                ? currentActiveWorkspace.contact_type._id
                : currentActiveWorkspace.contact_type;
              if (id) {
                if (
                  (id == e.child_entity_id &&
                    e.parent_entity_id == this.$route.params.entity_id) ||
                  (this.$route.params.entity_id == e.child_entity_id &&
                    e.parent_entity_id == id)
                ) {
                  return true;
                }
              }
            });
            if (currentRelationship) {
              if (currentRelationship.representation == "TAB") {
                let data;
                if (currentRelationship.owner_type == "PARENT") {
                  data = {
                    parent_entity_id: currentRelationship.parent_entity_id,
                    child_entity_id: currentRelationship.child_entity_id,
                    parent_entity_data_id:
                      currentRelationship.parent_entity_data_id,
                    child_entity_data_ids: [
                      currentActiveWorkspace.account_data_id,
                    ],
                  };
                } else {
                  if (currentRelationship.parent_relation_type == "MANY") {
                    data = {
                      parent_entity_id: currentRelationship.child_entity_id,
                      child_entity_id: currentRelationship.parent_entity_id,
                      parent_entity_data_id:
                        currentRelationship.parent_entity_data_id,
                      child_entity_data_ids: [
                        currentActiveWorkspace.account_data_id,
                      ],
                    };
                  } else {
                    data = {
                      parent_entity_id: currentRelationship.parent_entity_id,
                      child_entity_id: currentRelationship.child_entity_id,
                      parent_entity_data_id:
                        currentActiveWorkspace.account_data_id,
                      child_entity_data_ids: [
                        currentRelationship.parent_entity_data_id,
                      ],
                    };
                  }
                }
                await this.$store.dispatch(
                  "entityRelationships/assignEntityRelationshipData",
                  data
                );
              } else {
                let allFields = this.getAllFieldsFormEntity(
                  this.getEntityDataById
                );
                let toAssignRelationshipsData = this.prepareRelationshipData(
                  allFields,
                  relationshipsData,
                  this.entityDataId,
                  currentActiveWorkspace &&
                    currentActiveWorkspace.account_data_id
                    ? currentActiveWorkspace.account_data_id
                    : null
                );
                if (toAssignRelationshipsData.length) {
                  await this.$store.dispatch(
                    "entityRelationships/assignFieldsEntityRelationshipData",
                    toAssignRelationshipsData
                  );
                }
              }
            }
          }
        }
      }
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.getTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    async fetchNewEntityData() {
      // this.loading = true;
      if (this.entityDataId) {
        await this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
          id: this.entityDataId,
        });
        this.$store.commit("entitiesData/setTempEntityData", null, {
          root: true,
        });
      }
      if (this.getEntityPrimaryData?._id) {
        this.currentEntityData = this.getEntityPrimaryData;
        if (this.getEntityPrimaryData.avatar) {
          this.profilePicture = this.getEntityPrimaryData.avatar;
        }
      }
      // this.loading = false;
    },
    selectField(data, template_id) {
      let field = { ...data, ...{ template_id: template_id } };
      let existed = this.checkfieldVisible(field, template_id);
      let key;
      if (field.key.includes("#")) {
        key = field.key.split("#")[1];
      } else {
        key = field.key;
      }
      if (existed && this.showFields?.fields) {
        let index = this.showFields.fields.findIndex((e) => {
          if (e?.key) {
            let tempKey = e.key;
            if (e.key.includes("#")) {
              tempKey = e.key.split("#")[1];
            }
            if (tempKey == key && e.template_id == template_id) {
              return true;
            }
          }
        });
        if (index > -1) {
          this.showFields.fields.splice(index, 1);
        } else {
          field = { ...field, ...{ key: template_id + "#" + key } };
          this.showFields.fields.push(field);
        }
      } else {
        field = { ...field, ...{ key: template_id + "#" + key } };
        this.showFields.fields.push(field);
      }
    },
    async checkForFilterUpdate() {
      this.entityViewModal = false;
      // if (this.currentFilter) {
      //   await this.$store.dispatch("filters/updateFilter", {
      //     id: this.currentFilter,
      //     params: {
      //       columns_settings: this.filterColumList,
      //     },
      //   });
      // } else {
      if (this.isApplicationUserSide) {
        let data = this.getEntityShowFields || {};
        if (data) {
          data[this.getEntityDataById._id] = { ...this.showFields };
        }
        await this.$store.commit("entities/setEntityShowFields", data, {
          root: true,
        });
      } else {
        await this.$store.dispatch("entities/updateEntityById", {
          settings: this.getEntityDataById.settings,
          name: this.getEntityDataById.name,
          description: this.getEntityDataById.description,
          entity_type: this.getEntityDataById.entity_type,
          templates: this.getEntityDataById.templates,
          id: this.getEntityDataById._id,
          primaryFields: this.getEntityDataById.primaryFields,
          views_configuration: this.showFields,
        });
      }
      // }
    },
    checkfieldVisible(data, template_id) {
      if (
        this.showFields &&
        this.showFields.fields &&
        this.showFields.fields.length &&
        data?.key
      ) {
        let key = data.key;
        if (data.key.includes("#")) {
          key = data.key.split("#")[1];
        }
        let found = this.showFields.fields.find((el) => {
          let savedkey = el.key;
          if (el.key && el.key.includes("#")) {
            savedkey = el.key.split("#")[1];
          }
          if (el?.template_id == template_id && savedkey == key) {
            return true;
          }
        });
        return found ? true : false;
      }
      return false;
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity?.templates || []).forEach((temp) => {
        if (temp?.templateInfo?.sections) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (key && !key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = key;
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (key && !key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = key;
                return el;
              }),
            ];
          }
        }
      });
      if (
        types &&
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
    openViewConfigPage() {
      this.entityViewModal = true;
    },
    async backToEntityInfoList() {
      if (this.$route?.query?.addNew == "true") {
        if (this.isApplicationUserSide) {
          this.$router.push({ path: "/ap/dashboard" });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      } else if (this.$route.query?.close_tab) {
        window.close();
      } else {
        const returnTo = this.$route.query.returnTo;
        if (returnTo) {
          this.$router.push(returnTo);
        } else {
          let tempQuery = {};
          if (
            this.$route.query?.fromCustom ||
            this.$route.query?.fromEntityView
          ) {
            this.$router.go(-1);
          } else {
            const entityDataId=this.$route.query.dataId
            if (this.$route?.query?.filter) {
              tempQuery["filter"] = this.$route.query.filter;
            }
            if (this.$route?.query?.viewType) {
              tempQuery["viewType"] = this.$route.query.viewType;
            }
            if (this.$route?.query?.routeType) {
              tempQuery["routeType"] = this.$route.query.routeType;
            }
            if (this.$route?.query?.redirect) {
              tempQuery["redirect"] = this.$route.query.redirect;
            }
            if (this.$route?.query?.customization) {
              tempQuery["customization"] = this.$route.query.customization;
            }
            if (this.isApplicationUserSide) {
              this.$router.push({
                path:
                  "/ap/entity/" +
                  this.entity_id +
                  "/" +
                  this.$route.query.menu_id,
                query: {
                  ...tempQuery,
                  page: this.$route.query.page,
                  pageSize: this.$route.query.pageSize,
                  order_by: this.$route.query.order_by,
                  order_type: this.$route.query.order_type,
                },
              });
            } else {
              let UrlPath = "";
              if (this.getIframeUrlInfo != null) {
                UrlPath = `/if/${this.getIframeUrlInfo._id}/entity/${this.entity_id}`;
              } else {
                UrlPath = "/entity/" + this.entity_id;
              }
              this.$router.push({
                path: UrlPath,
                query: {
                  ...tempQuery,
                  page: this.$route.query.page,
                  pageSize: this.$route.query.pageSize,
                  order_by: this.$route.query.order_by,
                  order_type: this.$route.query.order_type,
                },
              });
            }
            if (this.templateData?.templateInfo?.isRestrictMultiple) {
              await postAPICall("DELETE", `/cache/deleteCacheData/${entityDataId}`)
          }}
        }
      }
    },
    async switchEntityView(view) {
      if (view != this.activeLayout) {
        this.activeLayout = view;
        let query = this.getNavigationQuery(this.$route.query);
        query["layout"] = view;
        if (this.$route?.query?.relationship_id) {
          query["relationship_id"] = this.$route.query.relationship_id;
        }
        if (this.isApplicationUserSide && this.isEdit) {
          query["type"] = "s7aLF3NnAQg=";
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      }
    },
    async changeTab(tab, switchTab = false) {
      if (!this.isModified || switchTab || this.isCancelTriggered) {
        this.saveAndSwitch = false;
        if (!this.saveAndSwitch) {
          this.prevTab = this.previousSavingTab;
          this.previousSavingTab = null;
          this.previousSavingTab = this.prevTab;
        }
        if (switchTab) {
          this.isTabClickedWithoutSaving = false;
        }
        /* Vibhakar Vaddi  changes related to Tab switching  start*/
        this.showData = false;
        let activeTab;
        if (
          this.customization &&
          this.customization.tab_groups &&
          this.customization.tab_groups.length
        ) {
          activeTab = this.customization.tab_groups.find(
            (e) => e && e.label.toString() == this.currrentTab.toString()
          );
          if (activeTab && activeTab.tabs.length > 1 && tab == "tab") {
            this.currentSubTab = activeTab.tabs[0].feature_id + "#" + 0;
          }
          if (activeTab && activeTab.tabs.length > 1) {
            this.currentSubTabFeatureType = activeTab.tabs.find(
              (e) => e.feature_id == this.currentSubTab.split("#")[0]
            ).feature_type;
          }
        }

        /* Vibhakar Vaddi  changes related to Tab switching  end*/
        let type = this.isApplicationUserSide ? this.$route.query.type : "";
        let query = this.getNavigationQuery(this.$route.query);
        query["activeTab"] = this.currrentTab;
        if (this.isApplicationUserSide) {
          query["type"] = type;
        }
        if (
          this.defaultTabs.indexOf(this.currrentTab) == -1 &&
          (this.customization == undefined ||
            this.customization.tab_groups.length == 0)
        ) {
          query["relationship_id"] = this.currrentTab;
        }
        if (this.$route.query?.board_field) {
          query["board_field"] = this.$route.query.board_field;
        }
        if (this.$route.query?.index) {
          query["index"] = this.$route.query.index;
        }
        query["tab"] = this.currrentTab;
        query["subTab"] = this.currentSubTab.split("#")[0];
        query["tabOrd"] = this.currentSubTab.split("#")[1];
        query["subType"] = this.currentSubTabFeatureType;
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
        setTimeout(() => {
          // this.loading = false;
          this.showData = true;
        }, 100);
        await this.fetchNewEntityData();
        // this.loading = true;
        //this.Mounted = false;
        this.showTabChangeDialog = false;
      }
    },
    getFieldValue(field, fieldKey) {
      if (this.currentEntityData?.templates_data) {
        if (field == "fields" && fieldKey?.key) {
          let [tempId, key] = fieldKey.key.split("#");
          if (!tempId) {
            tempId = fieldKey.template_id;
          }
          let templateData = this.currentEntityData.templates_data.find(
            (e) => e.template_id == tempId
          );
          if (templateData?.template_data_id?.template_data?.[key]) {
            const keyWithName = key + "/name";
            if( fieldKey.input_type == 'PHONE_COUNTRY_CODE'){
            console.log(templateData?.template_data_id?.template_data?.phone_number_code?.countryCallingCode,"temaplte data.....")
            return `+${templateData?.template_data_id?.template_data?.phone_number_code?.countryCallingCode} ${templateData?.template_data_id?.template_data?.[key]}`
          }
            if (templateData?.template_data_id?.template_data?.[keyWithName]) {
              return templateData?.template_data_id?.template_data?.[
                keyWithName
              ];
            } else if (
              templateData?.template_data_id?.template_data?.[key]?.name
            ) {
              return templateData?.template_data_id?.template_data?.[key]?.name;
            }
            if (fieldKey.input_type == "LIST") {
              let value = fieldKey?.list_data.find(
                (e) =>
                  e.value ==
                  templateData?.template_data_id?.template_data?.[key]
              );
              if (value && value.name) {
                return value.name;
              } else {
                return templateData?.template_data_id?.template_data?.[key];
              }
            }
            return templateData?.template_data_id?.template_data?.[key];
          }
          return "";
        } else {
          if (
            this.currentEntityData?.templates_data &&
            this.showFields[field] &&
            this.showFields[field].includes("#")
          ) {
            let thisField = this.allEntityFields.find(
              (fd) => fd.key == this.showFields[field]
            );
            let [tempId, key] = this.showFields[field].split("#");
            let templateData = this.currentEntityData.templates_data.find(
              (e) => e.template_id == tempId
            );
            if (templateData?.template_data_id?.template_data?.[key]) {
              if (
                templateData?.template_data_id?.template_data?.[key + "/name"]
              ) {
                return templateData?.template_data_id?.template_data?.[
                  key + "/name"
                ];
              } 
              else if(thisField?.input_type == "PHONE_COUNTRY_CODE"){
                 return `+${templateData?.template_data_id?.template_data?.phone_number_code?.countryCallingCode} ${templateData?.template_data_id?.template_data?.[key]}`
              }
              else if (thisField?.input_type == "DATE") {
                return moment(
                  templateData?.template_data_id?.template_data?.[key]
                ).format(this.getDefaultDateFormat);
              }
              return templateData?.template_data_id?.template_data?.[key];
            }
            return "";
          } else {
            if (this.currentEntity.entity_type == "INDIVIDUAL") {
              if (
                field == "profile" &&
                this.currentEntityData?.contact_id?.avatar &&
                this.showFields[field] == "defaultpic"
              ) {
                return this.currentEntityData.contact_id.avatar;
              }
            }
            return "";
          }
        }
      }
    },
    async fetchEntityRelationships() {
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      //Fetch application user permissions
      if (
        this.getActiveContactType?.contact_type?._id &&
        this.isApplicationUserSide
      ) {
        await Promise.all([
          // this.$store.dispatch(
          //   "applicationUsersPermission/fetchPermissionsEntityById",
          //   { id: this.getActiveContactType.contact_type._id }
          // ),
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: this.entity_id,
          }),
        ]);
      } else {
        await this.$store.dispatch(
          "entityRelationships/fetchEntityRelationships",
          { entity_id: this.entity_id }
        );
      }
      if (this.getEntityRelationships && this.getEntityRelationships.length) {
        this.setRelationshipDetails();
      }

      this.loading = false;
    },
    async fetchEntityNestedRelationships() {
      this.loading = true;
      this.loadingText = "Fetching  Nested Entity relationships...";
      await this.$store.dispatch(
        "entityRelationships/fetchEntityNestedRelationships",
        { entity_id: this.entity_id, fetchAll: true }
      );
      this.nestedRelationshipsData = [
        ...(this.getEntityNestedRelationships || []),
      ];
      // we have to add type owner of relationship
      this.nestedRelationshipsData = this.nestedRelationshipsData.map((e) => {
        // e.owner_type = "PARENT";
        e.relationship_title =
          e.parent_relationship_details.relationship_title +
          "/" +
          e.relationship_title;
        // e.relationship_slug =
        //   e.parent_relationship_details.relationship_slug +
        //   "-" +
        //   makeSlug(e.relationship_title);
        //e.relationship_id = e.child_entity_id;
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityNestedRelationships",
        this.nestedRelationshipsData
      );
      this.loading = false;
    },
    setRelationshipDetails() {
      this.relationshipsData = [...(this.getEntityRelationships || [])];

      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityRelationships",
        this.relationshipsData
      );
      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
      //have to check application user permisson entities
      if (this.isApplicationUserSide) {
        this.relationshipsData = this.relationshipsData.filter((e) => {
          if (
            this.getEntityPermission?.other_entities &&
            this.getEntityPermission.other_entities[e.relationship_id] &&
            this.getEntityPermission.other_entities[
              e.relationship_id
            ].permissions.indexOf("VIEW") != -1
          ) {
            return true;
          }
        });
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.showError = true;
      this.errorMessage = "";
      let promises = [
        fetchEntityById(this.entity_id),
        // this.$store.dispatch("entities/fetchEntityById", {
        //   entity_id: this.entity_id,
        // }),
      ];
      if (this.customization_id !== "") {
        promises.push(
          // this.$store.dispatch(
          //   "entityCustomization/fetchCustomizationById",
          //   this.customization_id
          // )
          fetchAllEntityCustomizationsById(this.customization_id)
        );
      } else {
        promises.push(
          // this.$store.dispatch(
          //   "entityCustomization/fetchEntityActiveCustomization",
          //   this.entity_id
          // )
          fetchActiveEntityCustomizationsByEntity(this.entity_id)
        );
      }
      if (this.entityDataId) {
        if (this.getTempEntityData?._id === this.entityDataId) {
          this.$store.commit(
            "entities/setEntityPrimaryData",
            this.getTempEntityData,
            { root: true }
          );
        } else {
          promises.push(
            this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
              id: this.entityDataId,
            })
          );
          this.$store.commit("entitiesData/setTempEntityData", null, {
            root: true,
          });
        }
      }
      if (this.getActiveContactType?.contact_type?._id) {
        // promises.push(
        //   this.$store.dispatch(
        //     "applicationUsersPermission/fetchPermissionsEntityById",
        //     { id: this.getActiveContactType.contact_type._id }
        //   )
        // );
        // promises.push(
        //   this.$store.dispatch(
        //     "menuManagementV2/fetchMenuByEntity",
        //     this.getActiveContactType.contact_type._id
        //   )
        // );
      }
      const [getEntityDataById, customization] = await Promise.all(promises);
      this.getEntityDataById = { ...getEntityDataById };
      if (customization) {
        this.customization = { ...customization };
      }
      let filterIds = [];
      this.customization?.tab_groups.forEach((tabGroup) => {
        tabGroup?.tabs.forEach((tab) => {
          if (tab?.selected_filter_when_view) {
            filterIds.push(tab.selected_filter_when_view);
          }
        });
      });
      if (filterIds?.length) {
        let params = {
          entity_id: this.entity_id,
          entity_data_id: this.entityDataId,
          filterIds: filterIds,
        };
        //API checks the filterIds whether the entity data satisfies the filter condition or not
        const response = await postAPICall(
          "POST",
          "/entity/filtered-menu",
          params
        );
        if (response?.data) {
          this.customization?.tab_groups.forEach((tabGroup) => {
            tabGroup.tabs = tabGroup?.tabs.filter((tab) => {
              if (tab?.selected_filter_when_view) {
                if (response?.data[tab?.selected_filter_when_view] == false) {
                  return false;
                }
              }
              return true;
            });
          });
          this.customization.tab_groups = this.customization?.tab_groups.filter(
            (group) => {
              return group?.tabs && group?.tabs?.length > 0;
            }
          );
        }
      }
      if (this.getEntityDataById) {
        this.currentEntity = JSON.parse(JSON.stringify(this.getEntityDataById));
        if (this.currentEntity && this.currentEntity.templates) {
          this.generateEntityFields();
          if (
            this.isApplicationUserSide &&
            this.getEntityShowFields &&
            this.getEntityShowFields[this.entity_id]
          ) {
            this.showFields = { ...this.getEntityShowFields[this.entity_id] };
          } else if (this.currentEntity?.views_configuration) {
            this.showFields = {
              ...this.currentEntity.views_configuration,
              profile : "defaultpic"
            };
          } else {
            await this.generateShowFields(this.currentEntity);
          }
          if (
            typeof this.showFields.fields == "string" ||
            this.showFields.fields == "" ||
            !this.showFields.fields
          ) {
            this.showFields.fields = [];
          }
          if (this.showFields) {
            this.checkFieldsExists();
          }
        }
        if (this.customization?.tab_groups?.length) {
          if (this.isApplicationUserSide) {
            let toBeDeletedIndices = [];
            this.customization?.tab_groups.map((tab, index) => {
              if (tab.tabs.length == 1) {
                // if (tab.tabs[0].feature_type == "RELATIONSHIP") {
                //   let relation = this.relationshipsData.filter(
                //     (rel) => rel._id == tab.tabs[0].feature_id
                //   );
                //   if (!relation.length) {
                //     toBeDeletedIndices.push(index);
                //   }
                // } else
                if (tab.tabs[0].feature_type == "TEMPLATE") {
                  if (!this.checkTemplateAccess(tab.tabs[0].feature_id)) {
                    toBeDeletedIndices.push(index);
                  }
                } else if (
                  tab.tabs[0].feature_type == "OTHERS" &&
                  tab.tabs[0].feature_id == "eSignatures"
                ) {
                  toBeDeletedIndices.push(index);
                }
              } else {
                let subDeletedIndices = [];
                tab.tabs.map((sub, sub_index) => {
                  // if (sub.feature_type == "RELATIONSHIP") {
                  //   let relation = this.relationshipsData.filter(
                  //     (rel) => rel._id == sub.feature_id
                  //   );
                  //   if (!relation.length) {
                  //     subDeletedIndices.push(sub_index);
                  //   }
                  // } else
                  if (sub.feature_type == "TEMPLATE") {
                    if (!this.checkTemplateAccess(sub.feature_id)) {
                      subDeletedIndices.push(sub_index);
                    }
                  } else if (
                    sub.feature_type == "OTHERS" &&
                    sub.feature_id == "eSignatures"
                  ) {
                    toBeDeletedIndices.push(index);
                  }
                });
                if (subDeletedIndices && subDeletedIndices.length) {
                  subDeletedIndices.sort((a, b) => b - a);
                  subDeletedIndices.map((i) =>
                    this.customization.tab_groups[index].tabs.splice(i, 1)
                  );
                }
                if (!this.customization.tab_groups[index].tabs.length) {
                  toBeDeletedIndices.push(index);
                }
              }
            });
            if (toBeDeletedIndices && toBeDeletedIndices.length) {
              toBeDeletedIndices.sort((a, b) => b - a);
              toBeDeletedIndices.map((i) =>
                this.customization.tab_groups.splice(i, 1)
              );
            }
          }
          if (this.customization.tab_groups.length) {
            if (this.currrentTab == "") {
              this.currrentTab = this.customization.tab_groups[0].label;
            }
            if (
              this.customization &&
              this.customization.tab_groups[0].tabs.length
            ) {
              if (this.currentSubTab == "") {
                this.currentSubTab =
                  this.customization.tab_groups[0].tabs[0].feature_id + "#" + 0;
              }
              if (this.currentSubTabFeatureType == "") {
                this.currentSubTabFeatureType =
                  this.customization.tab_groups[0].tabs[0].feature_type;
              }
            }
            this.showNestedRelationshipCheckbox = false;
          }
        } else if (this.currrentTab == "") {
          this.currrentTab = "entities_data";
        }
        if (this.customization != undefined) {
          if (
            this.customization.active_layout == "SPLIT_VIEW" &&
            !this.$route.query.dataId
          ) {
            this.activeLayout = this.$route.query.layout;
          } else {
            this.activeLayout = this.customization.active_layout;
          }
          this.$route.query.layout = this.activeLayout;
        }
        await this.fetchAndSetRelationships();
        this.entityAllFields = [];
        this.getEntityDataById.templates.forEach((e) => {
          if (e && e?.templateInfo?.sections && !e.is_repeatable) {
            this.entityAllFields = [
              ...this.entityAllFields,
              ...e.templateInfo.sections[0].fields.map((fl) => {
                if (fl?.key && fl.key.includes("#")) {
                  fl.key = fl.key.split("#")[1];
                }
                fl.template_id = e.template_id;
                return fl;
              }),
            ];
          }
        });
        if (this.$route.query?.filter) {
          // await this.$store.dispatch(
          //   "filters/getFilterById",
          //   this.$route.query?.filter
          // );
          this.getSingleFilterData = await fetchFilterById(
            this.$route.query.filter
          );
        }
        if (this.entityDataId) {
          if (this.getEntityPrimaryData?._id) {
            this.currentEntityData = this.getEntityPrimaryData;
            this.createdAt = this.formatDateCreateDate(
              this.currentEntityData?.created_at
            );
            this.updatedAt = this.formatDateCreateDate(
              this.currentEntityData?.updated_at
            );
          }
          if (this.currentEntity?.edit_filter) {
            await this.checkEditMode();
          }
        }
        // else {
        // if (this.getEntityDataById?.entity_type !== "INDIVIDUAL") {
        //   this.addPrimaryDataDialog = true;
        // }
        //   this.showTemplate = true;
        //   this.loading = false;
        //   this.showError = false;
        // }

        this.showTemplate = true;
        this.loading = false;
        this.showError = false;
      } else {
        this.showError = true;
        this.errorMessage = this.getEntityDataByIdError || "Forbidden access";
      }
      this.loading = false;
    },
    async fetchAndSetRelationships() {
      await Promise.all([
        this.fetchEntityRelationships(),
        this.fetchEntityNestedRelationships(),
      ]);
      if (this.customization?.tab_groups?.length) {
        if (this.isApplicationUserSide) {
          let toBeDeletedIndices = [];
          this.customization?.tab_groups.map((tab, index) => {
            if (tab.tabs.length == 1) {
              if (tab.tabs[0].feature_type == "RELATIONSHIP") {
                let relation = this.relationshipsData.filter(
                  (rel) => rel._id == tab.tabs[0].feature_id
                );
                if (!relation.length) {
                  toBeDeletedIndices.push(index);
                }
              }
            } else {
              let subDeletedIndices = [];
              tab.tabs.map((sub, sub_index) => {
                if (sub.feature_type == "RELATIONSHIP") {
                  let relation = this.relationshipsData.filter(
                    (rel) => rel._id == sub.feature_id
                  );
                  if (!relation.length) {
                    subDeletedIndices.push(sub_index);
                  }
                }
              });
              if (subDeletedIndices && subDeletedIndices.length) {
                subDeletedIndices.sort((a, b) => b - a);
                subDeletedIndices.map((i) =>
                  this.customization.tab_groups[index].tabs.splice(i, 1)
                );
              }
              if (!this.customization.tab_groups[index].tabs.length) {
                toBeDeletedIndices.push(index);
              }
            }
          });
          if (toBeDeletedIndices && toBeDeletedIndices.length) {
            toBeDeletedIndices.sort((a, b) => b - a);
            toBeDeletedIndices.map((i) =>
              this.customization.tab_groups.splice(i, 1)
            );
          }
        }
        if (this.customization.tab_groups.length) {
          if (this.currrentTab == "") {
            this.currrentTab = this.customization.tab_groups[0].label;
          }
          if (
            this.customization &&
            this.customization.tab_groups[0].tabs.length
          ) {
            if (this.currentSubTab == "") {
              this.currentSubTab =
                this.customization.tab_groups[0].tabs[0].feature_id + "#" + 0;
            }
            if (this.currentSubTabFeatureType == "") {
              this.currentSubTabFeatureType =
                this.customization.tab_groups[0].tabs[0].feature_type;
            }
          }
          this.showNestedRelationshipCheckbox = false;
        }
      }
    },
    async checkEditMode() {
      await this.$store.dispatch("entities/checkEntityDataByFilters", {
        entityDataId: this.entityDataId,
        entityId: this.currentEntity._id,
        filterId: this.currentEntity.edit_filter,
      });
      if (this.getEntityDataExists) {
        this.entityDataExists = true;
      }
    },
    checkFieldsExists() {
      this.showFields.fields = (this.showFields.fields || []).filter(
        (e) =>
          this.eliminateEntityFields(e.key) &&
          this.contentFields.indexOf(e.inputType) == -1
      );
      if (
        this.showFields.title &&
        !this.eliminateEntityFields(this.showFields.title)
      ) {
        this.showFields.title = "";
      }
      if (
        this.showFields.description &&
        !this.eliminateEntityFields(this.showFields.description)
      ) {
        this.showFields.description = "";
      }
    },
    eliminateEntityFields(field) {
      return this.selectedEntityFields.find((e) => e == field) ? true : false;
    },
    generateEntityFields() {
      this.selectedEntityFields = [];
      this.allEntityFields = [];
      if (this.currentEntity && this.currentEntity.templates) {
        this.currentEntity.templates.forEach((e) => {
          if (e && e.templateInfo && !e.is_repeatable) {
            this.allEntityFields = [
              ...this.allEntityFields,
              ...this.getTemplateFields(e.templateInfo),
            ];
          }
        });
      }
      this.selectedEntityFields = this.allEntityFields.flatMap((e) => e.key);
    },
    generateShowFields(entity) {
      if (entity?.templates) {
        if (entity?.entity_type == "INDIVIDUAL") {
          let standardTemp = entity?.templates.find(
            (e) => e && e?.templateInfo?.type == "STANDARD"
          );
          this.showFields.profile = "defaultpic";
          if (standardTemp?.template_id) {
            this.showFields.title = standardTemp.template_id + "#" + "name";
            this.showFields.description =
              standardTemp.template_id + "#" + "title";
          }
        } else {
          entity?.templates.forEach((temp) => {
            if (temp?.templateInfo && !temp?.is_repeatable) {
              if (!this.showFields.profile) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "IMAGE");
                if (field?.key) {
                  this.showFields.profile = field.key;
                }
              }
              if (!this.showFields.description) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "MULTI_LINE_TEXT");
                if (field?.key) {
                  this.showFields.description = field.key;
                }
              }
            }
          });
          if (
            !this.showFields.title &&
            entity.primaryFields &&
            entity.primaryFields[0]
          ) {
            this.showFields.title =
              entity.primaryFields[0].template_id +
              "#" +
              entity.primaryFields[0].key;
          }
        }
        entity?.templates.forEach((temp, i) => {
          if (temp?.templateInfo && i == 0) {
            let fields = (
              this.getTemplateFields(temp.templateInfo) || []
            ).filter((e) => this.contentFields.indexOf(e.input_type) === -1);
            if (fields.length) {
              fields.forEach((e) => {
                e.template_id = temp.template_id;
                if (
                  !this.showFields.fields ||
                  (this.showFields.fields.length < 7 &&
                    e.inputType != "DATA_TABLE")
                ) {
                  this.showFields.fields.push(e);
                }
              });
            }
          }
        });
      }
    },
    getTemplateFields(temaplate) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(temaplate));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    getCurrentStep(template_id) {
      let template = this.currentEntity.templates.find(
        (e) => e && e.templateInfo._id.toString() == template_id.toString()
      );
      if (template) {
        return { ...template, has_next: false };
      }
      return {};
    },
    async goToNextTab(entityDataId) {
      // if (this.nextTab) {
      //   let query = this.getNavigationQuery(this.$route.query);
      //   if (entityDataId) {
      //     query["dataId"] = entityDataId;
      //   }
      //   query["currentSubTab"] = this.nextTab.split("#")[0];
      //   query["tabOrd"] = this.nextTab.split("#")[1];
      //   this.$router.push({
      //     name: this.$route.name,
      //     query: query,
      //   });
      //   return;
      // }
      this.showData = false;
      let tabIndex = this.customization.tab_groups.findIndex(
        (e) => this.currrentTab.toString() == e.label.toString()
      );
      let subTabIndex = this.customization.tab_groups[tabIndex].tabs.findIndex(
        (e) =>
          e.feature_id.toString() == this.currentSubTab.toString().split("#")[0]
      );
      if (
        subTabIndex <
        this.customization.tab_groups[tabIndex].tabs.length - 1
      ) {
        this.currentSubTab =
          this.customization.tab_groups[tabIndex].tabs[subTabIndex + 1]
            .feature_id +
          "#" +
          (parseInt(this.currentSubTab.split("#")[1]) + 1);
        this.currentSubTabFeatureType =
          this.customization.tab_groups[tabIndex].tabs[
            subTabIndex + 1
          ].feature_type;
        let query = this.getNavigationQuery(this.$route.query);
        if (entityDataId) {
          query["dataId"] = entityDataId;
        }
        if (
          this.customization.tab_groups[tabIndex].tabs[subTabIndex + 1]
            .feature_type == "RELATIONSHIP" ||
          this.customization.tab_groups[tabIndex].tabs[subTabIndex + 1]
            .feature_type == "NESTED_RELATIONSHIP"
        ) {
          query["relationship_id"] = this.currentSubTab.split("#")[0];
        }
        if (this.isApplicationUserSide && this.$route.query?.type) {
          query["type"] = this.$route.query.type;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      } else {
        if (tabIndex < this.customization.tab_groups.length - 1) {
          this.currrentTab = this.customization.tab_groups[tabIndex + 1].label;
          this.currentSubTab =
            this.customization.tab_groups[tabIndex + 1].tabs[0].feature_id +
            "#" +
            0;
          this.currentSubTabFeatureType =
            this.customization.tab_groups[tabIndex + 1].tabs[0].feature_type;
          let query = this.getNavigationQuery(this.$route.query);
          if (
            this.customization.tab_groups[tabIndex + 1].tabs[0].feature_type ==
              "RELATIONSHIP" ||
            this.customization.tab_groups[tabIndex + 1].tabs[0].feature_type ==
              "NESTED_RELATIONSHIP"
          ) {
            query["relationship_id"] = this.currentSubTab.split("#")[0];
          }
          if (entityDataId) {
            query["dataId"] = entityDataId;
          }
          if (this.isApplicationUserSide && this.$route.query?.type) {
            query["type"] = this.$route.query.type;
          }
          this.$router.push({
            name: this.$route.name,
            query: query,
          });
        } else {
          let menuItem = null;
          if (this.$route?.query?.redirect) {
            menuItem = this.getMenuItem(this.$route.query.redirect);
          }
          if (this.isApplicationUserSide) {
            if (menuItem != null) {
              this.goToMenuItem(
                menuItem,
                menuItem.type == "Child" ? true : false
              );
            } else if (this.$route?.query?.addNew == "redirect") {
              this.$router.push({ path: "/ap/dashboard" });
            } else {
              this.$router.push(
                `/ap/entity/${this.currentEntity._id}/${this.$route.query.menu_id}`
              );
            }
          } else {
            if (menuItem != null) {
              this.goToMenuItem(
                menuItem,
                menuItem.type == "Child" ? true : false
              );
            } else if (this.$route?.query?.addNew == "redirect") {
              this.$router.push({ path: "/dashboard" });
            } else {
              let UrlPath = "";
              if (this.getIframeUrlInfo != null) {
                UrlPath = `/if/${this.getIframeUrlInfo._id}/entity/${this.currentEntity._id}`;
              } else {
                UrlPath = "/entity/" + this.currentEntity._id;
              }
              this.$router.push({
                path: UrlPath,
              });
            }
          }
        }
      }
      if (entityDataId) {
        this.entityDataId = entityDataId;
      }
      await this.fetchNewEntityData();
      this.showData = true;
    },
    async openSendNotification() {
      this.selectedNotifyEntity = this.currentEntityData;
      this.sendNotificationDialog = true;
    },
    async openWASendNotification() {
      this.selectedNotifyEntity = this.currentEntityData;
      this.sendWANotificationDialog = true;
    },
    async closeSendNotificationDialog(params) {
      this.emailConfigLoading = true;
      await this.$store.dispatch(
        "entities/sendNotificationToEntityDatausers",
        params
      );
      if (this.getSentEntityDataNotificationStatus) {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Success",
          message: "Sending mail(s) initiated",
          type: "success",
        });
      } else {
        this.emailConfigLoading = false;
        this.$notify({
          title: "Error",
          message:
            this.getSentEntityNotificationStatusErrors ||
            "Error while sending mails",
          type: "error",
        });
      }
      this.paramsData = {};
      this.sendNotificationDialog = false;
      this.sendWANotificationDialog = false;
    },

    initializeCamera(reset) {
      if (this.cameraStream && !reset) return;

      const constraints = {
        video: {
          facingMode: this.facingMode,
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          const video = this.$refs.video;
          video.srcObject = stream;
          this.cameraStream = stream;
        })
        .catch((error) => {
          console.log("initializeCamera", error);
        });
    },
    takePicture() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      // Set canvas dimensions to match the video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the video frame onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert canvas content to base64 data URL
      const dataUrl = canvas.toDataURL("image/jpeg");

      // Set the captured image
      this.capturedImage = dataUrl;
      this.files = {
        type: "image/jpeg",
        data: dataUrl,
        name: "abcuruiriie.jpeg",
        mimetype: "image/jpeg",
      };
      if (this.getIsMobile && this.files) {
        this.$notify({
          title: "Success",
          message: "Captured successfully! Please click on the upload.",
          type: "success",
        });
      }
    },
    rotateLeft() {
      if (this.cropper) {
        this.cropper.rotate(-90);
      }
    },
    rotateRight() {
      if (this.cropper) {
        this.cropper.rotate(90);
      }
    },
    initializeCropper() {
      if (!this.$refs.image) {
        return;
      }
      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        viewMode: 2,
        autoCropArea: 0.5,
      });
      if (this.capturedImage && !this.cropperInstance) {
        this.cropperInstance = new Cropper(
          this.$refs.image,
          this.cropperOptions
        );
      }
    },
    checkCroppedImage() {
      if (this.cropper) {
        const croppedCanvas = this.cropper.getCroppedCanvas();
        if (croppedCanvas) {
          const croppedImage = croppedCanvas.toDataURL("image/jpeg");
          this.capturedImage = croppedImage;
          this.files = {
            type: "image/jpeg",
            data: this.capturedImage,
            name: "aum.jpeg",
            mimetype: "image/jpeg",
          };
          this.updateCapturedImage();
        } else {
          this.updateCapturedImage();
        }
      }
    },
    toggleCamera() {
      this.facingMode = this.facingMode === "user" ? "environment" : "user";
      this.initializeCamera(true);
    },
    reCapturePic() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.cropper.destroy();
      this.initializeCamera(false);
      this.capturedImage = null;
    },
    ViewConfigPage() {
      (this.url_key = this.$route.fullPath), (this.copyModal = true);
    },
    isSaveAndNewVisible() {
      return this.isEdit && !this.entityDataId;
    },
    isDuplicateVisible() {
      return (
        this.isEdit &&
        this.entityDataId &&
        (this.checkButtonVisible("Duplicate") ||
          this.checkPerimission("DUPLICATE")) &&
        (!this.currentEntity || this.currentEntity.entity_type != "INDIVIDUAL")
      );
    },
    getMainButtonData() {
      const defaultButtonName = "Save";
      const editButtonName = this.templateData?.templateInfo?.isSaveAsDraft
        ? "Save"
        : "Update";
      const addButtonName =
        this.getCustomizedButtonName(0) || defaultButtonName;
      const canEdit =
        this.checkEditPermission() &&
        this.checkRolePermission("editEntitiesData");

      const buttonName = this.entityDataId
        ? this.isEdit
          ? editButtonName
          : this.getCustomizedButtonName(3) || addButtonName
        : addButtonName;

      return {
        name: buttonName,
        icon: buttonName === "Edit" ? "el-icon-edit" : "fa fa-floppy-o",
        showButton: this.entityDataId ? canEdit : true,
      };
    },
    getMenuItemsForButton() {
      const items = [];
      if (this.isDuplicateVisible()) {
        items.push({
          text:
            this.customization &&
            this.customization.buttons &&
            this.customization.buttons.length > 5
              ? this.customization.buttons[5].value
              : "Duplicate",
          command: "duplicate",
          icon: "fa fa-files-o",
        });
      }
      if (this.isSaveAndNewVisible()) {
        items.push({
          text:
            this.customization &&
            this.customization.buttons &&
            this.customization.buttons.length > 2
              ? this.customization.buttons[2].value
              : "Save & New",
          command: "saveAndNew",
          icon: "fa fa-floppy-o",
        });
      }
      return items;
    },
    handleMainButtonClick() {
      this.isEdit ? this.saveTemplateData(false) : this.emitGoToEditMode();
    },
    handleSaveAsDraftButtonClick() {
      this.isSaveAsDraft = true;
      this.saveTemplateData(false);
    },
    handleDropdownSelect(command) {
      if (command === "duplicate") {
        this.emitCloneEntityData();
      } else if (command === "saveAndNew") {
        this.saveTemplateData(true);
      }
    },
    getFormDataEntityDetails(entityData) {
      this.entityFormData = entityData;
    },
  },
  watch: {
    initialFormData: {
      handler(newData) {
        this.initialFormData = newData;
      },
      deep: true,
      immediate: true,
    },
    formDataUpdation: {
      handler(newData) {
        this.formDataUpdation = newData;
      },
      deep: true,
      immediate: true,
    },
    "$route.query": {
      handler: async function () {
        if (this.$route.query.relationship_id) {
          this.currentRelationshipIndex = [...this.relationshipsData].findIndex(
            (e) => e.relationship_id == this.$route.query.relationship_id
          );

          this.currentRelationshipDetails = [...this.relationshipsData].find(
            (e) => e.relationship_id == this.$route.query.relationship_id
          );

          this.currentEntityNestedRelationships = [
            ...this.getCurrentEntityNestedRelationships,
          ].find((e) => e._id == this.$route.query.relationship_id);
          if (
            this.currentRelationshipIndex > -1 &&
            this.currentRelationshipIndex + 1 <=
              this.relationshipsData.length - 1
          ) {
            this.hasNextRelationshipDetails =
              this.relationshipsData[this.currentRelationshipIndex + 1];
          }
        }
        if (this.$route.query.refresh_data) {
          await this.fetchNewEntityData();
          this.refreshThePage();
        }
      },
      deep: true,
      immediate: true,
      capturedImage: {
        immediate: true,
        handler() {
          this.loadCroppedImage();
        },
      },
    },
    "$route.name": {
      handler: function (name) {
        if (
          (name == "EntityDetailedEdit" ||
            name == "ApEntityExecute" ||
            name == "EntityDetailedEdit2_External") &&
          !this.isEdit &&
          this.mountedData
        ) {
          this.isEdit = true;
          this.refreshThePage();
        }
      },
      deep: true,
      immediate: true,
    },
    //   "$route.query.tabOrd": {
    //   handler(newTabOrd) {
    //     console.log("tabOrd changed to:", newTabOrd);

    //     // Update the active tab when tabOrd changes
    //     // if (newTabOrd && newTabOrd !== this.activeTab) {
    //     //   this.activeTab = newTabOrd;
    //     // }
    //   },
    //   immediate: true,
    // },
    activeTab(newTab) {
      if (newTab === "activities") {
        if (
          this.showFields.split_col2 &&
          this.showFields.split_col2.length > 0
        ) {
          if (
            this.filteredActivityTabGroups != undefined &&
            this.filteredActivityTabGroups.length > 0
          ) {
            this.activeSubTab =
              this.filteredActivityTabGroups[0].tabs[0]?.label || null;
          } else {
            this.activeSubTab = this.showFields.split_col2[0];
          }
        }
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("filters/setUpdateFilterStatus", null, { root: true });
    this.$store.commit("filters/setAllEntityFilters", null, { root: true });
    this.$store.commit("filters/setDeleteFilterStatus", false, { root: true });
    this.$store.commit("filters/setDeleteFilterErros", null, { root: true });
    this.$store.commit("filters/setCreateNewFilter", null, { root: true });
    // this.$store.commit("menuManagementV2/setEntityMenu", null, { root: true });
    // this.$store.commit("applicationUsersPermission/setEntityPermission", null, {
    //   root: true,
    // });
    this.$store.commit("entities/setEntityDataById", null, { root: true });
    this.$store.commit("entities/setEntityDataByEntityId", null, {
      root: true,
    });
    this.$store.commit("entities/setEntityDataUpdateStatus", null, {
      root: true,
    });
    this.$store.commit("entities/setEntityDataExists", null, { root: true });
    this.$store.commit("entities/setEntityPrimaryData", null, { root: true });
    this.$store.commit("templatesData/setDuplicateDataCheck", null, {
      root: true,
    });
    this.$store.commit("templatesData/setPrimaryEntityDataStatus", false, {
      root: true,
    });
    this.$store.commit("entities/setEntityShowFields", [], { root: true });
    this.$store.commit(
      "entityRelationships/setEntityRelationshipDataAssignStatus",
      false,
      { root: true }
    );
    this.$store.commit(
      "entityRelationships/setEntityRelationshipDataAssignFeildsDataStatus",
      false,
      { root: true }
    );
    this.$store.commit("entityRelationships/setEntityRelationships", [], {
      root: true,
    });
    this.$store.commit("entityRelationships/setEntityNestedRelationships", [], {
      root: true,
    });
    this.$store.commit(
      "entityRelationships/setCurrentEntityNestedRelationships",
      [],
      { root: true }
    );
    this.$store.commit(
      "entityRelationships/setCurrentEntityRelationships",
      [],
      { root: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.profile-vertical {
  display: flex;
  background-color: red;
}
.tab-length {
}
.entity-view ::v-deep .dialog-content{
 scrollbar-width : none
}
.custom-collapse-item .el-collapse-item__header .el-collapse-item__arrow {
  display: none;
}
.custom-sub-collapse {
  // background-color: #f0f8ff;
  border: 1px solid white;
  background-color: white;
}
.custom-collapse-sub-item {
  width: 90%;
  margin-left: 40px;
  padding: 3px;
  // box-shadow:  #dff6ff 0px 4px 16px,  #dff6ff(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 5px;
  border-bottom: 1px solid #DCDCDC;
  
}
.vertical-view {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.vertical-view-data {
  display: flex;
  gap: 12px;
}
.vertical-view-card {
  display: flex;
}
.custom-collapse {
  // position: relative; /* Ensures proper layering */
  // z-index: 1;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.el-collapse-item .custom-collapse-sub-item {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px;
  // box-shadow: #dff6ff 0px 4px 16px,#dff6ff(17, 17, 26, 0.05) 0px 8px 32px;
}
// /* Style each collapse item */
.custom-collapse-item {
  // height: 45px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
  border: 1px solid #dcdcdc;
  // border-radius: 25px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

// /* Style the collapse item title */
// .custom-title {
//   // margin-left: 10px;
//   margin-right: 10px;
//   font-weight: bold;
//   font-size: 16px;
//   color: #333;
// }

// /* Add custom styles for specific tabs */
// .tab-content-template {
//   padding: 15px;
//   background-color: #fff;
//   border: 1px solid #ddd;
//   border-radius: 5px;
// }
.child_alignment {
  margin-left: 50px;
}
.overview-activities {
  color: red;
  font-size: 40px;
}
.data-highlights {
  margin-top: -8px;
  margin-bottom: -8px;
}
.spacing {
  margin-bottom: -9px;
  margin-top: -5px;
}
.header-bottom-border-zero {
  margin-bottom: 5px;
}
.split-view-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Creates 3 equal columns */
  gap: 16px; /* Adds spacing between the columns */
  width: 100%; /* Ensures the container spans the full width */
}
.split-view-col {
  display: block;
}
@media (max-width: 768px) {
  .split-view-container {
    grid-template-columns: 1fr; /* Single column for mobile view */
  }
  .split-view-col {
    width: 100%;
  }
  .profile-panel-height {
    &::-webkit-scrollbar {
      display: none; /* Hides the scrollbar */
    }
    -ms-overflow-style: none; /* Hides scrollbar for IE and Edge */
    scrollbar-width: none; /* Hides scrollbar for Firefox */
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .split-view-container {
    grid-template-columns: 1fr; /* Two columns for tablet view */
    width: 100%;
  }
  .split-view-col {
    width: 100%; /* Ensure columns take full grid cell width */
  }
}

@media (min-width: 1024px) {
  .split-view-container {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
    grid-template-rows: auto auto auto;
  }

  .split-view-col {
    display: block; /* Ensures proper grid item behavior */
    width: 100%; /* Ensures columns span the full width of their grid cell */
  }
}

.company-header-container {
  margin-top: 10px;
  margin-left: 5px;
  .company-title {
    font-size: larger;
    font-weight: 600;
    overflow: hidden;
  }
}
.company-details-container {
  display: flex;
}
// .sticky-container{
//   position: sticky;
//   // background: #cde4e436;
//   top: 0; /* Adjust this value as per your layout */
//   z-index: 100; /* Ensure it stays above other elements */
//   background:white;
// }

.company-view-icon {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 50%;
  background: #eee;
  margin: 10px 0px 0px 0px;
  i {
    font-size: 36px;
    top: 18px;
    left: 7px;
    color: #aaa;
    margin: 10px;
  }
}
ul.circle-buttons-container {
  margin-left: 16px;
  li {
    cursor: pointer;
    margin-right: 8px;
    p.circle-button-label {
      font-size: 10px;
      margin-top: 2px;
      color: #555;
      align-content: center;
    }
  }
}
.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  border-radius: 4px;
}

.title-text {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.edit-button {
  margin: 0;
  background-color: #409eff;
  color: white;
}

.edit-button:hover {
  background-color: #66b1ff;
}
.field-label {
  color: #333;
  flex: 1;
}

.field-value-collapse {
  flex: 2;
  color: #666;
  text-align: right;
}
.header-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.edit-button {
  margin-left: auto;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}

.actions {
  display: flex;
  gap: 10px;
}

.el-icon {
  cursor: pointer;
  color: #007bff;
}

.content {
  padding: 10px;
  margin-top: 10px;
  border-top: 1px solid #eee;
}
.error-container {
  text-align: center !important;
}
.filter-card {
  background-color: #ffffff;
  position: relative;
  border: 2px solid #e6e6e6;
  border-radius: 20px;
  height: auto;
  width: 100% !important;
  align-content: center !important;
  align-items: center !important;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;

  .profile-empty {
    margin-top: 15px;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
    background: #f5f5f5;
    font-size: 75px;
    color: #cdcdcd;
    i {
      margin-top: 25px !important;
    }
  }
  img {
    margin-top: 15px;
    width: 120px;
    height: 120px;
    object-fit: cover;
    // border-radius: 50%;
  }
  .card-body {
    padding: 5px;
    .card-title {
      color: #303133;
      font-weight: 500;
      font-size: 14px;
      // margin-bottom: 0px !important;
    }
    .card-descripion {
      font-weight: 400;
      font-size: 12px;
      color: #909399;
    }
  }
  .card-fields {
    padding: 8px;
    .card-fields-inner {
      border-top: #e6e6e6 solid 1px;
      text-align: left !important;
    }
  }
}

.field-label {
  color: #222222;
  font-size: 13px;
  font-weight: 400;
}
.field-value {
  // font-size: 10px;
  color: #909399;
  font-weight: 400;
}
.data-card-container {
  height: calc(100vh - 180px);
  width: 100% !important;
}
.layout-selector {
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  color: #4c4b4e;
}
.send-notification-button {
  padding: 0 15px !important;
  height: 32px;
  margin-right: 10px;
}
.default-view-container {
  .default-profile {
    display: flex;
    // display: grid;
    // grid-gap: 0.5em;
    // grid-template-columns: 300px auto;
    // @media (max-width: 700px) {
    //   grid-template-columns: repeat(1, 1fr);
    //   text-align: center !important;
    // }
  }
  .left-card {
    flex: 1;
    width: 100%;
  }
  .right-card {
    flex-direction: column;
    flex: 3;
    width: 100%;
  }
  .profile-holder {
    img {
      max-width: 300px;
      height: 180px;
      object-fit: cover;
      border-radius: 30px;
    }
    .profile-empty-default {
      margin-top: 15px;
      max-width: 300px;
      height: 240px;
      object-fit: cover;
      margin-left: auto !important;
      margin-right: auto !important;
      background: #f5f5f5;
      font-size: 150px;
      color: #cdcdcd;
      text-align: center !important;
    }
  }
  .profile-details {
    width: 100%;
    .profile-heading {
      color: #303133;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .default-card-fields {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // height: 100px !important;
  }
}
.entity-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  order: 1;
}

.right {
  order: 2;
}
.profile-upper {
  position: relative;
}
#profile-d {
  position: absolute;
  @media (max-width: 1200px) {
    left: 40% !important;
  }
  @media (max-width: 500px) {
    left: 20% !important;
  }
  left: 39px;
  bottom: 0px;
  right: 0px;
  height: 180px;
  z-index: 2;
}

#profile-banner-image {
  height: 260px;
  overflow: hidden;
  z-index: 1;
}

#profile-banner-image img {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  object-fit: contain !important;
}

#profile-pic {
  img {
    width: 180px !important;
    height: 180px !important;
    object-fit: cover;
    // border-radius: 50%;
  }
  margin-top: 60px;
}
.profile-empty-cover {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 50%;
  background: #f5f5f5;
  font-size: 85px;
  color: #cdcdcd;
  text-align: center !important;
  justify-content: center !important;
  i {
    margin-top: 50px !important;
  }
}
.main-data-container {
  @media (max-width: 992px) {
    margin-top: 100px !important;
  }
  @media (max-width: 800px) {
    margin-top: 75px !important;
  }
  @media (max-width: 600px) {
    margin-top: 50px !important;
  }
  @media (max-width: 400px) {
    margin-top: 25px !important;
  }
}
.layout-container {
  padding-left: 20px;
  padding-right: 20px;
  .layout-head {
    color: #303133;
    font-size: 17px;
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
    color: #303133;
    font-family: "Inter";
  }
  .sub-head {
    color: #303133;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #303133;
    font-family: "Inter";
  }
}
.field-selection {
  color: #606266;
  padding: 5px;
  cursor: pointer;
}
.empty-profile-banner-image {
  width: 100% !important;
  height: 100% !important;
  background: #f5f5f5;
  font-size: 100px;
  color: #cdcdcd;
  text-align: center !important;
  justify-content: center !important;
  i {
    margin-top: 6% !important;
  }
}
.name-types {
  text-align: center;
}

.template-container {
  width: 100%;
  border-radius: 30px;
  background-color: #ffffff;
}

.el-button--mini {
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
}

.template-wrapper {
  box-shadow: 0 0px 5px 2px white !important;
  border: 2px solid #e6e6e6;
  border-width: thin;
  border-radius: 20px;
  background-color: #ffffff;
  margin: 5px 0px 0px 5px;
}

.uploader {
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.radio-group-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
.retake-btn {
  margin-top: 10px;
}
.preview-text {
  background-color: #ecf5ff;
  font-weight: 450;
  font-size: 18;
}
.imageEditorContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.cameraContainer {
  flex: 1;
}
.previewContainer {
  flex: 1;
  width: 400px;
}
.mobileStylingContainer {
  @media (max-width: 980px) {
    flex-direction: column-reverse;
  }
}
.mobileStylingContainer2 {
  @media (max-width: 980px) {
    flex-direction: column;
  }
}

.standard-view-card {
  background-color: #ffffff;
}

.cover-view-card {
  background-color: #ffffff;
}
.ai-icon-content {
  display: flex;
  min-width: 115px;
  align-items: center;
  color: var(--primary-color);
  cursor: pointer;
}

.ai-text {
  margin-left: 8px;
  color: var(--primary-color);
  display: inline-block;
}
</style>
<style>
.custom-dialog-style .el-dialog__body {
  padding: 0;
}

.custom-footer-style {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.el-tabs__header {
  height: 40px;
}
.activity-overview-tab .el-tabs__item {
  font-weight: bold;
}

.card-image {
  display: flex;
  justify-content: center;
}

.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  max-width: 115px !important;
  background-color: var(--tabs-color) !important;
}

.el-tabs__nav-wrap::after {
  display: none;
}

.el-link.el-link--default:hover {
  color: var(--primary-color) !important;
}
</style>

<style scoped>
.buttons-layout {
  display: flex;
  width: 17rem;
  justify-content: space-evenly;
  align-items: center;
}
.split-button {
  margin-right: 30px !important;
}
.custom-divider {
  height: 600px;
  margin-left: 25px;
}
.relationship-card,
.nested-relationship-card {
  margin-bottom: 8px;
}
.relationship-card h3,
.nested-relationship-card h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}
.relationship-card p,
.nested-relationship-card p {
  margin: 0;
  font-size: 0.9rem;
  color: #888;
}
.profile-panel-height {
  height: calc(100vh - 115px);
  background: #cde4e436;
  overflow-y: scroll;
  padding: 12px;
  &.bg-white {
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--sidemenu-text-color);
  }
}
.activities-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.form-container {
  padding: 10px;
}
.custom-icon-button {
  width: 36px;
  height: 38px;
  font-size: 10px;
  padding: 0;
}
.el-tooltip__popper.is-company-view-tooltip {
  padding: 6px 12px;
  background: var(--darker-primary-color);
  color: var(--primary-contrast-color);
}

.el-tooltip__popper.is-company-view-tooltip .popper__arrow {
  background: var(--darker-primary-color) !important;
  border-color: var(--darker-primary-color) !important;
}
activity-item {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and label */
  font-size: 13px;
  padding: 5px 10px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}
.activity-icon {
  width: 20px;
  height: 20px;
}

.activity-label {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}
</style>
